<template>
  <div class="flex flex-col w-full z-10" v-if="itemsLoaded">
    <h4 class="mb-3">Teenused</h4>
    <div class="w-full grid grid-cols-12 service-ex-row-headers">
      <div
        class="flex col-span-4 mobile:col-span-5 justify-start font-medium"
        :class="editMode ? 'justify-start mobile:hidden pl-2' : 'justify-start'"
      >
        Kirjeldus
      </div>
      <div
        class="flex font-medium mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        Kogus
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        Ühik
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        <span class="truncate"
          >Ühikuhind<span class="medium:hidden">(€)</span></span
        >
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        <span class="truncate"
          >Summa <span class="medium:hidden">(-km)</span></span
        >
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        KM%
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        KM
      </div>
      <div
        class="flex font-medium mobile:col-span-5"
        :class="
          editMode
            ? 'justify-start mobile:hidden pl-2'
            : 'justify-start col-span-1'
        "
      >
        Summa
      </div>
    </div>
    <div
      class="w-full h-1 rounded-full mt-2 duration-100 bg-offwhite mb-1"
    ></div>
    <div class="flex flex-col service-container z-10" id="service-container">
      <div class="flex flex-col" v-for="(item, index) in items" :key="item.id">
        <service-row
          :data="item"
          :is-white="true"
          :offer-status="offer.status"
          :edit-mode="editMode"
          :index="index"
          :service-templates="serviceTemplates"
          @serviceUpdated="handleServiceUpdate"
          @removeService="handleRemoveService"
          v-if="!editMode"
        ></service-row>
        <new-service-row
          :service="item"
          @removeService="handleRemoveService"
          :service-templates="serviceTemplates"
          :index="index"
          :is-white="true"
          v-if="editMode"
          class="mb-1"
          :active-template-selector="activeTemplateSelector"
          @openTemplateSelector="changeActiveTemplateSelector"
        ></new-service-row>
        <div class="flex w-full h-1 bg-offwhite mb-1"></div>
      </div>
      <div class="flex flex-col" v-for="item in newServices" :key="item.index">
        <service-row
          :data="item"
          :is-white="true"
          :offer-status="offer.status"
          :edit-mode="editMode"
          :is-editing="false"
          :service-templates="serviceTemplates"
          @serviceUpdated="handleServiceUpdate"
          @removeService="handleRemoveService"
          v-if="!editMode"
        ></service-row>
        <new-service-row
          :service="item"
          @removeService="removeService"
          :index="item.index"
          :service-templates="serviceTemplates"
          :active-template-selector="activeTemplateSelector"
          :is-white="true"
          v-if="editMode"
          class="mb-1"
          @openTemplateSelector="changeActiveTemplateSelector"
        ></new-service-row>
        <div class="flex w-full h-1 bg-offwhite mb-1"></div>
      </div>
    </div>
    <div class="flex py-3" v-if="editMode">
      <button @click="addService" class="alt-button-green">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa rida</span>
      </button>
    </div>
    <div class="flex items-center pt-2" v-if="offer">
      <button
        class="alt-button-green"
        @click="editMode = true"
        v-if="!editMode"
      >
        <span class="typcn typcn-edit icon"></span>
        <span class="label">Muuda</span>
      </button>
      <button v-if="editMode" @click="saveOfferItems" class="new-button-green">
        <span class="typcn typcn-tick icon"></span>
        <span class="label">Salvesta</span>
      </button>
      <button
        @click="editMode = false"
        v-if="editMode"
        class="new-button-danger"
      >
        <span class="typcn typcn-times icon"></span>
        <span v-if="editMode" class="label">Tühista</span>
      </button>
    </div>
  </div>
</template>

<script>
import NewServiceRow from "@/components/project/offer/services/NewServiceRow";
import ServiceRow from "@/components/project/offer/services/ServiceRowExistingOffer";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  components: {
    NewServiceRow,
    ServiceRow
  },
  data() {
    return {
      editMode: false,
      newServices: [],
      items: [],
      itemsLoaded: false,
      serviceTemplates: [],
      activeTemplateSelector: null,
      serviceIndex: 1
    };
  },
  props: {
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [RequestHandler],
  mounted() {
    this.getItems();
    this.loadTemplates();
  },

  methods: {
    loadTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/",
        "get",
        true
      ).then(res => {
        this.detailTemplates = res.data.filter(x => x.type === 1);
      });
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/",
        "get",
        true
      ).then(res => {
        this.serviceTemplates = res.data;
      });
    },
    getItems() {
      if (this.offer) {
        this.apiRequest(
          "notes/project/" + this.draft.uuid + "/" + this.offer.id + "/items/",
          "get",
          true
        ).then(res => {
          this.items = res.data;
          this.itemsLoaded = true;
        });
      } else {
        this.items = [];
        this.itemsLoaded = true;
        this.editMode = true;
        this.addService();
      }
    },
    genId(item) {
      const gennedId = Math.round(Math.random() * 100000);
      if (item) {
        this.items.find(x => x.id === item.id).index = gennedId;
      }
      return gennedId;
    },
    saveOfferItems() {
      this.$emit("serviceUpdated", {
        items: this.items,
        newServices: this.newServices
      });
      if (this.offer) {
        this.editMode = false;
      }
    },
    addService() {
      let data = {
        index: this.serviceIndex,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20
      };
      this.serviceIndex += 1;
      this.newServices.push(data);
      setTimeout(function() {
        document
          .getElementById("service-container")
          .scroll({ left: 0, top: 999999, behavior: "smooth" });
      }, 100);
    },
    handleRemoveService(e) {
      this.items.splice(
        this.items.findIndex(x => x.id === e),
        1
      );
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    handleServiceUpdate(e) {
      let itemToChange = this.items.findIndex(item => item.id === e.id);
      this.items[itemToChange] = e;
      this.saveOfferItems();
    },
    changeActiveTemplateSelector(e) {
      this.activeTemplateSelector = e.id;
    }
  }
};
</script>
