<template>
  <div class="modal">
    <div class="modal-body modal-small p-5">
      <div class="flex justify-center mb-4">
        <h3>Kas oled kasutaja juba aktiveerinud?</h3>
      </div>
      <div class="flex mb-4 px-3 mobile:px-0" v-if="!success">
        <p>
          <span class="font-semibold">NB!</span> Aktiveeri oma kasutaja! Sulle
          saadeti meilile (<span class="px-1 font-medium">{{
            $store.state.userData.user.email
          }}</span
          >) aktiverimislink, vajuta seda ning kasutaja ongi aktiveeritud. Siis
          saad kasutada kõiki Gewodo Pro funktsioone. NB! Juhul, kui kirja ei
          leia, kontrollige rämpspostikausta.
        </p>
      </div>
      <div class="flex mb-4 px-3 mobile:px-0" v-if="success">
        <p>
          <span class="font-semibold">Kiri saadetud!</span> Vaata oma postkasti
          ning järgi kirjas olevaid juhiseid, et oma konto aktiveerida.
        </p>
      </div>
      <div class="flex justify-around mobile:flex-col">
        <button class="new-button-green mobile:mb-2" @click="closeModal">
          <span class="label py-2">Selge!</span>
        </button>
        <button @click="resendEmail" v-if="!success" class="new-button-danger">
          <span class="label py-2">Ei saanud kirja</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      error: false
    };
  },
  methods: {
    resendEmail() {
      this.$emit("closeModal");
      this.$router.push("/resend-activation");
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
