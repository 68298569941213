<template>
  <div class="modal">
    <div
      class="modal-body modal-vwide h-full"
      v-if="data"
      v-on-clickaway:mousedown="closeModal"
    >
      <div
        class="flex bg-white rounded-gwdhalf items-center flex-col duration-100 h-full modal-content"
      >
        <div
          class="flex w-full justify-center mobile:justify-start items-center top-row mobile:flex-col"
        >
          <div
            class="w-10/12 mobile:w-full flex justify-start flex-col"
            :class="$store.state.companyData.isClient ? 'w-full' : ''"
          >
            <div class="flex items-start mobile:flex-col mb-2">
              <span class="text-xl font-semibold mr-4"
                >Pakkumine #{{ data.offer.offer_index }}</span
              >
              <div
                class="flex relative justify-center items-end mobile:items-center self-start flex-col"
              >
                <div
                  v-if="
                    $store.state.companyData.isClient ||
                      $store.state.companyData.activeCompany
                        .worker_permission === 'R6'
                  "
                  v-html="parseDraftOfferStatus(data.offer.status)"
                ></div>
                <div
                  class="flex relative w-64"
                  v-if="
                    !$store.state.companyData.isClient &&
                      $store.state.companyData.activeCompany
                        .worker_permission !== 'R6'
                  "
                >
                  <div
                    class="flex items-center hover:bg-offerwhite w-full cursor-pointer justify-center"
                    :class="statusMenuOpen ? 'rounded-t-xl' : 'rounded-xl'"
                    @click="
                      !$store.state.companyData.isClient &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                        ? (statusMenuOpen = !statusMenuOpen)
                        : $store.dispatch('userData/openSubWarning')
                    "
                  >
                    <div
                      v-if="
                        !$store.state.companyData.isClient &&
                          $store.state.companyData.activeCompany
                            .worker_permission !== 'R6'
                      "
                      class="z-10 relative w-full justify-center flex"
                      :class="statusMenuOpen ? 'rounded-t-xl' : 'rounded-xl'"
                      v-html="parseDraftOfferStatus(data.offer.status)"
                    ></div>
                    <span
                      class="typcn typcn-arrow-sorted-down transform duration-100"
                      :class="statusMenuOpen ? 'rotate-180' : ''"
                    ></span>
                  </div>
                  <offer-status-selector
                    v-if="
                      statusMenuOpen &&
                        !$store.state.companyData.isClient &&
                        $store.state.companyData.activeCompany
                          .subscription_permissions.client_portal
                    "
                    :data="data.offer"
                    @changeStatus="handleStatusChange"
                  >
                  </offer-status-selector>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <span class="font-medium">Loomise kuupäev</span>
              {{ moment(data.offer.created_at).format("DD.MM.YYYY") }}
              <span class="font-medium">Hind</span>
              <span>{{ data.offer.price }}€</span>
            </div>
          </div>
          <div class="tab-tray mb-4 self-end">
            <div
              @click="activeView = 1"
              class="tab-tray-item rounded-l-xl w-1/3"
              :class="activeView === 1 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/bc21/project.svg" />
              </span>
              <span class="tab-tray-label">Teenused</span>
            </div>
            <div
              @click="activeView = 2"
              class="tab-tray-item w-1/3"
              :class="activeView === 2 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/bc21/mail.svg" />
              </span>
              <span class="tab-tray-label">Meil</span>
            </div>
            <div
              @click="activeView = 3"
              class="tab-tray-item w-1/3 rounded-r-xl"
              :class="activeView === 3 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/icons/pdf.svg" />
              </span>
              <span class="tab-tray-label">PDF</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full rounded-md">
          <div class="flex w-full mb-4">
            <services
              v-if="activeView === 1"
              :draft="data.draft"
              :offer="$store.state.offerViewer.offerModal.data.offer"
              @serviceUpdated="saveOffer"
              ref="services"
            ></services>
            <add-offer-email
              ref="email"
              v-if="activeView === 2 && !$store.state.companyData.isClient"
              class="w-full"
              :draft="data.draft"
              :offer="data.offer"
              @update="updateOfferData"
              @emailSent="offerUpdated"
            ></add-offer-email>
            <client-offer-email
              v-if="activeView === 2 && $store.state.companyData.isClient"
              class="w-full"
              :draft="data.draft"
              :offer="data.offer"
            ></client-offer-email>
            <pdf
              v-if="activeView === 3 && !$store.state.companyData.isClient"
              class="w-full"
              :offer="data.offer"
              :draft="data.draft"
            >
            </pdf>
            <client-pdf
              v-if="activeView === 3 && $store.state.companyData.isClient"
              class="w-full"
              :draft="data.draft"
              :object-id="data.offer.id"
              :object-name="'offer'"
            ></client-pdf>
          </div>

          <confirm-modal
            @closeModal="confirmRemoveModalActive = false"
            @confirm="deleteOffer"
            text="Olete kindel, et soovite valitud pakkumise kustutada?"
            v-if="confirmRemoveModalActive"
          ></confirm-modal>
        </div>
      </div>
      <div class="modal-bottom-bar relative">
        <transition name="fade">
          <div class="settings-dropup" v-if="settingsDropupActive">
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  convertToInvoice();
                  settingsDropupActive = false;
                }
              "
              v-if="!$store.state.companyData.isClient"
            >
              <div class="settings-dropup-icon bg-pending">
                <span class="typcn typcn-credit-card"></span>
              </div>
              <div class="settings-dropup-title">Loo arve</div>
            </div>
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  confirmRemoveModalActive = true;
                  settingsDropupActive = false;
                }
              "
              v-if="!$store.state.companyData.isClient"
            >
              <div class="settings-dropup-icon bg-danger">
                <img src="/bc21/trash.svg" class="filter-to-white" />
              </div>
              <div class="settings-dropup-title">Kustuta pakkumine</div>
            </div>
            <div
              v-if="
                data.offer.status === 2 && $store.state.companyData.isClient
              "
              @click="updateOfferAsClient(true)"
              class="settings-dropup-item"
            >
              <span
                class="typcn typcn-tick settings-dropup-icon bg-green"
              ></span>
              <span class="settings-dropup-title">Võta pakkumine vastu</span>
            </div>
            <div
              v-if="
                data.offer.status === 2 && $store.state.companyData.isClient
              "
              @click="updateOfferAsClient(false)"
              class="settings-dropup-item"
            >
              <span class="icon settings-dropup-icon bg-danger"
                ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
              /></span>
              <span class="settings-dropup-title">Lükka pakkumine tagasi</span>
            </div>
          </div>
        </transition>
        <div class="flex w-full h-full items-center">
          <div
            class="flex h-full items-center"
            :class="$store.state.companyData.isClient ? 'hidden' : ''"
            v-if="
              !$store.state.companyData.isClient &&
                $store.state.companyData.activeCompany.subscription_permissions
                  .client_portal
            "
          >
            <button
              class="meatball-menu-button hidden mobile:flex mr-3"
              :class="settingsDropupActive ? 'open' : ''"
              @click="settingsDropupActive = !settingsDropupActive"
            >
              <div class="meatball-menu-icon">
                <transition name="fade" mode="out-in">
                  <div class="meatball-menu-icon" v-if="!settingsDropupActive">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <div
                    class="close typcn typcn-times"
                    v-if="settingsDropupActive"
                  ></div>
                </transition>
              </div>
              <span class="label">Valikud</span>
            </button>
            <div class="flex h-full">
              <button class="new-button-danger mr-3" @click="closeModal">
                <span class="typcn typcn-times icon"></span>
                <span class="label">Sulge</span>
              </button>
            </div>
            <button
              class="new-button-pending mobile:w-full mr-3 mobile:hidden"
              @click="convertToInvoice"
              v-if="!editMode"
            >
              <span class="typcn typcn-credit-card icon"></span>
              <span class="label">Loo arve</span>
            </button>
            <button
              class="new-button-danger mobile:w-full mobile:hidden"
              @click="confirmRemoveModalActive = true"
              v-if="!editMode"
            >
              <span class="icon"
                ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
              /></span>
              <span class="label">Kustuta pakkumine</span>
            </button>
          </div>

          <div
            class="flex h-full items-center"
            v-if="$store.state.companyData.isClient"
          >
            <button
              class="meatball-menu-button hidden mobile:flex mr-3"
              :class="settingsDropupActive ? 'open' : ''"
              @click="settingsDropupActive = !settingsDropupActive"
              v-if="data.offer.status === 2"
            >
              <div class="meatball-menu-icon">
                <transition name="fade" mode="out-in">
                  <div class="meatball-menu-icon" v-if="!settingsDropupActive">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                  <div
                    class="close typcn typcn-times"
                    v-if="settingsDropupActive"
                  ></div>
                </transition>
              </div>
              <span class="label">Valikud</span>
            </button>
            <div class="flex h-full">
              <button class="new-button-danger mr-3" @click="closeModal">
                <span class="typcn typcn-times icon"></span>
                <span class="label">Sulge</span>
              </button>
            </div>
            <div class="flex flex-row" v-if="data.offer.status === 2">
              <button
                @click="updateOfferAsClient(true)"
                class="new-button-green mr-3"
              >
                <span class="typcn typcn-tick icon"></span>
                <span class="label">Võta pakkumine vastu</span>
              </button>
              <button
                @click="updateOfferAsClient(false)"
                class="new-button-danger"
              >
                <span class="icon"
                  ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
                /></span>
                <span class="label">Lükka pakkumine tagasi</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <display-file-modal
        v-if="$store.state.fileViewer.displayFileModal"
        :file="$store.state.fileViewer.fileToDisplay"
        :company="$store.state.fileViewer.company"
        :draft="$store.state.fileViewer.draft"
        @fileDeleted="$refs.email.loadSavedEmail()"
      ></display-file-modal>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
import AddOfferEmail from "./modal/AddOfferEmail";
import Services from "./modal/Services";
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import OfferStatusSelector from "@/components/reusable/OfferStatusSelector";
import axios from "axios";
import moment from "moment";
import { directive as onClickaway } from "vue-clickaway2";
import Pdf from "@/components/project/offer/modal/PdfCompleter";
import ClientOfferEmail from "@/components/project/offer/modal/ClientOfferEmail";
import ClientPdf from "@/components/project/offer/modal/ClientPdf";
import DisplayFileModal from "@/components/reusable/FileModal";
import EventBus from "@/assets/mixins/EventBus";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    DisplayFileModal,
    ClientPdf,
    ClientOfferEmail,
    Pdf,
    ConfirmModal,
    AddOfferEmail,
    Services,
    OfferStatusSelector
  },
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      activeView: 1,
      tempData: null,
      editMode: false,
      details: "",
      moment: moment,
      confirmRemoveModalActive: false,
      statusMenuOpen: false,
      pdfViewActive: false,
      pdfSrc: "",
      emailFormActive: false,
      serviceTemplates: [],
      settingsDropupActive: false
    };
  },
  mixins: [StatusParser, RequestHandler],
  mounted() {
    this.details = this.data.draft.details;
  },
  methods: {
    offerUpdated() {
      EventBus.$emit("updateOffers");
    },
    closeModal() {
      this.$store.dispatch("offerViewer/closeOfferModal");
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    },
    convertToInvoice() {
      this.$store.dispatch("invoiceViewer/openAddInvoiceModal", {
        offer: this.$store.state.offerViewer.offerModal.data.offer,
        draft: this.$store.state.offerViewer.offerModal.data.draft
      });
      this.closeModal();
    },
    handleStatusChange(status) {
      if (status !== this.data.offer.status) {
        this.apiRequest(
          "notes/draft/" +
            this.data.draft.uuid +
            "/offer/" +
            this.data.offer.id +
            "/edit/",
          "patch",
          true,
          { status: status }
        ).then(res => {
          if (res) {
            this.statusMenuOpen = false;
            EventBus.$emit("updateOffers");
          }
        });
      } else {
        this.statusMenuOpen = false;
      }
    },
    generatePdfPreview(createFile) {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          this.data.draft.uuid +
          "/" +
          this.data.offer.id +
          "/generate/" +
          (createFile ? "?save_file=True" : ""),
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    downloadPDF() {
      this.apiRequest(
        "files/private/" +
          this.data.draft.company.uuid +
          "/" +
          this.data.offer.pdf[this.offer.pdf.length - 1].id +
          "/?type=file",
        "get",
        true
      ).then(res => {
        const url = res.data.file_url;
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", "arve.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    updateOfferAsClient(accept) {
      if (this.$store.state.companyData.isClient) {
        this.apiRequest(
          "notes/draft/" +
            this.data.draft.uuid +
            "/offer/" +
            this.data.offer.id +
            "/client/update/",
          "put",
          true,
          { accept: accept }
        ).then(res => {
          if (res.status === 200) {
            EventBus.$emit("updateOffers");
          }
        });
      }
    },
    handleServiceUpdateNew(e) {
      let itemToChange = this.newServices.findIndex(
        item => item.index === e.index
      );
      this.newServices[itemToChange] = e;
    },
    handleServiceUpdate(e) {
      let itemToChange = this.tempData.findIndex(item => item.id === e.id);
      this.tempData[itemToChange] = e;
    },
    updateOfferData(data) {
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/offer/" +
          this.data.offer.id +
          "/edit/",
        "patch",
        true,
        data
      ).then(res => {
        if (res) {
          EventBus.$emit("updateOffers");
        }
      });
    },
    saveNewServices(newData) {
      let data = [];
      for (let i = 0; i < newData.length; i++) {
        let obj = {};
        obj.name = newData[i].name;
        obj.amount = newData[i].amount;
        obj.unit = newData[i].unit;
        obj.price = newData[i].price;
        obj.vat = newData[i].vat;
        obj.offer = this.data.offer.id;
        data.push(obj);
      }
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/" +
          this.data.offer.id +
          "/items/add/",
        "post",
        true,
        data
      ).then(res => {
        res;
      });
    },
    deleteOffer() {
      this.apiRequest(
        "notes/company/" +
          this.data.draft.company.uuid +
          "/" +
          this.data.draft.uuid +
          "/offer/" +
          this.data.offer.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          EventBus.$emit("updateOffers");
          this.closeModal();
        }
      });
    },
    saveOffer(svcData) {
      let data = {};

      data.items = svcData.items;
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/" +
          this.data.offer.id +
          "/items/edit/",
        "patch",
        true,
        svcData.items
      ).then(res => {
        if (svcData.newServices.length > 0) {
          this.saveNewServices(svcData.newServices);
        }
        if (res.status === 200) {
          let sum = 0;
          for (let i = 0; i < svcData.items.length; i++) {
            sum +=
              parseFloat(svcData.items[i].price) *
              parseFloat(svcData.items[i].amount);
            if (svcData.items[i].vat === 20) {
              sum +=
                parseFloat(svcData.items[i].price) *
                parseFloat(svcData.items[i].amount) *
                0.2;
            }
          }
          for (let i = 0; i < svcData.newServices.length; i++) {
            sum +=
              parseFloat(svcData.newServices[i].price) *
              parseFloat(svcData.newServices[i].amount);
            if (svcData.newServices[i].vat === 20) {
              sum +=
                parseFloat(svcData.newServices[i].price) *
                parseFloat(svcData.newServices[i].amount) *
                0.2;
            }
          }
          sum = sum.toFixed(2);
          let data = {
            post: this.data.draft.id,
            status: this.data.offer.status,
            details: this.details,
            price: sum
          };
          this.apiRequest(
            "notes/draft/" +
              this.data.draft.uuid +
              "/offer/" +
              this.data.offer.id +
              "/edit/",
            "patch",
            true,
            data
          ).then(res => {
            if (res.status === 201) {
              EventBus.$emit("updateOffers");
              this.$refs.services.getItems();
            }
          });
        }
      });
    }
  }
};
</script>
