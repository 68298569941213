<template>
  <div class="flex flex-col">
    <div class="flex flex-row w-full mobile:flex-col" v-if="emailLoaded">
      <div class="flex flex-col mr-2 w-8/12 mobile:mr-0 mobile:w-full">
        <h4>Meil</h4>
        <div class="w-full mb-2 flex flex-row">
          <span> {{ email.client_email }}</span>
        </div>
        <textarea v-model="email.title" disabled class="w-full mb-2"></textarea>
        <textarea v-model="email.body" disabled class="w-full mb-2"></textarea>
        <textarea
          v-model="email.footer"
          disabled
          class="w-full mb-2"
        ></textarea>
      </div>
      <div class="flex flex-col w-4/12 ml-2">
        <h4>Manus</h4>
        <compact-file-display
          v-for="file in existingFiles"
          :key="file.id"
          :file="file"
          :draft="draft"
          @deleteFile="deleteFile"
        ></compact-file-display>
        <div v-if="existingFiles.length === 0">
          <span>Manus on tühi</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway2";
import RequestHandler from "@/assets/mixins/RequestHandler";
import CompactFileDisplay from "@/components/reusable/CompactFileDisplay";

export default {
  name: "ClientOfferEmail",
  props: {
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      email: {
        offer: 0,
        client_email: "",
        title: "",
        body: "",
        footer: ""
      },
      existingFiles: [],
      emailLoaded: false
    };
  },
  mixins: [RequestHandler],
  components: { CompactFileDisplay },
  methods: {
    loadSavedEmail() {
      this.apiRequest(
        "projects/client/" +
          this.$route.params.projectId +
          "/offer/" +
          this.offer.id +
          "/email/",
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.email.client_email = res.data.client_email;
          this.email.title = res.data.title;
          this.email.body = res.data.body;
          this.email.footer = res.data.footer;
          this.existingFiles = res.data.files;
          this.emailLoaded = true;
        }
      });
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  mounted() {
    this.email.offer = this.offer.id;
    this.loadSavedEmail();
  }
};
</script>

<style lang="scss">
.add-email-input {
  @apply bg-offwhite shadow w-full;
  &:focus {
    @apply shadow-outline;
  }
}
.image-logo {
  max-width: 450px;
  max-height: 120px;
}
</style>
