<template>
  <div id="app" class="bg-offwhite">
    <div :class="$route.meta.sidebar ? '' : 'sticky top-0 z-50'">
      <navbar></navbar>
    </div>
    <div
      class="wrapper flex flex-row mobile:flex-col w-full"
      :class="
        $store.state.taskViewer.displayTaskModal ? 'overflow-y-hidden' : ''
      "
    >
      <sidebar-overlord
        class="w-1/6 mobile:w-full medium:w-1/4"
        v-if="
          $route.meta.sidebar &&
            (($store.state.auth.status.loggedIn &&
              $store.state.companyData.retrieved) ||
              !$store.state.auth.status.loggedIn)
        "
        @activeCompanyChanged="handleActiveCompanyChange"
      ></sidebar-overlord>
      <transition name="fade" mode="out-in" :duration="50">
        <router-view
          v-if="
            dataRetrieved &&
              (($store.state.auth.status.loggedIn &&
                $store.state.companyData.retrieved) ||
                !$store.state.auth.status.loggedIn)
          "
          class="bg-white"
          :class="[
            [
              'Home',
              'Documents',
              'Landing',
              'AboutUs',
              'Product',
              'Pricing',
              'Demo'
            ].includes($route.name)
              ? 'p-0'
              : 'px-6 mobile:px-3 py-6',
            $route.meta.sidebar
              ? 'w-5/6 medium:w-3/4 mobile:w-full mobile:h-full'
              : 'w-full',
            $store.getters.anyModalOpen ? 'overflow-y-hidden' : ''
          ]"
        >
        </router-view>
      </transition>
    </div>
    <page-footer
      :class="[
        !$store.state.userData.cookieState.consentPopup ? '' : 'pb-40',
        $store.state.timetracking.hasActiveTimer ? 'pb-20' : ''
      ]"
    ></page-footer>
    <transition name="fade" :duration="500">
      <consent-popup
        v-if="$store.state.userData.cookieState.consentPopup"
        @acceptCookies="handleCookieAccept"
      ></consent-popup>
    </transition>

    <activation-modal
      v-if="$store.state.auth.status.loggedIn && activationPopup"
      @closeModal="setActivationPopupCookie"
    ></activation-modal>
    <sub-warning v-if="$store.state.userData.subWarning"></sub-warning>
    <transition name="fade" :duration="500">
      <trial-popup
        v-if="
          $store.state.companyData.trialPopupActive &&
            !$store.state.userData.cookieState.consentPopup
        "
      ></trial-popup>
    </transition>
    <transition name="fade" :duration="100">
      <loading-modal v-if="$store.state.loading"></loading-modal>
    </transition>
    <worker-cannot-access
      v-if="!$store.state.companyData.can_access"
    ></worker-cannot-access>
    <modal-controller></modal-controller>
    <timer-bar
      v-if="$store.state.timetracking.hasActiveTimer && screenW < 993"
    ></timer-bar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import PageFooter from "@/components/Footer";
import LoadingModal from "@/components/LoadingModal";
import ActivationModal from "@/components/reusable/ActivationModal";
import ConsentPopup from "@/components/CookieConsent";
import SidebarOverlord from "@/components/SidebarOverlord";
import SubWarning from "@/components/reusable/SubWarning";
import TrialPopup from "@/components/reusable/TrialPopup";
import WorkerCannotAccess from "@/components/WorkerCannotAccess";
import ModalController from "@/components/reusable/ModalController";
import TimerBar from "@/components/reusable/TimerBar";
export default {
  name: "App",
  components: {
    WorkerCannotAccess,
    Navbar,
    PageFooter,
    LoadingModal,
    ConsentPopup,
    ActivationModal,
    SidebarOverlord,
    SubWarning,
    TrialPopup,
    ModalController,
    TimerBar
  },
  created() {
    document.documentElement.setAttribute("lang", "et");
  },
  data() {
    return {
      showActivationPopup: false,
      showCookieBanner: false
    };
  },
  async mounted() {
    document.title = this.$route.meta.title || "Gewodo";
    if (this.$store.state.auth.status.loggedIn) {
      this.$store
        .dispatch("auth/verifyToken", true)
        .then(
          res => {
            if (res.status !== 200) {
              this.$store
                .dispatch("auth/refreshToken", true)
                .then(
                  res => {
                    if (res.status !== 200) {
                      this.$store.dispatch("auth/logout");
                      this.$router.push("/login");
                    }
                  },
                  err => {
                    if (err) {
                      this.$store.dispatch("auth/logout");
                      this.$router.push("/login");
                    }
                  }
                )
                .catch(err => {
                  if (err) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                  }
                });
            }
          },
          err => {
            if (err) {
              this.$store
                .dispatch("auth/refreshToken", true)
                .then(
                  res => {
                    if (res.status !== 200) {
                      this.$store.dispatch("auth/logout");
                      this.$router.push("/login");
                    }
                  },
                  err => {
                    if (err) {
                      this.$store.dispatch("auth/logout");
                      this.$router.push("/login");
                    }
                  }
                )
                .catch(err => {
                  if (err) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                  }
                });
            }
          }
        )
        .catch(err => {
          if (err) {
            this.$store
              .dispatch("auth/refreshToken", true)
              .then(
                res => {
                  if (res.status !== 200) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                  }
                },
                err => {
                  if (err) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                  }
                }
              )
              .catch(err => {
                if (err) {
                  this.$store.dispatch("auth/logout");
                  this.$router.push("/login");
                }
              });
          }
        });
    }

    this.checkAnalytics();
    this.$gtm.enabled();

    this.showActivationPopup = this.initActivationPopup();
    if (this.$store.state.auth.status.loggedIn) {
      this.$store.dispatch("notifications/retrieveNotifications");
      if (!this.$store.state.companyData.retrieved) {
        await this.$store.dispatch("companyData/retrieveCompanyData");
        if (
          this.$store.state.companyData.retrieved &&
          this.$store.state.companyData.activeCompany
        )
          this.$store.dispatch("timetracking/retrieveTimers");
      }
    }
  },
  methods: {
    initActivationPopup() {
      if (
        this.$store.state.auth.status.loggedIn &&
        this.$store.state.userData.cookieState.activationPopup &&
        !this.$store.state.userData.user.is_email_verified
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkAnalytics() {
      if (this.$store.state.userData.cookieState.analyticsEnabled) {
        this.initAnalytics();
      } else {
        this.showCookieBanner = true;
      }
    },
    handleCookieAccept(e) {
      this.$store.dispatch("userData/setSiteFeatureCookies", {
        analytics: e.analytics
      });
      this.initAnalytics();
    },
    initAnalytics() {
      this.$gtm.enable(true);
    },
    setActivationPopupCookie() {
      this.$store.dispatch(
        "userData/setActivationPopupCookie",
        this.$store.state.userData.user.id
      );
    },
    handleActiveCompanyChange() {
      if (this.$route.name !== "Calendar") {
        this.$router.push("/calendar");
      }
    }
  },
  computed: {
    dataRetrieved() {
      if (this.$store.state.auth.status.loggedIn) {
        if (this.$store.state.userData.retrieved) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    activationPopup() {
      if (
        this.$store.state.userData.cookieState.activationPopup &&
        !this.$store.state.userData.user.is_email_verified &&
        this.$route.name !== "ProviderWizard" &&
        this.dataRetrieved
      ) {
        return true;
      } else {
        return false;
      }
    },
    analyticsEnabled() {
      if (this.$store.state.userData.cookieState.analyticsCookie === true) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Gewodo";
    },
    "$store.getters.anyModalOpen"() {
      if (this.$store.getters.anyModalOpen) {
        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-y-hidden");
      } else {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("overflow-y-hidden");
      }
    }
  }
};
</script>
