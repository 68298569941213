<template>
  <div v-if="pdfViewActive">
    <div
      v-if="pdfSrc"
      class="z-1 flex flex-row items-start justify-end w-full h-8"
    >
      <div
        class="mr-1 cursor-pointer"
        v-if="totalPages > 1"
        @click="currentPage === 1 ? '' : (currentPage -= 1)"
      >
        <span class="bg-pending p-2 rounded-full">
          <span class="typcn typcn-chevron-left icon h-5 text-white"></span>
        </span>
      </div>
      <div
        class="mx-1 cursor-pointer"
        v-if="totalPages > 1"
        @click="currentPage === totalPages ? '' : (currentPage += 1)"
      >
        <span class="bg-pending p-2 rounded-full">
          <span class="typcn typcn-chevron-right icon h-5 text-white"></span>
        </span>
      </div>
      <div class="mx-1 cursor-pointer" @click="downloadPdf">
        <span class="bg-pending p-2 rounded-full">
          <span
            class="typcn typcn-download bg-pending icon h-5 text-white"
          ></span>
        </span>
      </div>
    </div>
    <div v-if="pdfSrc" class="w-full justify-center items-center flex">
      <pdf
        :src="pdfSrc"
        :page="currentPage"
        @num-pages="initPages"
        class="w-8/12 mobile:w-full flex border-2 border-black"
      ></pdf>
    </div>
    <div v-if="!pdfSrc">
      <span class="text-sm">Pdf puudub</span>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import pdf from "vue-pdf";

export default {
  name: "ClientPdf",
  props: {
    objectName: {
      type: String,
      default: ""
    },
    objectId: {
      type: Number,
      default: null
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      pdfViewActive: false,
      pdfSrc: null,
      currentPage: 1,
      totalPages: 1
    };
  },
  components: { pdf },
  mixins: [RequestHandler],
  methods: {
    loadPdfInfo() {
      this.apiRequest(
        "projects/client/" +
          this.draft.uuid +
          "/" +
          this.objectName +
          "/" +
          this.objectId +
          "/pdf/",
        "get",
        true
      ).then(res => {
        if (res.data.pdf.length > 0) {
          this.pdfSrc = res.data.pdf[0].file_url;
          this.pdfViewActive = true;
        } else {
          this.pdfViewActive = true;
        }
      });
    },
    initPages(e) {
      this.totalPages = e;
    },
    saveFile() {
      this.$emit("saveFile");
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.pdfSrc;
      link.target = "_blank";
      link.setAttribute("download", "pakkumine.pdf");
      document.body.appendChild(link);
      link.click();
    }
  },
  mounted() {
    this.loadPdfInfo();
  }
};
</script>
