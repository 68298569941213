export default {
  data() {
    return {
      userRoles: [
        {
          role: "R0",
          description: "Omanik"
        },
        {
          role: "R1",
          description: "Administraator"
        },
        {
          role: "R2",
          description: "Ettevõtte haldur"
        },
        {
          role: "R3",
          description: "Projektijuht"
        },
        {
          role: "R5",
          description: "Töötaja"
        }
      ]
    };
  },
  methods: {
    getUserRole(role) {
      return this.userRoles.find(x => x.role === role)
        ? this.userRoles.find(x => x.role === role).description
        : null;
    }
  }
};
