import moment from "moment";
import store from "..";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";
export const timetracking = {
  state: {
    timer: null,
    hasActiveTimer: false,
    loading: false
  },
  namespaced: true,
  mutations: {
    startTimer(state, timer) {
      state.timer = timer;
      state.hasActiveTimer = true;
    },
    stopTimer(state) {
      state.timer = null;
      state.hasActiveTimer = false;
    },
    blockStart(state) {
      state.loading = true;
    },
    unblockStart(state) {
      state.loading = false;
    }
  },
  actions: {
    startTimer({ dispatch, commit }, taskId) {
      commit("blockStart");

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => {
            let data = {};
            data.start_location = {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              accuracy: pos.coords.accuracy,
              time_stamp: moment(pos.timestamp).format("YYYY-MM-DD[T]HH:mm:ssZ")
            };
            StoreRequestHandler.apiRequest(
              `${process.env.VUE_APP_API_URL}tasks/${taskId}/stopper/start/`,
              "post",
              true,
              data
            ).then(res => {
              if (res.status === 201) dispatch("retrieveTimers");
            });
          },
          () => {
            StoreRequestHandler.apiRequest(
              `${process.env.VUE_APP_API_URL}tasks/${taskId}/stopper/start/`,
              "post",
              true
            ).then(res => {
              if (res.status === 201) dispatch("retrieveTimers");
            });
          }
        );
      }
    },
    stopTimer({ dispatch, commit }) {
      commit("blockStart");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => {
            let data = {};
            data.end_location = {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              accuracy: pos.coords.accuracy,
              time_stamp: moment(pos.timestamp).format("YYYY-MM-DD[T]HH:mm:ssZ")
            };
            StoreRequestHandler.apiRequest(
              `${process.env.VUE_APP_API_URL}tasks/${store.state.timetracking.timer.task_id}/stopper/${store.state.timetracking.timer.id}/stop/`,
              "put",
              true,
              data
            ).then(res => {
              if (res.status === 200) {
                dispatch("retrieveTimers");
              }
            });
          },
          () => {
            StoreRequestHandler.apiRequest(
              `${process.env.VUE_APP_API_URL}tasks/${store.state.timetracking.timer.task_id}/stopper/${store.state.timetracking.timer.id}/stop/`,
              "put",
              true
            ).then(res => {
              if (res.status === 200) {
                dispatch("retrieveTimers");
              }
            });
          }
        );
      }
    },
    retrieveTimers({ commit }) {
      StoreRequestHandler.apiRequest(
        `${process.env.VUE_APP_API_URL}tasks/${store.state.companyData.activeCompany.uuid}/active/timing/`,
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          commit("startTimer", res.data);
        } else if (res.status === 204) {
          commit("stopTimer");
        }
        commit("unblockStart");
      });
    }
  }
};
