<template>
  <div class="status-menu">
    <span
      class="status-menu-item"
      v-if="
        taskData.status !== 1 &&
          taskData.status === 2 &&
          ['R0', 'R1', 'R2'].includes(userRole)
      "
      v-html="parseTaskStatus(1)"
      @click="changeTaskStatus(1)"
    ></span>
    <span
      class="status-menu-item"
      v-if="taskData.status !== 2 && ['R0', 'R1', 'R2'].includes(userRole)"
      v-html="parseTaskStatus(2)"
      @click="changeTaskStatus(2)"
    ></span>
    <span
      class="status-menu-item"
      v-if="
        (taskData.status !== 3 && ['R0', 'R1', 'R2'].includes(userRole)) ||
          (['R4', 'R5'].includes(userRole) && taskData.status === 4)
      "
      v-html="parseTaskStatus(3)"
      @click="changeTaskStatus(3)"
    ></span>
    <span
      class="status-menu-item"
      v-if="taskData.status !== 4"
      v-html="parseTaskStatus(4)"
      @click="changeTaskStatus(4)"
    ></span>
    <span
      class="status-menu-item"
      :class="taskData.status !== 6 ? 'rounded-b-xl' : ''"
      v-if="taskData.status !== 5"
      v-html="parseTaskStatus(5)"
      @click="changeTaskStatus(5)"
    ></span>
    <span
      class="status-menu-item"
      :class="taskData.status !== 6 ? 'rounded-b-xl' : ''"
      v-if="taskData.status !== 6 && ['R0', 'R1', 'R2'].includes(userRole)"
      v-html="parseTaskStatus(6)"
      @click="endTask"
    ></span>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
export default {
  props: {
    taskData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: { type: String, default: "" }
  },
  mixins: [StatusParser],
  methods: {
    changeTaskStatus(status) {
      this.$emit("changeStatus", status);
    },
    endTask() {
      this.$emit("endTask");
    }
  }
};
</script>
