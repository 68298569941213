var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white flex flex-col"},[_c('div',{staticClass:"flex py-3 px-2 duration-100 items-center",class:[
      _vm.detailsActive ? 'bg-offwhite bg-opacity-50' : '',
      _vm.times.length > 0
        ? 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer'
        : ''
    ],on:{"click":_vm.activateDetails,"mouseover":function($event){['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
      _vm.$store.state.userData.user.id === _vm.worker.profile
        ? (_vm.isHovered = true)
        : ''},"mouseleave":function($event){['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
      _vm.$store.state.userData.user.id === _vm.worker.profile
        ? (_vm.isHovered = false)
        : ''}}},[_c('h4',{staticClass:"flex items-center",class:['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
        _vm.$store.state.userData.user.id === _vm.worker.profile
          ? 'w-9/12'
          : 'w-full'},[_vm._v(" "+_vm._s(_vm.worker.profile_name)+" ")]),(
        (['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
          _vm.$store.state.userData.user.id === _vm.worker.profile) &&
          _vm.$store.state.companyData.activeCompany.subscription_permissions
            .client_portal
      )?_c('div',{staticClass:"flex w-3/12 items-center justify-end"},[(_vm.times.length > 0)?_c('span',{staticClass:"bg-green rounded-full h-2 w-2 ring-2 ring-opacity-20 ring-green"}):_vm._e(),_c('span',{staticClass:"font-medium ml-3"},[_vm._v(_vm._s(_vm.totalTime))])]):_vm._e(),_c('div',{staticClass:"flex w-1/12 justify-center items-center"},[(_vm.times.length > 0)?_c('span',{staticClass:"typcn typcn-chevron-right transform duration-100 leading-none",class:_vm.detailsActive ? 'rotate-90' : ''}):_vm._e()])]),(
      _vm.detailsActive &&
        (['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
          _vm.$store.state.userData.user.id === _vm.worker.profile)
    )?_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.times),function(item){return _c('div',{key:item.id,staticClass:"flex p-2 items-center",class:[
        !item.stopper_end
          ? 'bg-green rounded-b-md text-white font-semibold'
          : '',
        item.start_location || item.end_location ? 'cursor-pointer' : ''
      ],on:{"click":function($event){return _vm.openMap(item)}}},[_c('div',{staticClass:"w-4/12 flex items-center"},[_vm._v(" "+_vm._s(_vm.moment(item.stopper_start).format("DD.MM.YYYY"))+" ")]),_c('div',{staticClass:"w-4/12 justify-center flex items-center"},[_c('span',[_vm._v(_vm._s(_vm.formatStopperInterval(item)))])]),_c('div',{staticClass:"w-4/12 justify-end flex items-center"},[(item.stopper_end)?_c('span',[_vm._v(_vm._s(_vm.moment .utc( _vm.moment .duration( _vm.moment(item.stopper_end).diff(_vm.moment(item.stopper_start)) ) .asMilliseconds() ) .format("HH:mm:ss")))]):_vm._e(),(!item.stopper_end)?_c('span',[_vm._v(_vm._s(_vm.moment .utc( _vm.moment .duration(_vm.moment().diff(_vm.moment(item.stopper_start))) .asMilliseconds() ) .format("HH:mm:ss")))]):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"bg-offwhite w-full h-1 rounded-full"}),(_vm.startLocationActive && _vm.activeItem)?_c('location-modal',{attrs:{"start-location":_vm.activeItem.start_location,"end-location":_vm.activeItem.end_location,"task-location":_vm.taskLocation},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }