var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal-body modal-medium mobile:justify-center"},[_c('div',{staticClass:"modal-content flex-col"},[_c('span',{staticClass:"text-2xl mb-4"},[_vm._v("Funktsiooni kasutamine piiratud")]),(
          _vm.$store.state.companyData.activeCompany.worker_permission === 'R0'
        )?_c('span',[_vm._v("Teil on hetkel kasutusel \"Baas\" pakett, mis ei hõlma antud funktsiooni. Selleks, et seda funktsiooni kasutada, uuendage oma paketti "),(!_vm.$store.state.companyData.activeCompany.trial_period_used)?_c('span',[_vm._v("või aktiveerige prooviperiood")]):_vm._e(),_vm._v(".")]):_vm._e(),(
          !_vm.$store.state.companyData.activeCompany.worker_permission === 'R0'
        )?_c('span',[_vm._v("Teie firmal ei ole Gewodo Premium paketti. Selleks, et seda funktsiooni kasutada, uuendage oma firma paketti.")]):_vm._e()]),_c('div',{staticClass:"modal-bottom-bar"},[(
          _vm.$store.state.companyData.activeCompany.worker_permission === 'R0'
        )?_c('button',{staticClass:"new-button-green mr-3 mobile:mr-0 mobile:mb-4",on:{"click":function($event){return _vm.goToAddSub()}}},[_c('span',{staticClass:"typcn typcn-tick icon"}),_c('span',{staticClass:"label"},[_vm._v("Uuenda paketti")])]):_vm._e(),(
          !_vm.$store.state.companyData.activeCompany.trial_period_used &&
            _vm.$store.state.companyData.activeCompany.worker_permission === 'R0'
        )?_c('button',{staticClass:"new-button-green mr-3 mobile:mr-0 mobile:mb-4",on:{"click":function($event){return _vm.goToTrialPage()}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Aktiveeri prooviperiood")])]):_vm._e(),_c('button',{staticClass:"new-button-danger",on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{staticClass:"typcn typcn-times icon"}),_c('span',{staticClass:"label"},[_vm._v("Sulge")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }