<template>
  <div class="modal relative">
    <div
      class="modal-body modal-medium w-full"
      id="file-viewer"
      v-if="activeFileLoaded"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-content flex-col">
        <div
          class="flex flex-row items-center justify-between mobile:flex-col mb-2 mobile:mb-4"
        >
          <div class="flex flex-row items-center mobile:mb-4">
            <h4>{{ file.file_name }}</h4>
          </div>
        </div>
        <div class="flex mobile:flex-col">
          <div class="justify-center flex w-full mobile:w-full mobile:mb-4">
            <div
              v-if="
                ['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                  activeFileData.file_type
                )
              "
              :class="
                !['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                  activeFileData.file_type
                )
                  ? 'hidden'
                  : ''
              "
              id="imageDisplay"
            >
              <img
                class="flex rounded-xl mobile:w-full mobile:h-auto mobile:m-0"
                :src="activeFileData.file_url"
                @load="showImage = true"
                :class="!showImage ? 'hidden' : ''"
                :alt="activeFileData.file_name"
              />
            </div>
            <div class="flex flex-col justify-center z-2">
              <div
                v-if="pdfSrc"
                class="justify-center items-center flex z-2"
                :style="{
                  minWidth: screenSize,
                  maxWidth: screenSize,
                  minHeight: screenHeight,
                  maxHeight: screenHeight
                }"
              >
                <pdf
                  :src="pdfSrc"
                  :page="currentPage"
                  @num-pages="initPages"
                  class="flex w-full h-full z-2"
                ></pdf>
              </div>
              <div
                v-if="
                  !['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                    activeFileData.file_type
                  ) && !pdfSrc
                "
                class="flex flex-col rounded-gwdfull bg-offwhite mt-3 py-3 px-5"
              >
                <span>Ei saa faili automaatselt kuvada</span>
                <span>faili nägemiseks laadige see alla</span>
              </div>
              <div
                v-if="pdfSrc && activeFileLoaded"
                class="z-1 flex flex-row items-start justify-center w-full h-8 mt-2"
              >
                <div
                  class="mr-1 cursor-pointer"
                  v-if="totalPages > 1"
                  @click="currentPage === 1 ? '' : (currentPage -= 1)"
                >
                  <span class="p-2 rounded-full bg-offwhite">
                    <span
                      class="typcn typcn-chevron-left icon h-5 text-black"
                    ></span>
                  </span>
                </div>
                <div
                  class="mx-1 cursor-pointer"
                  v-if="totalPages > 1"
                  @click="currentPage === totalPages ? '' : (currentPage += 1)"
                >
                  <span class="p-2 rounded-full bg-offwhite">
                    <span
                      class="typcn typcn-chevron-right icon h-5 text-black"
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col bg-offwhite rounded-xl px-5 py-3 w-1/5 mobile:w-full shadow-sm"
            v-if="fileInfoLoaded && canEdit"
          >
            <span class="text-sm mb-2">
              Üleslaadija: {{ editables.uploaded_by }}</span
            >
            <span class="text-sm mb-2">
              Kuupäev: {{ createdAtFormatted(editables.created_at) }}</span
            >
            <span class="text-sm mb-2"> Suurus: {{ editables.size }}</span>
            <div
              class="flex flex-row items-center justify-between"
              v-if="canEdit"
            >
              <span class="text-sm mr-2">Nähtav töötajale</span>
              <input
                type="checkbox"
                class="shadow-none focus:shadow-none"
                v-model="editables.visible_to_workers"
                @change="editFile"
              />
            </div>
            <div
              class="flex flex-row items-center justify-between"
              v-if="
                (draft ||
                  (editables.related_object &&
                    Object.keys(editables.related_object).length > 0 &&
                    editables.related_object.type === 'Projekt')) &&
                  canEdit
              "
            >
              <span class="text-sm mr-2">Nähtav kliendile</span>
              <input
                type="checkbox"
                class="shadow-none focus:shadow-none"
                v-model="editables.visible_to_client"
                @change="editFile"
              />
            </div>
            <div
              class="flex flex-row items-center justify-between"
              v-if="canEdit"
            >
              <span class="text-sm mr-2">Nähtav halduritele</span>
              <input
                type="checkbox"
                class="shadow-none focus:shadow-none"
                v-model="editables.visible_to_managers"
                @change="editFile"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar relative">
        <transition name="fade">
          <div class="settings-dropup" v-if="settingsDropupActive">
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  downloadFile();
                  settingsDropupActive = false;
                }
              "
            >
              <div class="settings-dropup-icon bg-green">
                <span class="typcn typcn-download"></span>
              </div>
              <div class="settings-dropup-title">Lae alla</div>
            </div>
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  deleteFileModalActive = true;
                  settingsDropupActive = false;
                }
              "
            >
              <div class="settings-dropup-icon bg-danger">
                <span class="">
                  <img src="/bc21/trash.svg" class="filter-to-white" />
                </span>
              </div>
              <div class="settings-dropup-title">Kustuta fail</div>
            </div>
          </div>
        </transition>
        <div class="flex">
          <button
            class="meatball-menu-button hidden mobile:flex mr-3"
            :class="settingsDropupActive ? 'open' : ''"
            @click="settingsDropupActive = !settingsDropupActive"
          >
            <div class="meatball-menu-icon">
              <transition name="fade" mode="out-in">
                <div class="meatball-menu-icon" v-if="!settingsDropupActive">
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                </div>
                <div
                  class="close typcn typcn-times"
                  v-if="settingsDropupActive"
                ></div>
              </transition>
            </div>
            <span class="label">Valikud</span>
          </button>
          <button class="new-button-danger mr-3" @click="closeModal">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Sulge</span>
          </button>
          <button
            class="new-button-green mr-3 mobile:hidden"
            @click="downloadFile"
            :class="canEdit ? 'mr-3' : ''"
          >
            <span class="typcn typcn-download icon"></span>
            <span class="label">Lae alla</span>
          </button>
          <button
            class="new-button-danger mobile:hidden"
            @click="deleteFileModalActive = true"
            v-if="canEdit"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="filter-to-white"
            /></span>
            <span class="label">Kustuta</span>
          </button>
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="deleteFileModalActive"
      text="Olete kindel, et soovite faili kustutada?"
      @confirm="deleteFile"
      @closeModal="deleteFileModalActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import { directive as onClickaway } from "vue-clickaway2";
import pdf from "vue-pdf";
import moment from "moment";
import ConfirmModal from "@/components/reusable/ConfirmModal";

export default {
  name: "FileModal",
  components: { ConfirmModal, pdf },
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: "emailFile"
    },
    company: String,
    draft: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activeFileData: null,
      activeFileLoaded: false,
      activeImage: null,
      pdfSrc: null,
      totalPages: 1,
      currentPage: 1,
      editables: null,
      showImage: false,
      deleteFileModalActive: false,
      fileInfoLoaded: false,
      settingsDropupActive: false
    };
  },
  mixins: [RequestHandler],
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    editFile() {
      this.apiRequest(
        "company/" + this.company + "/files/edit/",
        "patch",
        true,
        {
          id: this.activeFileData.id,
          visible_to_workers: this.editables.visible_to_workers,
          visible_to_managers: this.editables.visible_to_managers,
          visible_to_client: this.editables.visible_to_client
        }
      ).then(res => {
        if (res) {
          this.retrieveFileData();
        }
      });
    },
    activateImage(e) {
      if (this.activeImage === null) {
        this.activeImage = e;
      } else if (this.activeImage === e) {
        this.activeImage = null;
      } else {
        this.activeImage = e;
      }
    },
    initPages(e) {
      this.totalPages = e;
    },
    deleteFile() {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/" + this.company + "/files/delete/",
        "delete",
        true,
        {
          document_id_list: [this.activeFileData.id]
        }
      ).then(res => {
        if (res.status === 200) {
          this.$store.state.loading = false;
          this.$store.dispatch("fileViewer/closeFileModal");
          this.$emit("fileDeleted");
        } else {
          this.$store.state.loading = false;
        }
      });
    },
    retrieveFileInfo() {
      this.fileInfoLoaded = false;
      if (!this.editables) {
        let url;
        if (!this.$store.state.companyData.isClient) {
          url =
            "company/" + this.company + "/file/" + this.file.id + "/?info=True";
        } else {
          url =
            "projects/" + this.draft + "/file/" + this.file.id + "/?info=True";
        }
        this.apiRequest(url, "get", true).then(res => {
          this.editables = res.data;
          this.fileInfoLoaded = true;
        });
      }
    },
    retrieveFileData() {
      let url;
      if (!this.$store.state.companyData.isClient) {
        url = "company/" + this.company + "/file/" + this.file.id + "/";
      } else {
        url = "projects/" + this.draft + "/file/" + this.file.id;
      }
      this.apiRequest(url, "get", true).then(
        res => {
          this.activeFileData = res.data;
          if (["application/pdf", "pdf"].includes(res.data.file_type)) {
            this.pdfSrc = res.data.file_url;
            this.activeFileLoaded = true;
          } else {
            this.activeFileLoaded = true;
          }
          this.$store.state.loading = false;
        },

        err => {
          console.error(err);
          this.$store.state.loading = false;
        }
      );
    },
    closeModal() {
      if (!this.deleteFileModalActive) {
        this.$store.dispatch("fileViewer/closeFileModal");
      }
    },
    downloadFile() {
      if (this.activeFileData) {
        if (this.activeFileData.file_url) {
          const url = this.activeFileData.file_url;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.setAttribute("download", this.activeFileData.file_name);
          document.body.appendChild(link);
          link.click();
        } else {
          this.apiRequest(
            "files/private/" +
              this.company +
              "/" +
              this.activeFileData.id +
              "/?type=" +
              this.type +
              (this.$store.state.companyData.isClient
                ? "&project_uuid=" + this.draft
                : ""),
            "get",
            true
          ).then(res => {
            const url = res.data.file_url;
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.setAttribute("download", this.activeFileData.file_name);
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    },
    createdAtFormatted(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    }
  },
  mounted() {
    this.$store.state.loading = true;
    this.retrieveFileData();
    if (this.canEdit) {
      this.retrieveFileInfo();
    }
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    screenSize() {
      const screen = window.innerWidth;
      if (screen < 700) {
        return screen + "px";
      } else if (screen <= 992) {
        return "60vw";
      } else {
        return "50vw";
      }
    },
    screenHeight() {
      const screen = window.innerHeight;
      if (screen >= 1280) {
        return "95vh";
      } else {
        return "85vh";
      }
    },
    canEdit() {
      return (
        !this.$store.state.companyData.isClient &&
        ["R0", "R1", "R2"].includes(
          this.$store.state.companyData.activeCompany.worker_permission
        )
      );
    }
  }
};
</script>

<style lang="scss">
#file-viewer {
  opacity: 1;
  z-index: 1;
}
#imageDisplay {
  opacity: 100;
}
</style>
