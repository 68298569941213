<template>
  <div
    class="user-menu"
    :class="active ? 'user-menu-open' : 'user-menu-closed'"
  >
    <transition name="fade" :duration="{ enter: 500, leave: 50 }">
      <ul v-if="active">
        <router-link :to="'/company/new'" class="mb-4">
          <button class="new-button-green">
            <span class="label py-1">Loo ettevõte</span>
          </button>
        </router-link>
        <router-link to="/account">
          <button class="new-button-green">
            <span class="label py-1">Kontoseaded</span>
          </button></router-link
        >
        <router-link to="/settings">
          <button class="new-button-green">
            <span class="label py-1">Ettevõtte seaded</span>
          </button>
        </router-link>
        <button
          @click="logout()"
          data-cy="logout"
          class="new-button-danger mt-5 navbar-logout bg-white"
        >
          <span class="label py-1">Logi välja</span>
        </button>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    }
  }
};
</script>
