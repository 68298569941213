<template>
  <div class="dashboard-sidebar-cont">
    <div class="dashboard-sidebar pt-5">
      <router-link class="dashboard-sidebar-item" to="/client/projects">
        <img src="/bc21/clipboard.svg" class="mr-2 mobile:mr-0" />
        <span>Tööd</span>
      </router-link>
      <router-link class="dashboard-sidebar-item" to="/client/companies">
        <img src="/bc21/project.svg" class="mr-2 mobile:mr-0" />
        <span>Seotud ettevõtted</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyNavbar",
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.dashboard-sidebar {
  .company-selector-button {
    @apply transform cursor-pointer duration-100 absolute;
    right: 0.2rem;
    &:hover {
      @apply text-darkgreen;
    }
  }
  .company-selector-button-active {
    @apply rotate-180 text-green;
  }
  .company-selector {
    @apply bg-white flex flex-col justify-center pb-2;
  }
}
</style>
