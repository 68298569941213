<template>
  <div
    class="w-full grid grid-cols-12 items-center py-3"
    :class="isWhite ? 'bg-white' : 'bg-offwhite'"
  >
    <div
      class="flex justify-start px-1 mobile:px-0"
      :class="
        isEditing
          ? 'col-span-3 mobile:col-span-5'
          : 'col-span-4 mobile:col-span-5'
      "
    >
      <span v-if="!isEditing" class="truncate">{{ data.name }}</span>
      <input
        type="text"
        class="add-offer-input"
        v-model="tempData.name"
        v-if="isEditing"
      />
    </div>
    <div class="flex justify-start px-1 mobile:hidden">
      <span v-if="!isEditing">{{ data.amount }}</span>
      <input
        type="number"
        class="add-offer-input"
        step="0.0001"
        min="0"
        v-model="tempData.amount"
        v-if="isEditing"
      />
    </div>
    <div class="flex justify-start px-1 mobile:hidden">
      <span v-if="!isEditing">{{ parseUnit(data.unit) }}</span>
      <select class="add-offer-input" v-model="tempData.unit" v-if="isEditing">
        <option :value="1">tk</option>
        <option :value="2">h</option>
        <option :value="11">m</option>
        <option :value="3">km</option>
        <option :value="4">l</option>
        <option :value="5">m²</option>
        <option :value="6">m³</option>
        <option :value="8">kg</option>
        <option :value="12">t</option>
        <option :value="7">päev</option>
        <option :value="9">kuu</option>
      </select>
    </div>
    <div class="flex justify-start px-1 mobile:hidden">
      <span v-if="!isEditing">{{ data.price }}</span>
      <input
        type="number"
        step="0.0001"
        class="add-offer-input"
        min="0"
        v-model="tempData.price"
        v-if="isEditing"
      />
    </div>
    <div
      class="flex px-1 mobile:hidden"
      :class="isEditing ? 'justify-center' : 'justify-start'"
    >
      {{ calculatedSumNoVat }}€
    </div>
    <div class="flex justify-start px-1 mobile:hidden">
      <span v-if="!isEditing">{{ vatType }}</span>
      <select class="add-offer-input" v-model="tempData.vat" v-if="isEditing">
        <option :value="0">0%</option>
        <option :value="20">20%</option>
      </select>
    </div>
    <div
      class="flex px-1 mobile:hidden"
      :class="isEditing ? 'justify-start' : 'justify-start'"
    >
      {{ calculatedVat }}€
    </div>
    <div
      class="flex col-span-2 px-1 mobile:col-span-5 mobile:px-0"
      :class="isEditing ? 'justify-start' : 'justify-start'"
    >
      {{ calculatedSum }}€
    </div>
    <div class="flex col-span-1 justify-center px-1 " v-if="isEditing">
      <button
        class="bg-danger rounded-xl h-8 w-8 text-xl flex justify-center items-center hover:bg-dangerdark hover:shadow-dangeroutline"
        @click="confirmRemoveModalActive = true"
      >
        <span class="typcn typcn-times"></span>
      </button>
    </div>
    <div class="col-span-2 hidden mobile:flex justify-center px-1">
      <button
        class="rounded-xl w-8 h-8 flex items-center justify-center"
        @click="displayMobileTray = !displayMobileTray"
        :class="
          displayMobileTray
            ? 'bg-green focus:bg-darkgreen hover:bg-darkgreen'
            : 'bg-pending focus:bg-pendingdark hover:bg-pendingdark'
        "
      >
        <span class="typcn typcn-info-large text-white"></span>
      </button>
    </div>
    <div
      class="col-span-12 hidden mobile:flex flex-col"
      v-if="displayMobileTray"
    >
      <span class="bg-offwhite h-1 w-full rounded-full my-2"></span>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">Kogus</div>
        <div class="flex w-7/12 text-sm">
          <div class="flex justify-start px-1">
            <span v-if="!isEditing">{{ data.amount }}</span>
            <input
              type="number"
              class="add-offer-input"
              step="0.0001"
              min="0"
              v-model="tempData.amount"
              v-if="isEditing"
            />
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">Ühik</div>
        <div class="flex w-7/12 text-sm">
          <div class="flex justify-start px-1">
            <span v-if="!isEditing">{{ parseUnit(data.unit) }}</span>
            <select
              class="add-offer-input"
              v-model="tempData.unit"
              v-if="isEditing"
            >
              <option :value="1">pc</option>
              <option :value="2">h</option>
              <option :value="3">km</option>
              <option :value="4">l</option>
              <option :value="5">m²</option>
              <option :value="6">m³</option>
              <option :value="8">kg</option>
              <option :value="7">day</option>
              <option :value="9">month</option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">Ühikuhind</div>
        <div class="flex w-7/12 text-sm">
          <div class="flex justify-start px-1">
            <span v-if="!isEditing">{{ data.price }}</span>
            <input
              type="number"
              step="0.0001"
              class="add-offer-input"
              min="0"
              v-model="tempData.price"
              v-if="isEditing"
            />
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">Summa (-km)</div>
        <div class="flex w-7/12 text-sm">
          <div
            class="flex px-1"
            :class="isEditing ? 'justify-center' : 'justify-start'"
          >
            {{ calculatedSumNoVat }}€
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">KM%</div>
        <div class="flex w-7/12 text-sm">
          <div class="flex justify-start px-1">
            <span v-if="!isEditing">{{ vatType }}</span>
            <select
              class="add-offer-input"
              v-model="tempData.vat"
              v-if="isEditing"
            >
              <option :value="0">0%</option>
              <option :value="20">20%</option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-5/12 font-medium text-sm">KM</div>
        <div class="flex w-7/12 text-sm">
          <div
            class="flex px-1"
            :class="isEditing ? 'justify-center' : 'justify-start'"
          >
            {{ calculatedVat }}€
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      @closeModal="confirmRemoveModalActive = false"
      @confirm="removeService"
      text="Olete kindel, et soovite valitud pakkumise rea eemaldada?"
      v-if="confirmRemoveModalActive"
    ></confirm-modal>
  </div>
</template>

<script>
import UnitParser from "@/assets/mixins/UnitParser";
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  name: "ServiceRowExistingOffer",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    offerStatus: {
      type: Number,
      default: null
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isEditing: Boolean
  },
  mixins: [UnitParser],
  components: {
    ConfirmModal
  },
  data() {
    return {
      tempData: {},
      vat: 0.2,
      confirmRemoveModalActive: false,
      displayMobileTray: false
    };
  },
  mounted() {
    this.tempData = this.data;
  },
  methods: {
    removeService() {
      this.$emit("removeService", this.data.id);
    },
    editService() {
      this.isEditing = true;
    },
    confirmEdit() {
      this.$emit("serviceUpdated", this.tempData);
      this.isEditing = false;
    },
    cancelEdit() {
      this.isEditing = false;
    }
  },
  computed: {
    calculatedVat() {
      let vat;
      if (this.tempData.vat === 20) {
        vat =
          parseFloat(this.tempData.price) *
          parseFloat(this.tempData.amount) *
          parseFloat(this.vat);
      } else {
        vat = 0;
      }
      return vat.toFixed(2);
    },
    calculatedSum() {
      let sum =
        parseFloat(this.calculatedSumNoVat) + parseFloat(this.calculatedVat);
      return sum.toFixed(2);
    },
    vatType() {
      if (this.data.vat === 20) {
        return "20%";
      } else {
        return "0%";
      }
    },
    calculatedSumNoVat() {
      let sum =
        parseFloat(this.tempData.price) * parseFloat(this.tempData.amount);
      return sum.toFixed(2);
    }
  }
};
</script>

<style lang="scss">
.service-circle-button {
  @apply rounded-full shadow duration-100;
  span::before {
    @apply transform duration-150;
  }
  &:hover {
    @apply shadow-outlinedark;
    span::before {
      @apply scale-105;
    }
  }
}
</style>
