<template>
  <div class="flex mb-1 items-center form-label">
    <div class="header mr-2" v-html="generateHeader"></div>
    <div class="flex items-center">
      <span
        v-if="required || error"
        class="required-label"
        :class="
          this.valid
            ? 'bg-green text-white rounded-full w-7'
            : this.error
            ? 'bg-danger text-white rounded-xl py-1 px-2'
            : 'bg-attention text-black rounded-xl py-1 px-2'
        "
      >
        <span
          class="typcn"
          :class="valid ? 'typcn-tick' : error ? 'typcn-times mr-2' : ''"
        ></span>
        <span v-if="!valid">{{ errorText }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    required: { type: Boolean, default: false },
    headingLevel: { type: Number, default: 4 },
    valid: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorText: { type: String, default: "Kohustuslik" }
  },
  methods: {},
  computed: {
    generateHeader() {
      return `<h${this.headingLevel}>${this.title}</h${this.headingLevel}>`;
    }
  }
};
</script>

<style lang="scss">
.required-label {
  @apply h-7 text-xs font-semibold items-center justify-center flex transition duration-150;
}
</style>
