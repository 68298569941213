var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-8 w-8 border-2 justify-center items-center rounded-xl duration-100 cursor-pointer",class:[
    _vm.val && !_vm.hovered ? 'bg-green border-green' : '',
    _vm.val && _vm.hovered ? 'bg-danger border-danger' : '',
    !_vm.val && _vm.hovered ? 'bg-offwhite border-green mobile:bg-white' : '',
    !_vm.val && !_vm.hovered ? 'border-green' : ''
  ],on:{"click":_vm.toggle,"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('span',{staticClass:"typcn text-xl leading-none text-white",class:_vm.val
        ? 'text-white typcn-tick'
        : _vm.hovered
        ? 'text-green typcn-tick'
        : 'text-white'})])}
var staticRenderFns = []

export { render, staticRenderFns }