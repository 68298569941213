<template>
  <div class="modal">
    <div class="modal-body modal-medium mobile:justify-center">
      <div class="modal-content flex-col">
        <span class="text-2xl mb-4">Funktsiooni kasutamine piiratud</span>
        <span
          v-if="
            $store.state.companyData.activeCompany.worker_permission === 'R0'
          "
          >Teil on hetkel kasutusel "Baas" pakett, mis ei hõlma antud
          funktsiooni. Selleks, et seda funktsiooni kasutada, uuendage oma
          paketti
          <span v-if="!$store.state.companyData.activeCompany.trial_period_used"
            >või aktiveerige prooviperiood</span
          >.</span
        >
        <span
          v-if="
            !$store.state.companyData.activeCompany.worker_permission === 'R0'
          "
          >Teie firmal ei ole Gewodo Premium paketti. Selleks, et seda
          funktsiooni kasutada, uuendage oma firma paketti.</span
        >
      </div>

      <div class="modal-bottom-bar">
        <button
          class="new-button-green mr-3 mobile:mr-0 mobile:mb-4"
          @click="goToAddSub()"
          v-if="
            $store.state.companyData.activeCompany.worker_permission === 'R0'
          "
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Uuenda paketti</span>
        </button>
        <button
          class="new-button-green mr-3 mobile:mr-0 mobile:mb-4"
          @click="goToTrialPage()"
          v-if="
            !$store.state.companyData.activeCompany.trial_period_used &&
              $store.state.companyData.activeCompany.worker_permission === 'R0'
          "
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Aktiveeri prooviperiood</span>
        </button>
        <button class="new-button-danger" @click="closeModal()">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToAddSub() {
      this.$store.dispatch("userData/closeSubWarning");
      this.$router.push("/subscriptions/add");
    },
    goToTrialPage() {
      this.$store.dispatch("userData/closeSubWarning");
      this.$router.push("/trial");
    },
    closeModal() {
      this.$store.dispatch("userData/closeSubWarning");
    }
  }
};
</script>
