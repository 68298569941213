<template>
  <div
    class="footer w-full flex bg-offwhite text-darkgrey py-6 mobile:flex-col flex-row"
  >
    <div class="mobile:w-11/12 w-9/12 flex mx-auto flex-row mobile:flex-wrap">
      <div
        class="flex flex-col mobile:w-full w-3/12 mobile:justify-start items-start mobile:items-center mobile:mb-3  px-3"
      >
        <div class="w-10/12 mobile:w-full mr-auto justify-center flex">
          <img class="flex w-64 h-auto" src="/logotext.png" />
        </div>

        <div
          class="flex text-3xl justify-center w-10/12 mobile:w-full mr-auto "
        >
          <a
            href="https://www.facebook.com/Gewodo-105097304633348"
            target="_blank"
          >
            <span
              class="typcn typcn-social-facebook-circular mr-2 hover:text-green"
            ></span
          ></a>
          <a
            href="https://www.linkedin.com/company/gewodo/about/"
            target="_blank"
          >
            <span
              class="typcn typcn-social-linkedin-circular mx-2 hover:text-green"
            ></span>
          </a>
          <a href="https://www.instagram.com/gewodoeesti/" target="_blank">
            <span
              class="typcn typcn-social-instagram-circular mx-2 hover:text-green"
            ></span>
          </a>
        </div>
      </div>
      <div
        class="flex flex-col mobile:w-full w-3/12 items-start mobile:items-center mobile:mb-3"
      >
        <h4>Ettevõte</h4>
        <ul>
          <li class="footer-item">
            <router-link to="/about-us">Meist</router-link>
          </li>
          <li class="footer-item">
            <router-link to="/contact">Kontakt</router-link>
          </li>
          <li class="footer-item">
            <router-link
              to="/privacy-policy"
              class="hover:font-semibold active:font-semibold"
              >Privaatsuspoliitika</router-link
            >
          </li>
          <li class="footer-item">
            <router-link to="/terms" class="hover:font-semibold"
              >Tingimused</router-link
            >
          </li>
        </ul>
      </div>
      <div
        class="flex flex-col mobile:w-full w-3/12 items-start mobile:items-center mobile:mb-3"
      >
        <h4>Teenus</h4>
        <ul class="">
          <li class="footer-item">
            <router-link to="/product">Toode</router-link>
          </li>
          <li class="footer-item">
            <router-link
              to="/demo"
              class="hover:font-semibold active:font-semibold"
              >Demo</router-link
            >
          </li>
          <li class="footer-item">
            <router-link
              to="/pricing"
              class="hover:font-semibold active:font-semibold"
              >Hinnastamine</router-link
            >
          </li>
        </ul>
      </div>
      <div
        class="flex mobile:flex-row flex-col mobile:w-full w-3/12 mobile:mb-3 mobile:flex-wrap"
      >
        <div
          class="flex flex-col mobile:mb-3 mobile:mt-0 mobile:w-full mobile:items-center mobile:justify-center"
        >
          <h4>Gewodo OÜ</h4>
          <span>info@gewodo.com</span>
          <span>16008050</span>
          <span>EE102271504</span>
          <span>
            Jõe tn 3, Tallinn
          </span>
          <span>10151</span>
          <span>Harju maakond</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: false
    };
  }
};
</script>

<style lang="scss">
.footer {
  height: auto;
  .footer-item {
    a:hover {
      @apply font-semibold;
    }
    .router-link-active {
      @apply font-semibold;
    }
  }
}
@media screen and (max-width: 992px) {
  .footer {
    .footer-item {
      @apply text-center;
    }
  }
}
</style>
