const PrivacyPolicy = () => import("@/views/public/PrivacyPolicy");
const Terms = () => import("@/views/public/TermsAndConditions");
const Contact = () => import("@/views/public/Contact");
const Support = () => import("@/views/public/TechSupport");
const CookieDeclaration = () => import("@/views/public/CookieDeclaration");
const Landing = () => import("@/views/public/Landing");
const AboutUs = () => import("@/views/public/AboutUs");
const Product = () => import("@/views/public/Product");
const Pricing = () => import("@/views/public/Pricing");
const Demo = () => import("@/views/public/Demo");
const publicRoutes = [
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      public: true,
      title: "Privaatsustingimused | Gewodo"
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: {
      public: true,
      title: "Kasutustingimused | Gewodo"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      public: true,
      title: "Kontakt | Gewodo"
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      public: true,
      title: "Klienditugi | Gewodo"
    }
  },
  {
    path: "/cookie-declaration",
    name: "CookieDeclaration",
    component: CookieDeclaration,
    meta: {
      public: true,
      title: "Küpsiste kasutamine | Gewodo"
    }
  },
  {
    path: "/landing",
    name: "Landing",
    component: Landing,
    meta: {
      public: true,
      title: "Gewodo"
    }
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      public: true,
      title: "Meist | Gewodo"
    }
  },
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
    meta: {
      public: true,
      title: "Demo | Gewodo"
    }
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    meta: {
      public: true,
      title: "Hinnastamine | Gewodo"
    }
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    meta: {
      public: true,
      title: "Toode | Gewodo"
    }
  }
];

export default publicRoutes;
