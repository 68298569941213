<template>
  <div class="modal">
    <div class="modal-body modal-medium">
      <span class="text-2xl mb-4">Töötajana kasutamine piiratud</span>
      <div class="flex flex-col">
        <span>Paketi kasutusaeg on lõppenud.</span>
        <span
          >Palun võtke ettevõtte omanikuga ühendust, ning paluge seda uuendada.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkerCannotAccess"
};
</script>
