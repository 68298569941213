var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"w-full flex h-navbar z-40 bg-white shadow px-12 mobile:px-0 relative",class:!_vm.$store.state.userData.user.is_email_verified &&
    _vm.$store.state.auth.status.loggedIn &&
    this.$route.name !== 'ResendActivation'
      ? 'mb-16'
      : ''},[_c('div',{staticClass:"w-full flex mx-auto flex-row mobile:py-3 mobile:mx-0"},[_c('div',{staticClass:"w-3/12 mobile:w-1/2 flex mobile:justify-between justify-start items-center mobile:px-3",class:_vm.$store.state.auth.status.loggedIn ? 'mobile:w-1/2' : 'mobile:w-full'},[_c('router-link',{class:_vm.logoLink === _vm.$route.path ? 'cursor-default' : 'cursor-pointer',attrs:{"to":_vm.logoLink}},[_c('img',{staticClass:"mobile:h-12 h-16 p-3 mobile:p-1 mobile:flex hidden",attrs:{"src":"/favicon.ico"}}),_c('img',{staticClass:"mobile:h-12 h-16 p-3 mobile:p-1 mobile:hidden",attrs:{"src":"/logonotext.png"}})]),(
          !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
        )?_c('button',{staticClass:"h-10 w-10 duration-100 hidden mobile:flex",class:_vm.hamburgerActive
            ? 'bg-white shadow-dangeroutline focus:bg-white text-danger'
            : 'bg-green text-white focus:text-green focus:bg-white focus:shadow-outline',on:{"click":function($event){_vm.hamburgerActive = !_vm.hamburgerActive}}},[_c('span',{staticClass:"typcn ",class:_vm.hamburgerActive ? 'text-3xl typcn-times' : 'typcn-th-menu text-xl'})]):_vm._e()],1),(_vm.hamburgerActive)?_c('div',{staticClass:"mobile-hamburger-menu"},[_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_vm._v("Avaleht")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/product"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_vm._v("Toode")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/pricing"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_vm._v("Hinnastamine")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/about-us"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_vm._v("Meist")])],1),_c('router-link',{staticClass:"font-medium p-2 rounded-xl mb-4 text-center mx-3",class:_vm.$route.name === 'Login'
            ? 'bg-white text-green shadow-outline'
            : 'bg-green text-white shadow ',attrs:{"to":"/login"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_c('span',[_vm._v("Logi sisse")])]),_c('router-link',{staticClass:"font-medium p-2 rounded-xl text-center mx-3",class:_vm.$route.name === 'Register'
            ? 'bg-white text-green shadow-outline'
            : 'bg-green text-white shadow ',attrs:{"to":"/register"},nativeOn:{"click":function($event){_vm.hamburgerActive = false}}},[_vm._v("Registreeri")])],1):_vm._e(),(!_vm.$store.state.auth.status.loggedIn && !_vm.$route.meta.isProto)?_c('div',{staticClass:"flex justify-center w-6/12 h-16 mobile:hidden medium:self-center"},[_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Avaleht")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/product"}},[_vm._v("Toode")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/pricing"}},[_vm._v("Hinnastamine")])],1),_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/about-us"}},[_vm._v("Meist")])],1)]):_vm._e(),(_vm.$store.state.auth.status.loggedIn)?_c('div',{staticClass:"flex justify-center w-6/12 h-16 mobile:hidden medium:self-center items-center"},[_c('transition',{attrs:{"name":"fade","duration":100,"mode":"out-in"}},[(
            _vm.$store.state.timetracking.hasActiveTimer &&
              _vm.screenW &&
              _vm.screenW > 992 &&
              !_vm.$store.state.timetracking.loading
          )?_c('timer'):_vm._e()],1)],1):_vm._e(),(
        _vm.$store.state.auth.status.loggedIn && _vm.$store.state.userData.retrieved
      )?_c('div',{staticClass:"w-3/12 mobile:w-1/2 flex justify-end items-center flex-row mobile:px-3"},[_c('div',{staticClass:"flex items-center justify-end"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.$route.name !== 'Calendar')?_c('div',{staticClass:"h-10 w-10 rounded-full bg-offwhite mr-3 flex justify-center items-center hover:bg-darkgreen hover:text-white cursor-pointer",on:{"click":function($event){return _vm.$router.push('/calendar')}}},[_c('span',{staticClass:"typcn typcn-home h-7 w-7 leading-none text-2xl flex justify-center items-center"})]):_vm._e()]),_c('div',{staticClass:"rounded-full p-2 leading-none relative mr-3 h-10 w-10 flex items-center justify-center duration-100 cursor-pointer",class:_vm.$store.state.notifications.notificationsLoaded &&
            _vm.$store.state.notifications.unread.length > 0
              ? 'bg-green hover:bg-darkgreen'
              : 'bg-offwhite hover:bg-darkgreen',on:{"mouseenter":function($event){_vm.notificationTrayButtonHovered = true},"mouseleave":function($event){_vm.notificationTrayButtonHovered = false},"click":_vm.toggleNotificationTray}},[_c('span',{staticClass:"text-white ",attrs:{"role":"button"}},[_c('img',{staticClass:"h-7 w-7",class:_vm.$store.state.notifications.unread.length > 0
                  ? 'filter-to-white'
                  : _vm.notificationTrayButtonHovered
                  ? 'filter-to-white'
                  : '',attrs:{"src":"/bc21/bell.svg"}})]),(
              _vm.$store.state.notifications.notificationsLoaded &&
                _vm.$store.state.notifications.unread.length > 0
            )?_c('span',{staticClass:"absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer",attrs:{"role":"button"}},[_vm._v(_vm._s(_vm.$store.state.notifications.unread.length))]):_vm._e()]),_c('div',{staticClass:"flex relative cursor-pointer duration-100",on:{"click":function($event){return _vm.toggleUserMenu()},"mouseenter":function($event){_vm.userMenuButtonHovered = true},"mouseleave":function($event){_vm.userMenuButtonHovered = false}}},[(
              Object.keys(this.$store.state.userData.user.image).length > 0
            )?_c('img',{staticClass:"rounded-full cursor-pointer h-12 w-12 p-1 hover:shadow-outline navbar-profile-image",attrs:{"src":_vm.profileImage,"data-cy":"usermenu"}}):_c('div',{staticClass:"bg-offwhite h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-darkgreen"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.getInitials()))])]),_vm._m(0)])],1)]):_vm._e(),(
        !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
      )?_c('div',{staticClass:"flex-auto self-center flex justify-end items-center flex-row mobile:hidden"},[_c('router-link',{staticClass:"mobile:hidden",attrs:{"to":"/login"}},[_c('button',{staticClass:"my-3 mx-2 self-end mobile:text-sm new-button-green p-2",class:_vm.$route.name === 'Login' ? 'bg-white' : ''},[_c('span',{staticClass:"label text-lg py-1"},[_vm._v("Logi sisse")])])]),_c('router-link',{attrs:{"to":"/register"}},[_c('button',{staticClass:"my-3 mx-2 self-end mobile:text-sm new-button-green p-2"},[_c('span',{staticClass:"label text-lg py-1"},[_vm._v("Registreeri")])])])],1):_vm._e(),_c('div',{staticClass:"navbar-menu-container"},[_c('notification-tray',{attrs:{"active":_vm.notificationTrayActive},on:{"closeTray":function($event){_vm.notificationTrayActive = false}}}),_c('user-menu',{attrs:{"active":_vm.showUserMenu}})],1)]),(
      _vm.$store.state.auth.status.loggedIn &&
        !_vm.$store.state.userData.user.is_email_verified &&
        _vm.$store.state.userData.retrieved &&
        this.$route.name !== 'ResendActivation'
    )?_c('div',{staticClass:"verify-email-notification"},[_c('p',[_vm._v(" Et kasutada lehe funktsioone, palun kinnitage oma meilikonto. Kui te ei ole vastavat e-kirja saanud, vajutage "),_c('router-link',{staticClass:"cursor-pointer text-highlightblue",attrs:{"to":"/resend-activation"}},[_vm._v("siia")]),_vm._v(". ")],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gear-icon"},[_c('img',{staticClass:"filter-to-white h-3 w-3",attrs:{"src":"/bc21/gear.svg"}})])}]

export { render, staticRenderFns }