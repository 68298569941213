import jwtdecode from "jwt-decode";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";

const initState = {
  unread: [],
  notificationsLoaded: false
};

export const notifications = {
  namespaced: true,
  state: initState,
  mutations: {
    setUnreadNotifications(state, data) {
      state.unread = data;
      state.notificationsLoaded = true;
    },
    setReadNotifications(state, data) {
      state.read = data;
    },
    clearNotificationState(state) {
      state.unread = [];
      state.notificationsLoaded = false;
    }
  },
  actions: {
    retrieveNotifications({ commit }) {
      StoreRequestHandler.apiRequest(
        `notifications/${
          jwtdecode(localStorage.getItem("access")).user_id
        }/unread/`,
        "get",
        true
      ).then(res => {
        commit("setUnreadNotifications", res.data);
      });
    },
    readNotification({ dispatch }, data) {
      StoreRequestHandler.apiRequest(
        `notifications/${data}/read/`,
        "put",
        true,
        data
      ).then(res => {
        if (res.status === 200) {
          dispatch("retrieveNotifications");
        }
      });
    },
    readAllUnread({ dispatch }) {
      StoreRequestHandler.apiRequest(
        `notifications/${
          jwtdecode(localStorage.getItem("access")).user_id
        }/all/read/`,
        "put",
        true
      ).then(() => {
        dispatch("retrieveNotifications");
      });
    },
    clearNotificationState({ commit }) {
      commit("clearNotificationState");
    }
  }
};
