<template>
  <router-link
    :to="target"
    class="dashboard-sidebar-item"
    v-if="
      permissions.includes(
        $store.state.companyData.activeCompany.worker_permission
      )
    "
  >
    <img :src="icon" class="mr-3 mobile:mr-0" />
    <span>{{ text }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      default: "/"
    },
    icon: {
      type: String,
      default: "/bc21/märkmik.svg"
    },
    text: {
      type: String,
      default: "Placeholder"
    },
    permissions: {
      type: Array,
      default: () => {
        return ["R0", "R1", "R2", "R3", "R4", "R5"];
      }
    }
  }
};
</script>

<style></style>
