<template>
  <div class="flex flex-col items-start w-full">
    <input
      type="text"
      v-model="description"
      @change="updateItemDescription"
      class="w-full"
      placeholder="Pealkiri"
    />
  </div>
</template>

<script>
export default {
  name: "NewTaskTodo",
  props: {
    index: Number,
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sub_items: [],
      startIndex: 100,
      description: ""
    };
  },
  methods: {
    updateItemDescription() {
      this.$emit("itemUpdated", this.description);
    },
    addNewItem() {
      this.$emit("addItem");
    }
  }
};
</script>

<style scoped></style>
