export const offerViewer = {
  state: {
    offerModal: {
      show: false,
      data: null
    },
    newOfferModal: {
      show: false,
      data: null
    }
  },
  namespaced: true,
  mutations: {
    openOfferModal(state, data) {
      state.offerModal.data = data;
      state.offerModal.show = true;
    },
    closeOfferModal(state) {
      state.offerModal.data = null;
      state.offerModal.show = false;
    },
    openNewOfferModal(state, data) {
      state.newOfferModal.data = data;
      state.newOfferModal.show = true;
    },
    closeNewOfferModal(state) {
      state.newOfferModal.data = null;
      state.newOfferModal.show = false;
    }
  },
  actions: {
    openOfferModal({ commit }, data) {
      commit("openOfferModal", data);
    },
    closeOfferModal({ commit }) {
      commit("closeOfferModal");
    },
    openNewOfferModal({ commit }, data) {
      commit("openNewOfferModal", data);
    },
    closeNewOfferModal({ commit }) {
      commit("closeNewOfferModal");
    }
  }
};
