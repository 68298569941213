export const invoiceViewer = {
  state: {
    invoiceModal: {
      show: false,
      data: null
    },
    newInvoiceModal: {
      show: false,
      data: null
    }
  },
  namespaced: true,
  mutations: {
    openInvoiceModal(state, data) {
      state.invoiceModal.show = true;
      state.invoiceModal.data = data;
    },
    closeInvoiceModal(state) {
      state.invoiceModal.show = false;
      state.invoiceModal.data = null;
    },
    openAddInvoiceModal(state, data) {
      state.newInvoiceModal.show = true;
      state.newInvoiceModal.data = data;
    },
    closeAddInvoiceModal(state) {
      state.newInvoiceModal.show = false;
      state.newInvoiceModal.data = null;
    }
  },
  actions: {
    openInvoiceModal({ commit }, data) {
      commit("openInvoiceModal", data);
    },
    closeInvoiceModal({ commit }) {
      commit("closeInvoiceModal");
    },
    openAddInvoiceModal({ commit }, data) {
      commit("openAddInvoiceModal", data);
    },
    closeAddInvoiceModal({ commit }) {
      commit("closeAddInvoiceModal");
    }
  }
};
