<template>
  <div class="flex flex-col py-2">
    <div class="flex flex-col w-full mb-1">
      <div
        class="flex flex-row items-center task-todo rounded-xl"
        :class="canEdit ? 'hover:bg-offwhite hover:bg-opacity-50' : ''"
      >
        <div class="w-8 flex justify-center mr-2">
          <span
            class="flex justify-center items-center rounded-xl shadow-sm bg-darkgrey text-white h-8 w-8"
          >
            <span
              class="typcn typcn-input-checked text-2xl leading-none justify-center items-center"
            ></span>
          </span>
        </div>
        <h4
          class="w-10/12 mobile:w-10/12"
          :class="todo.done ? 'line-through' : ''"
        >
          {{ todo.description }}
        </h4>
        <div
          class="flex items-center justify-center task-todo-delete"
          v-if="
            !$store.state.companyData.isClient &&
              ['R0', 'R1', 'R2', 'R3'].includes(
                $store.state.companyData.activeCompany.worker_permission
              )
          "
        >
          <button class="alt-button-danger" @click="confirmDeleteActive = true">
            <span class="typcn typcn-trash icon"></span>
            <span class="label">Kustuta</span>
          </button>
        </div>
      </div>
      <div class="flex flex-row items-center py-1">
        <span class="w-8 text-sm flex justify-center mr-2 px-2">
          {{ donePercentage }}</span
        >
        <div class="flex w-auto flex-grow mobile:w-10/12 h-1 shadow rounded-xl">
          <div
            class="h-full rounded-md todo-progressbar"
            :id="'doneItems' + todo.id"
          ></div>
          <div
            class="bg-offwhite h-full rounded-md todo-progressbar"
            :id="'notDoneItems' + todo.id"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="todo.sub_items.length > 0 && todo.parent_todo === null"
      class="flex flex-col w-full mb-2"
    >
      <transition-group name="list">
        <task-todo-item
          v-for="subItem in todo.sub_items"
          :key="subItem.id"
          :todo="subItem"
          @todoChanged="changeTodoStatus"
          @deleteTodo="deleteTodo"
        ></task-todo-item>
      </transition-group>
    </div>
    <transition name="fade">
      <div class="flex flex-col justify-end" v-if="addItem">
        <new-task-todo
          :key="newItem.index"
          :index="newItem.index"
          :show-add-button="false"
          @itemUpdated="handleItemUpdate"
          @addItem="handleAddItem"
          class="mb-2"
        ></new-task-todo>
      </div>
    </transition>
    <transition
      name="fade"
      @after-leave="displayAddButton = false"
      @enter="displayAddButton = true"
    >
      <div
        class="flex mb-2"
        v-if="
          !addItem &&
            !$store.state.companyData.isClient &&
            canEdit &&
            !displayItemAddButtons
        "
      >
        <button class="alt-button-green mr-2" @click="addItem = true">
          <span class="icon typcn typcn-plus"></span>
          <span class="label">Lisa</span>
        </button>
      </div>
    </transition>

    <transition
      name="fade"
      @after-leave="displayItemAddButtons = false"
      @enter="displayItemAddButtons = true"
    >
      <div
        class="flex mb-2"
        v-if="
          !$store.state.companyData.isClient &&
            canEdit &&
            addItem &&
            !displayAddButton
        "
      >
        <button class="alt-button-green mr-2" @click="addNewItem">
          <span class="icon typcn typcn-plus"></span>
          <span class="label">Lisa</span>
        </button>
        <button class="alt-button-danger" @click="cancelAddItem">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </transition>

    <confirm-modal
      v-if="confirmDeleteActive"
      text="Olete kindel, et soovite valitud tegevuse kustutada?"
      @confirm="deleteTodo(todo.id)"
      @closeModal="confirmDeleteActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import TaskTodoItem from "@/components/tasks/todo/TaskTodoItem";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import NewTaskTodo from "@/components/tasks/todo/NewTaskTodo";
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  name: "TaskTodo",
  components: { NewTaskTodo, ConfirmModal, TaskTodoItem },
  mixins: [RequestHandler],
  props: {
    todo: Object,
    task: Number,
    index: Number,
    canEdit: Boolean
  },
  data() {
    return {
      confirmDeleteActive: false,
      newItem: { description: "", index: null },
      addItem: false,
      startIndex: 1,
      donePercentage: null,
      displayAddButton: true,
      displayItemAddButtons: false,
      completionObject: {
        number: null,
        completed: null,
        done: null,
        notDone: null
      }
    };
  },
  methods: {
    handleItemUpdate(e) {
      this.newItem.description = e;
      this.newItem.index = this.startIndex;
    },
    cancelAddItem() {
      this.addItem = false;
      this.newItem.description = "";
    },
    addNewItem() {
      this.apiRequest(
        "tasks/" + this.task + "/todos/" + this.todo.id + "/add/",
        "post",
        true,
        [this.newItem]
      ).then(res => {
        if (res.status === 201) {
          this.startIndex += 1;
          this.newItem.description = "";
          this.$emit("todoItemSaved");
        }
      });
      this.startIndex += 1;
      this.addItem = true;
    },
    handleAddItem() {
      this.$emit("addNewItem");
      this.startIndex += 1;
      this.addItem = true;
    },
    changeTodoStatus(e) {
      this.$emit("todoChanged", e);
    },
    deleteTodo(e) {
      this.apiRequest(
        "tasks/" + this.task + "/todos/" + e + "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.$emit("triggerReload");
        }
      });
    },
    calculateDonePercentage() {
      if (this.todo.sub_items.length === 0) {
        this.donePercentage = "0%";
      } else {
        if (this.todo.sub_items_done) {
          this.donePercentage = "100%";
        } else {
          this.donePercentage =
            Math.round(
              ((this.todo.sub_items.length -
                this.todo.sub_items.filter(x => !x.done).length) /
                this.todo.sub_items.length) *
                100
            ) + "%";
        }
      }
    },
    initTodoStatus() {
      let notDoneItems = document.getElementById("notDoneItems" + this.todo.id);
      let doneItems = document.getElementById("doneItems" + this.todo.id);

      if (this.todo.sub_items.length === 0) {
        doneItems.style.width = "0%";
        notDoneItems.style.width = "100%";
      } else {
        if (this.todo.sub_items_done) {
          doneItems.style.backgroundColor = "#23c7ac";
          doneItems.style.width = "100%";
          notDoneItems.style.width = "0%";
        } else {
          let subItemAmount = this.todo.sub_items.length;
          let doneItemAmount = this.todo.sub_items.filter(x => x.done).length;
          doneItems.style.backgroundColor = "#5E17EB";
          doneItems.style.width = (doneItemAmount / subItemAmount) * 100 + "%";
          notDoneItems.style.width =
            ((subItemAmount - doneItemAmount) / subItemAmount) * 100 + "%";
        }
      }
    }
  },
  mounted() {
    this.$nextTick(this.calculateDonePercentage());
  },
  watch: {
    todo: {
      deep: true,
      handler() {
        this.calculateDonePercentage();
      }
    },
    donePercentage() {
      this.initTodoStatus();
    }
  }
};
</script>

<style lang="scss">
.todo-progressbar {
  transition: all 0.1s ease-out;
}
</style>
