<template>
  <div
    class="flex items-center justify-between rounded-xl items-center w-full mb-2"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="flex items-center">
      <base-checkbox
        v-if="!$store.state.companyData.isClient"
        :base-val="todo.done"
        @valueChanged="changeTodoStatus(todo.id)"
        class="mr-2"
      ></base-checkbox>
      <span
        v-if="$store.state.companyData.isClient"
        class="typcn mr-2 shadow-none px-2"
        :class="todo.done ? 'typcn-tick text-green' : 'typcn-times text-danger'"
      ></span>
      <span :class="todo.done ? 'line-through' : ''">{{
        todo.description
      }}</span>
    </div>
    <div
      class="rounded-xl border-2 border-danger hover:bg-danger text-danger hover:text-white cursor-pointer flex duration-75"
      v-if="
        !$store.state.companyData.isClient &&
          ['R0', 'R1', 'R2', 'R3'].includes(
            $store.state.companyData.activeCompany.worker_permission
          )
      "
      @click="confirmDeleteActive = true"
    >
      <span class="typcn typcn-trash text-lg px-1"></span>
    </div>

    <confirm-modal
      v-if="confirmDeleteActive"
      text="Olete kindel, et soovite valitud tegevuse kustutada?"
      @confirm="deleteTodo()"
      @closeModal="confirmDeleteActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  name: "TaskTodoItem",
  components: { ConfirmModal },
  props: {
    todo: Object
  },
  data() {
    return {
      confirmDeleteActive: false,
      isHovered: false
    };
  },
  methods: {
    changeTodoStatus(e) {
      this.$emit("todoChanged", e);
    },
    deleteTodo() {
      this.$emit("deleteTodo", this.todo.id);
    }
  }
};
</script>
