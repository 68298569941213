import debounce from "lodash/debounce";

export default {
  data() {
    return {
      screenH: 0,
      screenW: 0,
      screenResizeHandler: null
    };
  },
  mounted() {
    this.screenH = window.innerHeight;
    this.screenW = window.innerWidth;

    this.screenResizeHandler = window.addEventListener(
      "resize",
      debounce(() => {
        this.handleScreenResize();
      }, 150)
    );
  },
  methods: {
    handleScreenResize() {
      this.screenH = window.innerHeight;
      this.screenW = window.innerWidth;
    }
  }
};
