import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

import CompanyRoutes from "./modules/company";
import CoreRoutes from "./modules/core";
import ClientRoutes from "./modules/client";
import AccountRoutes from "./modules/account";
import PublicRoutes from "./modules/public";
Vue.use(VueRouter);

const routes = [].concat(
  CompanyRoutes,
  CoreRoutes,
  ClientRoutes,
  AccountRoutes,
  PublicRoutes
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const isPublic = routes.find(item => item.name === to.name).meta.public;
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const isLoggedIn = store.state.auth.status.loggedIn;
  store.dispatch("userData/initCookieState");
  if (isLoggedIn) {
    if (!store.state.userData.retrieved) {
      await store.dispatch("userData/retrieveProfileData");
    }
    if (!store.state.companyData.retrieved) {
      await store.dispatch("companyData/retrieveCompanyData");
    }
    if (
      store.state.userData.retrieved &&
      store.state.companyData.retrieved &&
      !store.state.companyData.isClient
    ) {
      await store.dispatch("timetracking/retrieveTimers");
    }
    if (store.state.userData.user.is_email_verified) {
      store.dispatch("notifications/retrieveNotifications");
    }
    if (to.path === "/login") next("/calendar");
    next();
  } else {
    if (isPublic) {
      next();
    } else {
      if (accessToken && refreshToken) {
        store.dispatch("auth/relogin").then(
          res => {
            next(to.fullPath);
            res;
          },
          err => {
            console.error(err);
            store.dispatch("auth/logout");
            next("/login");
          }
        );
      } else {
        store.dispatch("auth/logout");
        next("/login");
      }
    }
  }
});

export default router;
