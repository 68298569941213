var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col py-2"},[_c('div',{staticClass:"flex flex-col w-full mb-1"},[_c('div',{staticClass:"flex flex-row items-center task-todo rounded-xl",class:_vm.canEdit ? 'hover:bg-offwhite hover:bg-opacity-50' : ''},[_vm._m(0),_c('h4',{staticClass:"w-10/12 mobile:w-10/12",class:_vm.todo.done ? 'line-through' : ''},[_vm._v(" "+_vm._s(_vm.todo.description)+" ")]),(
          !_vm.$store.state.companyData.isClient &&
            ['R0', 'R1', 'R2', 'R3'].includes(
              _vm.$store.state.companyData.activeCompany.worker_permission
            )
        )?_c('div',{staticClass:"flex items-center justify-center task-todo-delete"},[_c('button',{staticClass:"alt-button-danger",on:{"click":function($event){_vm.confirmDeleteActive = true}}},[_c('span',{staticClass:"typcn typcn-trash icon"}),_c('span',{staticClass:"label"},[_vm._v("Kustuta")])])]):_vm._e()]),_c('div',{staticClass:"flex flex-row items-center py-1"},[_c('span',{staticClass:"w-8 text-sm flex justify-center mr-2 px-2"},[_vm._v(" "+_vm._s(_vm.donePercentage))]),_c('div',{staticClass:"flex w-auto flex-grow mobile:w-10/12 h-1 shadow rounded-xl"},[_c('div',{staticClass:"h-full rounded-md todo-progressbar",attrs:{"id":'doneItems' + _vm.todo.id}}),_c('div',{staticClass:"bg-offwhite h-full rounded-md todo-progressbar",attrs:{"id":'notDoneItems' + _vm.todo.id}})])])]),(_vm.todo.sub_items.length > 0 && _vm.todo.parent_todo === null)?_c('div',{staticClass:"flex flex-col w-full mb-2"},[_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.todo.sub_items),function(subItem){return _c('task-todo-item',{key:subItem.id,attrs:{"todo":subItem},on:{"todoChanged":_vm.changeTodoStatus,"deleteTodo":_vm.deleteTodo}})}),1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.addItem)?_c('div',{staticClass:"flex flex-col justify-end"},[_c('new-task-todo',{key:_vm.newItem.index,staticClass:"mb-2",attrs:{"index":_vm.newItem.index,"show-add-button":false},on:{"itemUpdated":_vm.handleItemUpdate,"addItem":_vm.handleAddItem}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"},on:{"after-leave":function($event){_vm.displayAddButton = false},"enter":function($event){_vm.displayAddButton = true}}},[(
        !_vm.addItem &&
          !_vm.$store.state.companyData.isClient &&
          _vm.canEdit &&
          !_vm.displayItemAddButtons
      )?_c('div',{staticClass:"flex mb-2"},[_c('button',{staticClass:"alt-button-green mr-2",on:{"click":function($event){_vm.addItem = true}}},[_c('span',{staticClass:"icon typcn typcn-plus"}),_c('span',{staticClass:"label"},[_vm._v("Lisa")])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"},on:{"after-leave":function($event){_vm.displayItemAddButtons = false},"enter":function($event){_vm.displayItemAddButtons = true}}},[(
        !_vm.$store.state.companyData.isClient &&
          _vm.canEdit &&
          _vm.addItem &&
          !_vm.displayAddButton
      )?_c('div',{staticClass:"flex mb-2"},[_c('button',{staticClass:"alt-button-green mr-2",on:{"click":_vm.addNewItem}},[_c('span',{staticClass:"icon typcn typcn-plus"}),_c('span',{staticClass:"label"},[_vm._v("Lisa")])]),_c('button',{staticClass:"alt-button-danger",on:{"click":_vm.cancelAddItem}},[_c('span',{staticClass:"icon typcn typcn-times"}),_c('span',{staticClass:"label"},[_vm._v("Tühista")])])]):_vm._e()]),(_vm.confirmDeleteActive)?_c('confirm-modal',{attrs:{"text":"Olete kindel, et soovite valitud tegevuse kustutada?"},on:{"confirm":function($event){return _vm.deleteTodo(_vm.todo.id)},"closeModal":function($event){_vm.confirmDeleteActive = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-8 flex justify-center mr-2"},[_c('span',{staticClass:"flex justify-center items-center rounded-xl shadow-sm bg-darkgrey text-white h-8 w-8"},[_c('span',{staticClass:"typcn typcn-input-checked text-2xl leading-none justify-center items-center"})])])}]

export { render, staticRenderFns }