<template>
  <div class="bg-white flex flex-col">
    <div
      class="flex py-3 px-2 duration-100 items-center"
      :class="[
        detailsActive ? 'bg-offwhite bg-opacity-50' : '',
        times.length > 0
          ? 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer'
          : ''
      ]"
      @click="activateDetails"
      @mouseover="
        ['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
        $store.state.userData.user.id === worker.profile
          ? (isHovered = true)
          : ''
      "
      @mouseleave="
        ['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
        $store.state.userData.user.id === worker.profile
          ? (isHovered = false)
          : ''
      "
    >
      <h4
        class="flex items-center"
        :class="
          ['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
          $store.state.userData.user.id === worker.profile
            ? 'w-9/12'
            : 'w-full'
        "
      >
        {{ worker.profile_name }}
      </h4>
      <div
        class="flex w-3/12 items-center justify-end"
        v-if="
          (['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
            $store.state.userData.user.id === worker.profile) &&
            $store.state.companyData.activeCompany.subscription_permissions
              .client_portal
        "
      >
        <span
          class="bg-green rounded-full h-2 w-2 ring-2 ring-opacity-20 ring-green"
          v-if="times.length > 0"
        ></span>
        <span class="font-medium ml-3">{{ totalTime }}</span>
      </div>
      <div class="flex w-1/12 justify-center items-center">
        <span
          class="typcn typcn-chevron-right transform duration-100 leading-none"
          :class="detailsActive ? 'rotate-90' : ''"
          v-if="times.length > 0"
        ></span>
      </div>
    </div>
    <div
      class="flex flex-col"
      v-if="
        detailsActive &&
          (['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
            $store.state.userData.user.id === worker.profile)
      "
    >
      <div
        class="flex p-2 items-center"
        v-for="item in times"
        :key="item.id"
        :class="[
          !item.stopper_end
            ? 'bg-green rounded-b-md text-white font-semibold'
            : '',
          item.start_location || item.end_location ? 'cursor-pointer' : ''
        ]"
        @click="openMap(item)"
      >
        <div class="w-4/12 flex items-center">
          {{ moment(item.stopper_start).format("DD.MM.YYYY") }}
        </div>
        <div class="w-4/12 justify-center flex items-center">
          <span>{{ formatStopperInterval(item) }}</span>
        </div>
        <div class="w-4/12 justify-end flex items-center">
          <span v-if="item.stopper_end">{{
            moment
              .utc(
                moment
                  .duration(
                    moment(item.stopper_end).diff(moment(item.stopper_start))
                  )
                  .asMilliseconds()
              )
              .format("HH:mm:ss")
          }}</span>
          <span v-if="!item.stopper_end">{{
            moment
              .utc(
                moment
                  .duration(moment().diff(moment(item.stopper_start)))
                  .asMilliseconds()
              )
              .format("HH:mm:ss")
          }}</span>
        </div>
      </div>
    </div>
    <div class="bg-offwhite w-full h-1 rounded-full"></div>
    <location-modal
      v-if="startLocationActive && activeItem"
      :start-location="activeItem.start_location"
      :end-location="activeItem.end_location"
      :task-location="taskLocation"
      @closeModal="closeModal"
    >
    </location-modal>
  </div>
</template>

<script>
import moment from "moment";
import LocationModal from "@/components/reusable/LocationModal";
export default {
  components: { LocationModal },
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    },
    times: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userRole: { type: String, default: "" },
    taskLocation: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      moment: moment,
      detailsActive: false,
      isHovered: false,
      startLocationActive: false,
      activeItem: null
    };
  },
  methods: {
    activateDetails() {
      if (this.times.length > 0) {
        this.detailsActive = !this.detailsActive;
      }
    },
    formatStopperInterval(item) {
      if (item.stopper_end) {
        return `${moment(item.stopper_start).format("HH:mm")} - ${moment(
          item.stopper_end
        ).format("HH:mm")}`;
      }
      return `${moment(item.stopper_start).format("HH:mm")} - ${moment(
        moment()
      ).format("HH:mm")}`;
    },
    closeModal() {
      this.startLocationActive = false;
      this.activeItem = null;
    },
    openMap(item) {
      if (
        (item.start_location && Object.keys(item.start_location).length > 0) ||
        (item.end_location && Object.keys(item.end_location).length > 0)
      ) {
        this.activeItem = item;
        this.startLocationActive = true;
      }
    }
  },
  computed: {
    totalTime() {
      let strToReturn;
      if (this.times.length > 0) {
        let totalDuration = null;
        let completedTimes = this.times.filter(x => x.stopper_end);
        for (let i = 0; i < completedTimes.length; i++) {
          if (completedTimes[i].stopper_end) {
            if (i === 0) {
              totalDuration = moment.utc(
                moment
                  .duration(
                    moment(completedTimes[i].stopper_end).diff(
                      moment(completedTimes[i].stopper_start)
                    )
                  )
                  .asMilliseconds()
              );
            } else {
              totalDuration.add(
                moment
                  .duration(
                    moment(completedTimes[i].stopper_end).diff(
                      moment(completedTimes[i].stopper_start)
                    )
                  )
                  .asMilliseconds()
              );
            }
          }
        }
        if (totalDuration > 0) {
          strToReturn = moment(totalDuration).format("HH:mm");
        } else {
          strToReturn = "00:00";
        }
      } else {
        strToReturn = "00:00";
      }

      return strToReturn;
    }
  }
};
</script>
