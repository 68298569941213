<template>
  <div class="off-status-menu z-10 mobile:bg-white">
    <span
      class="status-menu-item mobile:bg-white"
      v-if="data.status !== 2"
      v-html="parseDraftInvoiceStatus(2)"
      @click="changeOfferStatus(2)"
    >
    </span>
    <span
      v-if="data.status !== 4"
      class="status-menu-item mobile:bg-white"
      v-html="parseDraftInvoiceStatus(4)"
      @click="changeOfferStatus(4)"
    >
    </span>
    <span
      v-if="data.status !== 5"
      class="status-menu-item mobile:bg-white"
      v-html="parseDraftInvoiceStatus(5)"
      @click="changeOfferStatus(5)"
    >
    </span>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [StatusParser],
  methods: {
    changeOfferStatus(status) {
      this.$emit("changeStatus", status);
    }
  }
};
</script>

<style lang="scss">
.off-status-menu {
  @apply absolute bg-white shadow rounded-b-xl flex flex-col items-center w-full;
  top: 100%;
  .status-menu-item {
    @apply py-2 w-full justify-start flex cursor-pointer items-center;
    .status-label {
      @apply justify-start;
    }
    &:hover {
      @apply bg-offwhite;
    }
  }
}
</style>
