<template>
  <div class="flex flex-col">
    <div class="flex flex-row w-full mobile:flex-col" v-if="emailLoaded">
      <div class="flex flex-col mr-2 w-8/12 mobile:mr-0 mobile:w-full mb-4">
        <h4 v-if="emailTemplates.length > 0 && !email.sent">Mall</h4>
        <div
          class="flex w-full mb-6"
          v-if="emailTemplates.length > 0 && !email.sent"
        >
          <select v-model="emailTemplate" class="w-full">
            <option
              v-for="item in emailTemplates"
              :key="item.id"
              :value="item"
              >{{ item.description }}</option
            >
          </select>
        </div>
        <h4>Meil</h4>
        <div class="w-full mb-2 flex flex-col">
          <h4
            v-if="$v.$error && $v.email.client_email.$invalid"
            class="text-danger"
          >
            Kliendi meil on ebakorrektne
          </h4>
          <input
            type="text"
            placeholder="Saaja"
            class="w-full"
            v-model="email.client_email"
            :class="[
              $v.$error && $v.email.client_email.$invalid
                ? 'shadow-dangeroutline'
                : ''
            ]"
            @input="debounceSaveChanges"
          />
        </div>
        <div class="w-full mb-2 relative">
          <input
            type="text"
            placeholder="Teema"
            class="w-full"
            v-model="email.title"
            @input="debounceSaveChanges"
          />
        </div>

        <textarea
          rows="4"
          v-model="email.body"
          class="mb-2"
          placeholder="Sisu"
          @input="debounceSaveChanges"
        ></textarea>
        <textarea
          rows="3"
          v-model="email.footer"
          @input="debounceSaveChanges"
          placeholder="Allkiri"
          class="mb-6"
        ></textarea>
        <div class="flex flex-col mb-4">
          <h4>Manus</h4>
          <compact-file-display
            v-for="file in existingFiles"
            :key="file.id"
            :file="file"
            :draft="draft"
            @deleteFile="deleteFile"
            class="mb-2"
          ></compact-file-display>
          <div v-if="existingFiles.length === 0" class="mb-2">
            <span>Manus on tühi</span>
          </div>
          <div class="flex flex-row mb-4">
            <input
              ref="file"
              type="file"
              multiple
              hidden
              @change="updateFiles"
            />

            <button
              v-if="!email.sent"
              @click="selectFile()"
              class="alt-button-pending"
            >
              <span class="typcn typcn-document-add icon"></span>
              <span class="label">Lisa faile</span>
            </button>
          </div>
        </div>

        <div class="flex flex-col mb-2">
          <div
            v-for="(item, index) in fileList"
            :key="index"
            class="bg-offwhite font-semibold p-1 rounded-xl mb-1 w-full text-darkgrey shadow"
          >
            <div
              class="flex py-1 px-1 items-center w-full h-full justify-between"
            >
              <span class="flex">{{ item.name }}</span>
              <button
                class="bg-danger rounded-xl h-8 w-8 flex justify-center items-center hover:bg-dangerdark hover:shadow-dangeroutline"
                @click="removeFile(item)"
              >
                <span class="typcn typcn-times text-white"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="flex w-full mobile:hidden">
          <button
            v-if="!email.sent"
            class="new-button-green mr-3"
            @click="sendEmail()"
          >
            <span class="icon"
              ><img src="/bc21/mail.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label">Saada meil</span>
          </button>
          <saving-loader :saving="saving" :saved="saved"></saving-loader>
        </div>
        <div class="flex py-2">
          <span v-if="email.sent" class="flex items-center">
            <div
              class="flex bg-green mr-3 rounded-full items-center justify-center w-8 h-8"
            >
              <span
                class="typcn typcn-tick text-white text-2xl leading-none"
              ></span>
            </div>
            <h4 class="">E-kiri on kliendile edastatud.</h4>
          </span>
          <div class="flex flex-col items-start w-full" v-if="error">
            <div class="error-box mb-2 w-full">
              <span
                class="typcn typcn-times w-auto flex justify-center items-center leading-none text-2xl mr-4"
              ></span>
              <span class="flex"
                >Teie ettevõtte meiliaadress on kinnitamata!</span
              >
            </div>
            <router-link to="/settings" class="flex">
              <button class="new-button-green w-full">
                <div class="icon">
                  <img
                    src="/icons/logonohighlight.png"
                    class="filter-to-white"
                  />
                </div>
                <span class="label">Luba meiliaadressi kasutus</span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-4/12 ml-2 mobile:w-full mobile:ml-0">
        <pdf :draft="draft" :invoice="invoice"></pdf>
      </div>
      <div class="hidden flex-col w-full mobile:flex">
        <button
          v-if="!email.sent"
          class="new-button-green mobile:mb-4"
          @click="sendEmail()"
        >
          <span class="icon"
            ><img src="/bc21/mail.svg" class="h-5 filter-to-white"
          /></span>
          <span class="label">Saada meil</span>
        </button>
        <saving-loader :saving="saving" :saved="saved"></saving-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway2";
import { email, required } from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";
import debounce from "lodash/debounce";
import Pdf from "@/components/project/invoice/modal/PdfCompleter";
import CompactFileDisplay from "@/components/reusable/CompactFileDisplay";
import SavingLoader from "@/components/reusable/SavingLoader";

export default {
  name: "AddInvoiceEmail",
  props: {
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      emailTemplates: [],
      emailTemplate: null,
      fileList: [],
      email: {
        invoice: 0,
        client_email: "",
        title: "",
        body: "",
        footer: "",
        sent: false
      },
      existingFiles: [],
      saving: false,
      saved: false,
      savedTimeout: null,
      emailLoaded: false,
      error: false,
      addPdf: true
    };
  },
  mixins: [RequestHandler],
  components: { CompactFileDisplay, Pdf, SavingLoader },
  methods: {
    deleteFile(file) {
      if (this.existingFiles.find(item => item.id === file.id) !== undefined) {
        this.existingFiles.splice(
          this.existingFiles.findIndex(item => item.id === file.id),
          1
        );
      }
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/delete/",
        "delete",
        true,
        {
          document_id_list: [file.id]
        }
      ).then(res => {
        if (res.status === 200) {
          this.loadSavedEmail(false);
        }
      });
    },
    debounceSaveChanges: debounce(function() {
      this.updateEmail();
    }, 1000),
    removeFile(index) {
      if (this.fileList.find(item => item === index) !== undefined) {
        this.fileList.splice(
          this.fileList.findIndex(item => item === index),
          1
        );
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    updateFiles() {
      this.fileList = [...this.$refs.file.files];
      this.debounceSaveChanges();
    },
    loadEmailTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/",
        "get",
        true
      ).then(res => {
        this.emailTemplates = res.data.filter(x => x.type === 2);
        this.emailLoaded = true;
      });
    },
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.loading = true;
        const data = new FormData();
        data.append("recipient", this.email.client_email);
        data.append("title", this.email.title);
        data.append("body", this.email.body);
        data.append("footer", this.email.footer);
        data.append("add_pdf", this.addPdf);
        if (this.fileList.length > 0) {
          for (let i = 0; i < this.fileList.length; i++) {
            const file = this.fileList[i];
            const fileToAppend = new File([file], file.name, {
              type: file.type
            });
            data.append("files", fileToAppend, file.name);
          }
        }
        this.apiRequest(
          "notes/project/" +
            this.draft.uuid +
            "/invoice/" +
            this.invoice.id +
            "/send-email/",
          "post",
          true,
          data
        )
          .then(res => {
            if (res) {
              this.$emit("emailSent");
            }
            this.$store.state.loading = false;
            this.loadSavedEmail(false);
          })
          .catch(err => {
            if (err) {
              this.error = true;
              this.$store.state.loading = false;
            }
          });
      }
    },
    saveEmail() {
      this.saved = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = new FormData();
        data.append("client_email", this.email.client_email);
        data.append("title", this.email.title);
        data.append("body", this.email.body);
        data.append("footer", this.email.footer);
        data.append("invoice", this.email.invoice);
        if (this.fileList.length > 0) {
          for (let i = 0; i < this.fileList.length; i++) {
            const file = this.fileList[i];
            const fileToAppend = new File([file], file.name, {
              type: file.type
            });
            data.append("files", fileToAppend, file.name);
          }
        }
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            this.draft.uuid +
            "/invoice/" +
            this.invoice.id +
            "/email/new/",
          "post",
          true,
          data
        ).then(res => {
          if (res) {
            this.loadSavedEmail(false);
          }
        });
      }
    },
    updateEmail() {
      this.saved = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saving = true;
        let self = this;

        if (this.savedTimeout) {
          clearTimeout(this.savedTimeout);
          this.savedTimeout = null;
        }
        const data = new FormData();
        data.append("client_email", this.email.client_email);
        data.append("title", this.email.title);
        data.append("body", this.email.body);
        data.append("footer", this.email.footer);
        data.append("invoice", this.email.invoice);

        if (this.fileList.length > 0) {
          for (let i = 0; i < this.fileList.length; i++) {
            const file = this.fileList[i];
            const fileToAppend = new File([file], file.name, {
              type: file.type
            });
            data.append("files", fileToAppend, file.name);
          }
        }
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            this.draft.uuid +
            "/invoice/" +
            this.invoice.id +
            "/email/edit/",
          "patch",
          true,
          data
        ).then(res => {
          if (res) {
            this.saving = false;
            this.saved = true;
            this.savedTimeout = setTimeout(function() {
              self.saved = false;
            }, 5000);
            this.loadSavedEmail(false);
          }
        });
      }
    },
    loadSavedEmail(full) {
      this.fileList = [];
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/email/",
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          if (full) {
            this.email.client_email = res.data.client_email;
            this.email.title = res.data.title;
            this.email.body = res.data.body;
            this.email.footer = res.data.footer;
            this.existingFiles = res.data.files;
            this.email.sent = res.data.sent;
          } else {
            this.email.sent = res.data.sent;
            this.existingFiles = res.data.files;
          }
        }
        this.loadEmailTemplates();
      });
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  mounted() {
    this.email.invoice = this.invoice.id;
    this.loadSavedEmail(true);
  },
  validations: {
    email: {
      client_email: {
        email,
        required
      }
    }
  },
  watch: {
    emailTemplate() {
      this.email.footer = this.emailTemplate.footer;
      this.email.title = this.emailTemplate.title;
      this.email.body = this.emailTemplate.header;
      this.updateEmail();
    }
  }
};
</script>

<style lang="scss">
.add-email-input {
  @apply bg-offwhite shadow w-full;
  &:focus {
    @apply shadow-outline;
  }
}
.image-logo {
  max-width: 450px;
  max-height: 120px;
}
</style>
