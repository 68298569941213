import axios from "axios";
import store from "../index";

const user = localStorage.getItem("access");

const initState = user
  ? {
      status: {
        loggedIn: true,
        verified: false,
        refreshQueue: []
      },
      user
    }
  : {
      status: {
        loggedIn: false,
        verified: false,
        refreshQueue: []
      },
      user: null
    };

export const auth = {
  namespaced: true,
  state: initState,
  mutations: {
    login(state, tokenPair) {
      state.status.loggedIn = false;
      localStorage.setItem("access", tokenPair.access);
      localStorage.setItem("refresh", tokenPair.refresh);
      state.user = tokenPair.access;
      state.status.loggedIn = true;
    },
    logout(state) {
      state.user = {};
      state.status.loggedIn = false;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("verifiedAt");
    },
    refreshToken(state, accessToken) {
      localStorage.setItem("access", accessToken);
      state.user = accessToken;
    },
    verifyToken(state) {
      state.status.lastVerified = Date.now();
    },
    refreshTokenTime(state) {
      state.status.lastRefreshed = Date.now();
    },
    pushRefreshQueue(state, promise) {
      state.status.refreshQueue.push(promise);
    },
    clearRefreshQueue(state) {
      state.status.refreshQueue = [];
    }
  },
  actions: {
    login({ commit }, user) {
      store.state.loading = true;
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: process.env.VUE_APP_API_URL + "token/",
          data: user
        }).then(
          res => {
            commit("login", res.data);
            resolve(res);
            store.state.loading = false;
          },
          err => {
            console.error(err);
            reject(err);
            store.state.loading = false;
          }
        );
      });
    },
    relogin({ commit }) {
      return new Promise(resolve => {
        commit("login", {
          access: localStorage.getItem("access"),
          refresh: localStorage.getItem("refresh")
        });
        resolve();
      });
    },
    refreshToken({ commit }) {
      const promise = new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: process.env.VUE_APP_API_URL + "refresh/",
          data: {
            access: localStorage.getItem("access"),
            refresh: localStorage.getItem("refresh")
          }
        }).then(
          res => {
            commit("refreshToken", res.data.access);
            commit("clearRefreshQueue");
            resolve(res);
          },
          err => {
            console.error(err);
            reject(err);
          }
        );
      });

      commit("pushRefreshQueue", promise);

      return promise;
    },
    clearRefreshQueue({ commit }) {
      commit("clearRefreshQueue");
    },
    verifyToken({ commit, getters }, force) {
      if (
        Date.now() - store.state.auth.status.lastVerified < 120000 !== true ||
        (force &&
          getters.verifyPromises < 1 &&
          Date.now() - store.state.auth.status.lastVerified < 5000)
      ) {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: process.env.VUE_APP_API_URL + "token/verify/",
            data: {
              token: localStorage.getItem("access")
            }
          })
            .then(
              res => {
                if (res.status === 200) {
                  resolve(res);
                  commit("verifyToken");
                } else {
                  reject(res);
                  commit("verifyToken");
                }
              },
              err => {
                reject(err);
                commit("verifyToken");
              }
            )
            .catch(err => {
              console.error(err);
              commit("verifyToken");
              reject(err);
            });
        });
      }
    },
    createUser({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: process.env.VUE_APP_API_URL + "authentication/users/new/",
          data: user
        }).then(
          res => {
            if (res.status === 201) {
              dispatch("login", user.user).then(response => {
                if (response.status === 200) {
                  resolve(res);
                }
              });
            }
          },
          err => {
            console.error(err);
            reject(err);
          }
        );
      });
    },
    logout({ commit, dispatch }) {
      commit("logout");
      dispatch("userData/clearUserData", null, { root: true });
      dispatch("notifications/clearNotificationState", null, { root: true });
      dispatch("companyData/clearCompanyState", null, { root: true });
      if (store.getters.anyModalOpen) {
        store.dispatch("offerViewer/closeOfferModal");
        store.dispatch("offerViewer/closeNewOfferModal");
        store.dispatch("fileViewer/closeFileModal");
        store.dispatch("invoiceViewer/closeInvoiceModal");
        store.dispatch("invoiceViewer/closeAddInvoiceModal");
        store.dispatch("taskViewer/closeTaskModal");
        store.dispatch("taskViewer/closeAddTaskModal");
      }
    }
  }
};
