var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-offwhite",attrs:{"id":"app"}},[_c('div',{class:_vm.$route.meta.sidebar ? '' : 'sticky top-0 z-50'},[_c('navbar')],1),_c('div',{staticClass:"wrapper flex flex-row mobile:flex-col w-full",class:_vm.$store.state.taskViewer.displayTaskModal ? 'overflow-y-hidden' : ''},[(
        _vm.$route.meta.sidebar &&
          ((_vm.$store.state.auth.status.loggedIn &&
            _vm.$store.state.companyData.retrieved) ||
            !_vm.$store.state.auth.status.loggedIn)
      )?_c('sidebar-overlord',{staticClass:"w-1/6 mobile:w-full medium:w-1/4",on:{"activeCompanyChanged":_vm.handleActiveCompanyChange}}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":50}},[(
          _vm.dataRetrieved &&
            ((_vm.$store.state.auth.status.loggedIn &&
              _vm.$store.state.companyData.retrieved) ||
              !_vm.$store.state.auth.status.loggedIn)
        )?_c('router-view',{staticClass:"bg-white",class:[
          [
            'Home',
            'Documents',
            'Landing',
            'AboutUs',
            'Product',
            'Pricing',
            'Demo'
          ].includes(_vm.$route.name)
            ? 'p-0'
            : 'px-6 mobile:px-3 py-6',
          _vm.$route.meta.sidebar
            ? 'w-5/6 medium:w-3/4 mobile:w-full mobile:h-full'
            : 'w-full',
          _vm.$store.getters.anyModalOpen ? 'overflow-y-hidden' : ''
        ]}):_vm._e()],1)],1),_c('page-footer',{class:[
      !_vm.$store.state.userData.cookieState.consentPopup ? '' : 'pb-40',
      _vm.$store.state.timetracking.hasActiveTimer ? 'pb-20' : ''
    ]}),_c('transition',{attrs:{"name":"fade","duration":500}},[(_vm.$store.state.userData.cookieState.consentPopup)?_c('consent-popup',{on:{"acceptCookies":_vm.handleCookieAccept}}):_vm._e()],1),(_vm.$store.state.auth.status.loggedIn && _vm.activationPopup)?_c('activation-modal',{on:{"closeModal":_vm.setActivationPopupCookie}}):_vm._e(),(_vm.$store.state.userData.subWarning)?_c('sub-warning'):_vm._e(),_c('transition',{attrs:{"name":"fade","duration":500}},[(
        _vm.$store.state.companyData.trialPopupActive &&
          !_vm.$store.state.userData.cookieState.consentPopup
      )?_c('trial-popup'):_vm._e()],1),_c('transition',{attrs:{"name":"fade","duration":100}},[(_vm.$store.state.loading)?_c('loading-modal'):_vm._e()],1),(!_vm.$store.state.companyData.can_access)?_c('worker-cannot-access'):_vm._e(),_c('modal-controller'),(_vm.$store.state.timetracking.hasActiveTimer && _vm.screenW < 993)?_c('timer-bar'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }