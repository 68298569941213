<template>
  <div class="modal">
    <div class="modal-body modal-vwide" v-on-clickaway:mousedown="closeModal">
      <add-offer
        @closeForm="closeModal"
        @offerSaved="handleOfferSave"
      ></add-offer>
    </div>
  </div>
</template>

<script>
import AddOffer from "@/components/project/AddOffer";

import { directive as onClickaway } from "vue-clickaway2";
export default {
  components: {
    AddOffer
  },
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    closeModal() {
      this.$store.dispatch("offerViewer/closeNewOfferModal");
    },
    handleOfferSave(offerId) {
      this.$emit("offerSaved", offerId);
      this.$emit("closeModal");
    }
  }
};
</script>
