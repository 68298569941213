<template>
  <div class="flex rounded-md mb-3 bg-white p-1">
    <div class="p-1">
      <div
        @click="changePage(page - 1)"
        class="p-3 rounded-md leading-none font-semibold cursor-pointer items-center justify-center flex bg-offwhite"
      >
        <span class="typcn typcn-chevron-left"></span>
      </div>
    </div>
    <div class="p-1" v-for="i in count" :key="i">
      <div
        class="p-3 rounded-md leading-none font-semibold cursor-pointer"
        :class="page === i ? 'bg-green text-white' : 'bg-offwhite'"
        @click="changePage(i)"
      >
        {{ i }}
      </div>
    </div>
    <div class="p-1">
      <div
        @click="changePage(page + 1)"
        class="p-3 rounded-md leading-none font-semibold cursor-pointer items-center justify-center flex bg-offwhite"
      >
        <span class="typcn typcn-chevron-right"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: { type: Number, default: null },
    count: { type: Number, default: null }
  },
  methods: {
    changePage(index) {
      if (index > 0 && index <= this.count) {
        this.$emit("pageChange", index);
      }
    }
  }
};
</script>
