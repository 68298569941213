<template>
  <div class="notification-card" @click="goToNotificationTarget()">
    {{ notification.description }}
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    goToNotificationTarget() {
      this.$store.dispatch(
        "notifications/readNotification",
        this.notification.id
      );
      this.$emit("close");
      if (this.notification.object_type === "Task") {
        if (!this.$route.path.includes("/calendar")) {
          this.$router.push(this.notification.notification_target);
        } else {
          this.$store.dispatch("taskViewer/openTaskModal", {
            task: this.notification.object_id
          });
        }
      } else if (this.notification.object_type === "ProjectTask") {
        if (!this.$route.path.includes("/projects")) {
          this.$router.push(this.notification.notification_target);
        } else {
          this.$store.dispatch("taskViewer/openTaskModal", {
            task: this.notification.object_id
          });
        }
      } else {
        this.$router.push(this.notification.notification_target);
      }
    }
  }
};
</script>
