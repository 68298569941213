const RegisterWithToken = () => import("@/views/core/RegisterWithToken");
const RegisterWorkerWithToken = () =>
  import("@/views/core/RegisterWorkerWithToken");
const Project = () => import("@/views/company/Project");
const Home = () => import("@/views/public/Home");
const Login = () => import("@/views/core/Login");
const Register = () => import("@/views/core/Register");
const VerifyEmail = () => import("@/views/core/VerifyEmail");
const PasswordReset = () => import("@/views/core/PasswordReset");
const PasswordResetConfirm = () => import("@/views/core/PasswordResetConfirm");

const coreRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      public: true,
      title: "Gewodo"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      public: true,
      title: "Login | Gewodo"
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      public: true,
      title: "Registreeri | Gewodo"
    }
  },
  {
    path: "/register/join/",
    name: "RegisterWithToken",
    component: RegisterWithToken,
    meta: {
      public: true,
      title: "Liitu | Gewodo"
    }
  },
  {
    path: "/register/worker/",
    name: "RegisterWorkerWithToken",
    component: RegisterWorkerWithToken,
    meta: {
      public: true,
      title: "Liitu | Gewodo"
    }
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    component: Project,
    meta: {
      public: false,
      title: "Projekt | Gewodo",
      sidebar: true
    }
  },
  {
    path: "/verify-email/:tokenPrefix/:token",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      public: true,
      title: "Kinnita email | Gewodo"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: PasswordReset,
    meta: {
      public: true,
      title: "Taasta parool | Gewodo"
    }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordConfirm",
    component: PasswordResetConfirm,
    meta: {
      public: true,
      title: "Kinnita parooli taastamine | Gewodo"
    }
  }
];

export default coreRoutes;
