export default {
  methods: {
    parseUnit(unit) {
      let unitString = "";

      switch (unit) {
        case 1:
          unitString = "tk";
          break;
        case 2:
          unitString = "h";
          break;
        case 3:
          unitString = "km";
          break;
        case 4:
          unitString = "l";
          break;
        case 5:
          unitString = "m²";
          break;
        case 6:
          unitString = "m³";
          break;
        case 7:
          unitString = "päev";
          break;
        case 8:
          unitString = "kg";
          break;
        case 9:
          unitString = "kuu";
          break;
        case 11:
          unitString = "m";
          break;
        case 12:
          unitString = "t";
          break;
        default:
          unitString = "";
      }
      return unitString;
    }
  }
};
