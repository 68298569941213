export const fileViewer = {
  state: {
    fileToDisplay: null,
    displayFileModal: false,
    displayFileType: "companyFile",
    company: null,
    draft: null
  },
  namespaced: true,
  mutations: {
    openFileModal(state, data) {
      state.fileToDisplay = data.file;
      state.displayFileModal = true;
      state.displayFileType = data.type;
      state.company = data.company;
      if (data.draft) {
        state.draft = data.draft;
      }
    },
    closeFileModal(state) {
      state.fileToDisplay = null;
      state.displayFileModal = false;
      state.displayFileType = "companyFile";
    }
  },
  actions: {
    openFileModal({ commit }, data) {
      commit("openFileModal", data);
    },
    closeFileModal({ commit }) {
      commit("closeFileModal");
    }
  }
};
