import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./modules/auth.module";
import { userData } from "./modules/userdata.module";
import { notifications } from "./modules/notifications.module";
import { companyData } from "./modules/companyData.module";
import { taskViewer } from "./modules/taskviewer.module";
import { offerViewer } from "./modules/offerviewer.module";
import { invoiceViewer } from "./modules/invoiceviewer.module";
import { fileViewer } from "./modules/fileviewer.module";
import { timetracking } from "./modules/timetracking.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false
  },
  modules: {
    auth,
    userData,
    notifications,
    companyData,
    taskViewer,
    offerViewer,
    invoiceViewer,
    fileViewer,
    timetracking
  },
  getters: {
    anyModalOpen: state => {
      return (
        state.fileViewer.displayFileModal ||
        state.invoiceViewer.invoiceModal.show ||
        state.invoiceViewer.newInvoiceModal.show ||
        state.offerViewer.offerModal.show ||
        state.offerViewer.newOfferModal.show ||
        state.taskViewer.addTaskModal.show ||
        state.taskViewer.taskModal.show
      );
    }
  }
});
