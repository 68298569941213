<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-row mb-2 items-center mobile:flex-col">
      <h3 class="mobile:mb-2">Töötajad ({{ workersSelected.length }})</h3>
    </div>
    <div
      class="w-full flex items-center bg-offwhite rounded-xl duration-100 mb-2"
      :class="focusSearch ? 'shadow-outline' : 'shadow'"
    >
      <div class="w-1/12 px-1 mobile:mr-2">
        <span class="">
          <img src="/bc21/search.svg" class="h-4 w-4 ml-2" />
        </span>
      </div>
      <input
        type="text"
        v-model="filterText"
        @input="debounceSearch"
        placeholder="Otsi töötajat"
        class="shadow-none focus:shadow-none hover:shadow-none w-10/12"
        @focus="focusSearch = true"
        @blur="focusSearch = false"
      />
    </div>
    <transition name="fade" mode="out-in" :duration="150">
      <div
        v-if="workersLoaded && workers.results.length > 0"
        key="worker-cards"
        class="flex flex-col w-full overflow-y-scroll min-h-min max-h-64 h-auto pb-1 overflow-x-hidden"
      >
        <worker-card
          v-for="item in workers.results"
          :key="item.id"
          :data="item"
          :is-busy="conflictingWorkers.includes(item.id)"
          :is-selected="workersSelected.includes(item.id)"
          @toggleSelect="toggleWorker"
        ></worker-card>

        <paginator
          v-if="workers.count > 1"
          :page="page"
          :count="workers.count"
          @pageChange="pageChange"
        ></paginator>
      </div>
      <div
        v-if="workersLoaded && workers.results.length === 0"
        class="w-full flex flex-col items-center justify-center"
        key="no-workers"
      >
        <span>{{
          `${
            filterText
              ? "Otsinguga klappivaid töötajaid pole!"
              : "Töötajaid pole!"
          }`
        }}</span>
      </div>
      <div key="loading-spinner" v-if="!workersLoaded">
        <clip-loader></clip-loader>
      </div>
    </transition>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import WorkerCard from "./WorkerCard";
import debounce from "lodash/debounce";
import Paginator from "@/components/reusable/Paginator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: {
    existingWorkers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    startTime: { type: String, default: "" },
    endTime: { type: String, default: "" },
    taskId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      workers: null,
      workersLoaded: false,
      workersSelected: [],
      addingWorkers: false,
      filterText: "",
      focusSearch: false,
      conflictingWorkers: [],
      page: 1
    };
  },
  components: {
    Paginator,
    WorkerCard,
    ClipLoader
  },
  mixins: [RequestHandler],
  mounted() {
    this.retrieveWorkers();

    if (this.existingWorkers.length > 0) {
      this.existingWorkers.map(x => this.toggleWorker(x.id));
    }
  },
  methods: {
    debounceSearch: debounce(function() {
      this.retrieveWorkers();
    }, 500),
    pageChange(e) {
      this.page = e;
      this.retrieveWorkers();
    },
    retrieveWorkers() {
      this.workersLoaded = false;
      this.workers = {
        results: []
      };
      this.apiRequest(
        `company/${
          this.$store.state.companyData.activeCompany.uuid
        }/workers/?task=true&page=${this.page}${
          this.filterText ? `&s=${this.filterText}` : ""
        }`,
        "get",
        true
      ).then(res => {
        this.workers = res.data;
        this.workersLoaded = true;
        this.checkIfWorkersFree();
        this.$emit("workersRetrieved", this.workers);
      });
    },
    toggleWorker(e) {
      if (this.workersSelected.find(item => item === e) !== undefined) {
        this.workersSelected.splice(
          this.workersSelected.findIndex(item => item === e),
          1
        );
      } else {
        this.workersSelected.push(e);
      }
      this.$emit("workersUpdated", this.workersSelected);
    },
    checkIfWorkersFree() {
      this.conflictingWorkers = [];
      if (
        this.workers &&
        this.workers.results &&
        this.workers.results.length > 0
      ) {
        this.apiRequest(
          "calendar/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/free/",
          "put",
          true,
          {
            start_time: this.startTime,
            end_time: this.endTime,
            workers: this.workers.results.map(x => x.id),
            task: this.taskId
          }
        ).then(res => {
          if (res.status === 200) {
            this.conflictingWorkers = res.data;
          }
        });
      }
    }
  },
  watch: {
    filterText() {
      this.page = 1;
    },
    startTime() {
      this.checkIfWorkersFree();
    },
    endTime() {
      this.checkIfWorkersFree();
    }
  }
};
</script>
