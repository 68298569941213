import jwtdecode from "jwt-decode";
import cookies from "js-cookie";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";

export const companyData = {
  state: {
    retrieved: false,
    companies: [],
    activeCompany: null,
    isClient: false,
    trialPopupActive: false,
    can_access: true
  },
  namespaced: true,
  mutations: {
    setCompanyData(state, data) {
      state.companies = data;
      if (data.length > 0) {
        if (cookies.get("gwd_default_company")) {
          state.activeCompany = state.companies.find(
            item => item.uuid === cookies.get("gwd_default_company")
          );
          if (state.activeCompany === undefined) {
            state.activeCompany = state.companies[0];
            cookies.set("gwd_default_company", state.activeCompany.uuid);
          }
        } else {
          state.activeCompany = state.companies[0];
          cookies.set("gwd_default_company", state.activeCompany.uuid);
        }
        state.can_access = state.activeCompany.can_access;
        state.isClient = false;
      } else {
        state.isClient = true;
      }
      state.retrieved = true;
    },
    clear(state) {
      state.companies = [];
      state.activeCompany = null;
      state.isClient = false;
      state.retrieved = false;
    },
    setUnactivatedAccount(state) {
      state.companies = [];
      state.activeCompany = null;
      state.isClient = true;
      state.retrieved = true;
    },
    closeTrialPopup(state) {
      state.trialPopupActive = false;
    },
    openTrialPopup(state) {
      state.trialPopupActive = true;
    },
    activateCompany(state, companyId) {
      state.activeCompany = state.companies.find(
        item => item.uuid === companyId
      );
      cookies.set("gwd_default_company", state.activeCompany.uuid);
    }
  },
  actions: {
    clearCompanyState({ commit }) {
      commit("clear");
    },
    activateCompany({ commit, dispatch }, data) {
      commit("activateCompany", data);
      dispatch("timetracking/retrieveTimers", {}, { root: true });
    },
    setUnactivatedAccount({ commit }) {
      commit("setUnactivatedAccount");
    },
    closeTrialPopup({ commit }) {
      commit("closeTrialPopup");
    },
    openTrialPopup({ commit }) {
      commit("openTrialPopup");
    },
    retrieveCompanyData({ commit }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `profiles/${
            jwtdecode(localStorage.getItem("access")).user_id
          }/companies/`,
          "get",
          true
        )
          .then(
            async res => {
              commit("clear");
              commit("setCompanyData", res.data);
              resolve(res);
            },
            err => {
              reject(err);
            }
          )
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
