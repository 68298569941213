<template>
  <div class="w-full items-center flex flex-col add-task-modal">
    <div class="flex flex-col w-full">
      <div class="flex flex-col w-full mobile:flex-col">
        <div class="flex w-full mobile:flex-col">
          <div class="w-2/3 flex flex-col pr-2 mobile:pr-0 mb-8 mobile:w-full">
            <form-label :heading-level="3" title="Üldinfo"></form-label>
            <div class="form-group mb-4">
              <form-label
                title="Pealkiri"
                :required="true"
                :valid="!$v.title.$invalid"
                :error="$v.title.$error"
              ></form-label>
              <input
                class="shadow focus:shadow-outline"
                :class="
                  $v.$error && $v.title.$invalid ? 'shadow-dangeroutline' : ''
                "
                v-model="title"
              />
            </div>
            <div class="form-group mb-4">
              <form-label :heading-level="4" title="Projekt"></form-label>
              <div class="flex items-center">
                <button
                  class="new-button-green"
                  @click="projectSelectorActive = true"
                  v-if="!selectedProject && !relatedProject"
                >
                  <span class="icon">
                    <img src="/bc21/project.svg" class="filter-to-white" />
                  </span>
                  <span class="label">Vali projekt</span>
                </button>
                <span class="mr-3">{{
                  relatedProject
                    ? relatedProject.title
                    : selectedProject
                    ? selectedProject.title
                    : ""
                }}</span>
                <button
                  v-if="!relatedProject && selectedProject"
                  @click="projectSelectorActive = true"
                  class="new-button-green mr-3"
                >
                  <span class="icon typcn typcn-plus"></span>
                  <span class="label">Vali uus</span>
                </button>
                <button
                  v-if="!relatedProject && selectedProject"
                  @click="selectedProject = null"
                  class="new-button-danger"
                >
                  <span class="icon typcn typcn-times"></span>
                  <span class="label">Tühjenda</span>
                </button>
              </div>
            </div>
            <div class="form-group h-full mb-4">
              <form-label title="Kirjeldus"></form-label>
              <textarea
                class="shadow focus:shadow-outline h-full"
                rows="5"
                v-model="description"
              ></textarea>
            </div>
            <div class="form-group h-full mb-8">
              <form-label title="Asukoht"></form-label>
              <location-inaddress
                v-if="!location"
                @locationChanged="handleLocationChange"
              >
              </location-inaddress>
              <div
                v-if="location"
                class="bg-offwhite w-full rounded-xl py-1 px-2 flex items-center mb-4"
              >
                <span>{{ location.ipikkaadress }}</span>
                <span
                  class="bg-danger p-2 rounded-full ml-auto"
                  @click="location = null"
                  ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
                /></span>
              </div>
            </div>
            <div
              class="flex flex-col w-full pr-3 mobile:w-full mobile:mb-0 mobile:pr-0 mb-4 mobile:flex-col"
            >
              <form-label title="Aeg" :heading-level="3"></form-label>
              <div class="flex items-start flex-wrap">
                <div class="flex task-input-container">
                  <form-label
                    title="Kuupäev"
                    :required="true"
                    :valid="!$v.startDate.$invalid"
                    :error="$v.startDate.$error"
                  ></form-label>
                  <div class="flex">
                    <date-picker
                      class="add-task-date-selector w-full mr-3 mobile:mr-0"
                      :class="[
                        topCalendarShowing ? 'is-active' : '',
                        $v.$error && $v.startDate.$invalid ? 'error' : ''
                      ]"
                      :language="ee"
                      :format="formatDate"
                      :monday-first="true"
                      @opened="handleTopCalendarShow"
                      @closed="handleTopCalendarClose"
                      v-model="startDate"
                      :disabled-dates="disabledDates"
                    ></date-picker>
                  </div>
                </div>
                <div
                  class="flex task-input-container flex-grow"
                  v-if="timesInited"
                >
                  <div class="flex flex-col w-full">
                    <form-label
                      title="Kestab kogu päeva"
                      class="h-8"
                    ></form-label>
                    <div
                      class="flex pr-2 items-center mt-auto flex-grow h-full"
                    >
                      <base-checkbox
                        @valueChanged="isAllDay = $event"
                        :base-val="isAllDay"
                      ></base-checkbox>
                    </div>
                  </div>
                </div>
                <transition name="fade">
                  <div
                    class="flex task-input-container"
                    v-if="!isAllDay && timesInited"
                  >
                    <form-label
                      title="Algus"
                      :required="true"
                      :valid="!$v.startTime.$invalid"
                      :error="$v.startTime.$error"
                    ></form-label>
                    <div class="flex">
                      <time-selector
                        class="justify-end"
                        :value="startTime"
                        @input="handleStartTimeChange"
                        :is-white="false"
                      ></time-selector>
                    </div>
                  </div>
                </transition>

                <transition name="fade">
                  <div
                    class="flex task-input-container"
                    v-if="!isAllDay && timesInited"
                  >
                    <form-label
                      title="Lõpp"
                      :required="true"
                      :valid="!$v.endTime.$invalid"
                      :error="$v.endTime.$error"
                      v-if="!isAllDay"
                    ></form-label>

                    <div class="flex" v-if="!isAllDay">
                      <time-selector
                        class="justify-end"
                        :value="endTime"
                        @input="handleEndTimeChange"
                        :is-white="false"
                      ></time-selector>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="flex w-full mobile:w-full mobile:pl-0 flex-col mb-4">
              <div
                class="flex mobile:flex-col"
                :class="isRepeating ? 'mb-4' : ''"
              >
                <div
                  class="flex flex-col mobile:items-start w-1/4 mobile:w-full mobile:mb-2"
                >
                  <form-label title="Kordub"></form-label>
                  <div
                    class="flex flex-row mobile:flex-col items-center mobile:items-start"
                  >
                    <base-checkbox
                      :base-val="isRepeating"
                      @valueChanged="isRepeating = $event"
                      class="mr-3 mobile:order-2"
                    ></base-checkbox>
                  </div>
                </div>
                <div class="flex flex-col" v-if="isRepeating">
                  <h4>Sagedus</h4>
                  <div class="flex items-center">
                    <input
                      type="number"
                      v-model="repeatAmount"
                      class="w-3/12 mr-2"
                    />
                    <select class="mr-2" v-model="repeatPeriod">
                      <option
                        v-for="period in periods"
                        :key="period.en"
                        :value="period.en"
                      >
                        {{ period.ee }}
                      </option>
                    </select>
                    <span>tagant</span>
                  </div>
                </div>
              </div>

              <transition name="fade">
                <div
                  class="flex flex-col w-full mobile:w-full"
                  v-if="isRepeating"
                >
                  <div class="flex flex-col w-full">
                    <h4>Lõpp</h4>
                    <div class="flex mobile:flex-col mobile:w-full">
                      <div
                        class="flex flex-col items-center mobile:items-start w-1/2 mobile:w-full py-1 mobile:flex-col"
                      >
                        <div
                          class="items-center flex justify-start w-full mobile:w-full pl-2"
                        >
                          <input
                            type="radio"
                            id="endDate"
                            value="1"
                            v-model="repeatType"
                            class="mr-3 shadow-none focus:shadow-none"
                          />
                          <span :class="repeatType === '1' ? 'font-medium' : ''"
                            >Korduste arv</span
                          >
                        </div>
                        <div
                          :class="repeatType === '2' ? 'disabled' : ''"
                          class="flex flex-row items-center justify-start w-full"
                        >
                          <input
                            :class="repeatType === '2' ? 'disabled' : ''"
                            type="number"
                            step="1"
                            v-model="repeatCount"
                            class="flex-shrink flex mr-3 w-1/3"
                            :disabled="repeatType === '2'"
                          />
                          <span>korda</span>
                        </div>
                      </div>
                      <div
                        class="flex flex-col w-1/2 py-1 mobile:flex-col mobile:w-full"
                      >
                        <div
                          class="flex flex-row w-full mobile:w-full items-center pl-2"
                        >
                          <input
                            type="radio"
                            id="endCount"
                            value="2"
                            v-model="repeatType"
                            class="shadow-none focus:shadow-none mr-3"
                          />
                          <span :class="repeatType === '2' ? 'font-medium' : ''"
                            >Kuupäev</span
                          >
                        </div>
                        <div
                          :class="
                            repeatType === '1'
                              ? 'disabled-repeat-date-selector'
                              : 'repeat-date-selector'
                          "
                          class="flex flex-row items-center mobile:items-start justify-start repeat-date-selector mobile:w-full"
                        >
                          <date-picker
                            :language="ee"
                            :format="formatDate"
                            :monday-first="true"
                            v-model="repeatEndDate"
                            :disabled="repeatType === '1'"
                            class="repeating-datepicker w-full"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="w-1/3 flex flex-col mobile:w-full mb-8 pl-2 mobile:pl-0">
            <div
              class="flex mb-4"
              v-if="
                $store.state.companyData.activeCompany.subscription_permissions
                  .client_portal
              "
            >
              <worker-selector
                @workersUpdated="handleWorkerUpdate"
                @workersRetrieved="handleWorkersRetrieved"
                :start-time="startTimeFormatted"
                :end-time="endTimeFormatted"
                :existing-workers="
                  Array.isArray(this.presetData) ? [this.presetData[1]] : []
                "
              ></worker-selector>
            </div>

            <div class="flex flex-col mobile:items-start">
              <h3 class="mb-2">Nähtavus</h3>
              <div
                class="flex items-center mb-4 mobile:justify-center"
                v-if="
                  $store.state.companyData.activeCompany
                    .subscription_permissions.client_portal
                "
              >
                <base-checkbox
                  class="mr-2"
                  :base-val="is_public"
                  @valueChanged="is_public = $event"
                ></base-checkbox>
                <span>Nähtav kõikidele töötajatele</span>
              </div>
              <div class="flex items-center mb-4 mobile:justify-center">
                <base-checkbox
                  class="mr-2"
                  :base-val="saveAsActive"
                  @valueChanged="saveAsActive = $event"
                ></base-checkbox>
                <span>Salvesta aktiivsena</span>
              </div>
              <div
                class="flex items-center mb-4 mobile:justify-center"
                v-if="relatedProject"
              >
                <base-checkbox
                  class="mr-2"
                  :base-val="visible_to_client"
                  @valueChanged="visible_to_client = $event"
                ></base-checkbox>
                <span>Nähtav kliendile</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full mx-auto error-box mb-4"
        ref="errorBox"
        v-if="$v.$error && $v.$invalid"
      >
        <div class="flex w-1/12 justify-center items-center">
          <span class="typcn typcn-times text-4xl leading-none"></span>
        </div>
        <div class="flex w-11/12 items-center">
          <span v-if="$v.title.$invalid">Palun sisestage pealkiri</span>
          <span v-if="$v.description.$invalid"
            >Kirjeldus võib olla kuni 1024 tähemärki</span
          >
          <span v-if="$v.startDate.$invalid">Palun valige kuupäev</span>
          <span v-if="$v.startTime.$invalid">Palun valige a</span>
          <span v-if="$v.endTime.$invalid">Asd</span>
        </div>
      </div>
      <div
        class="flex pb-3 mobile:pb-12 mobile:justify-center"
        v-if="displaySaveButton"
      >
        <div class="flex mr-2 mobile:mr-0">
          <button @click="saveTask()" class="new-button-green">
            <span class="icon typcn typcn-tick"></span>
            <span class="label">Salvesta</span>
          </button>
        </div>
      </div>
      <project-selector
        v-if="projectSelectorActive"
        :convert="false"
        @closeModal="projectSelectorActive = false"
        @projectAdded="handleSelectedProject"
      >
      </project-selector>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import DatePicker from "vuejs-datepicker";
import TimeSelector from "@/components/reusable/TimeSelector";
import WorkerSelector from "@/components/tasks/WorkerSelector";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { required, maxLength } from "vuelidate/lib/validators";
import "moment/locale/et";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import ProjectSelector from "@/components/tasks/ProjectSelector";

const periods = [
  {
    ee: "päeva",
    en: "DAILY"
  },
  {
    ee: "nädala",
    en: "WEEKLY"
  },
  {
    ee: "kuu",
    en: "MONTHLY"
  },
  {
    ee: "aasta",
    en: "YEARLY"
  }
];

export default {
  props: {
    hasPresetData: Boolean,
    presetData: {
      type: [Object, Array, Date],
      default: () => {
        return {};
      }
    },
    relatedProject: {
      type: String,
      default: null
    },
    projectLocation: {
      type: Object,
      default: () => {
        return {};
      }
    },
    displaySaveButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isAllDay: false,
      ee: ee,
      topCalendarShowing: false,
      bottomCalendarShowing: false,
      startTime: null,
      endTime: null,
      timesInited: false,
      startDate: null,
      endDate: null,
      description: "",
      title: "",
      workers: [],
      isRepeating: false,
      periods: [],
      repeatAmount: 1,
      repeatEnd: 1,
      repeatType: "1",
      repeatCount: 1,
      repeatEndDate: null,
      repeatPeriod: "DAILY",
      conflictingWorkers: [],
      selectableWorkers: null,
      selectableWorkersRetrieved: false,
      is_public: false,
      visible_to_client: false,
      saveAsActive: true,
      location: null,
      projectSelectorActive: false,
      selectedProject: null
    };
  },
  mixins: [RequestHandler],
  components: {
    LocationInaddress,
    DatePicker,
    TimeSelector,
    WorkerSelector,
    ProjectSelector
  },
  mounted() {
    this.initTimes();
    if (this.hasPresetData) {
      if (Array.isArray(this.presetData)) {
        this.startDate = this.presetData[0].toDate();
      } else {
        this.startDate = this.presetData.toDate();
      }
    } else {
      this.startDate = moment().toDate();
    }
    if (Object.keys(this.projectLocation).length > 0) {
      this.location = {
        ipikkaadress: this.projectLocation.long_address,
        ads_oid: this.projectLocation.ads_oid,
        viitepunkt_b: this.projectLocation.latitude,
        viitepunkt_l: this.projectLocation.longitude
      };
    }
  },
  watch: {
    startTime() {
      let startTimeObject = moment(this.startDate);
      startTimeObject.hour(moment(this.startTime, "HH:mm").hour());
      startTimeObject.minute(moment(this.startTime, "HH:mm").minute());
      if (
        moment(this.startTime, "HH:mm").isSameOrAfter(
          moment(this.endTime, "HH:mm")
        )
      ) {
        this.endTime = moment(startTimeObject)
          .add(1, "hours")
          .format("HH:mm");
      }
    },
    endTime() {
      let endTimeObject = moment(this.startDate);
      endTimeObject.hour(moment(this.endTime, "HH:mm").hour());
      endTimeObject.minute(moment(this.endTime, "HH:mm").minute());
      if (
        moment(this.endTime, "HH:mm").isSameOrBefore(
          moment(this.startTime, "HH:mm")
        )
      ) {
        this.startTime = moment(endTimeObject)
          .subtract(1, "hours")
          .format("HH:mm");
      }
    }
  },
  methods: {
    handleLocationChange(e) {
      this.location = e;
    },
    saveTask() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let data;
        if (this.isRepeating) {
          data = {
            task_type: 3,
            title: this.title,
            description: this.description,
            all_day: this.isAllDay,
            start_time: this.startTimeFormatted,
            end_time: this.endTimeFormatted,
            workers: this.workers,
            interval: this.repeatAmount,
            repeat_frequency: this.repeatPeriod,
            publish: this.saveAsActive,
            is_public: this.is_public,
            location: this.location
          };
          if (this.relatedProject) {
            data.outside_post = this.relatedProject.uuid;
            data.visible_to_client = this.visible_to_client;
          } else if (this.selectedProject) {
            data.outside_post = this.selectedProject.uuid;
            data.visible_to_client = false;
          }

          if (this.repeatType === "2") {
            data.until_date = this.repeatEndDateFormatted;
          } else {
            data.count = this.repeatCount;
          }
        } else {
          data = {
            task_type: this.isAllDay ? 2 : 1,
            title: this.title,
            description: this.description,
            start_time: this.startTimeFormatted,
            end_time: this.endTimeFormatted,
            workers: this.workers,
            publish: this.saveAsActive,
            is_public: this.is_public,
            location: this.location
          };
          if (this.relatedProject) {
            data.outside_post = this.relatedProject.uuid;
            data.visible_to_client = this.visible_to_client;
          } else if (this.selectedProject) {
            data.outside_post = this.selectedProject.uuid;
            data.visible_to_client = false;
          }
        }
        this.apiRequest(
          "tasks/" + this.$store.state.companyData.activeCompany.uuid + "/new/",
          "post",
          true,
          data
        ).then(
          res => {
            if (res) {
              this.$store.dispatch("taskViewer/openTaskModal", {
                task: res.data.task_id
              });
              this.$emit("taskSaved");
            }
          },
          err => {
            if (err) {
              this.$refs.errorBox.scrollIntoView();
            }
          }
        );
      } else {
        let self = this;
        setTimeout(function() {
          self.$refs.errorBox.scrollIntoView();
        }, 100);
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    initTimes() {
      this.startTime = moment()
        .add(1, "hours")
        .startOf("hour")
        .format("HH:mm");
      this.endTime = moment()
        .add(2, "hours")
        .startOf("hour")
        .format("HH:mm");
      this.repeatEndDate = moment().toDate();
      this.periods = periods;
      this.timesInited = true;
    },
    handleStartTimeChange(e) {
      this.startTime = e;
    },
    handleEndTimeChange(e) {
      this.endTime = e;
    },
    handleTopCalendarShow() {
      this.topCalendarShowing = true;
    },
    handleTopCalendarClose() {
      this.topCalendarShowing = false;
    },
    handleBottomCalendarShow() {
      this.bottomCalendarShowing = true;
    },
    handleBottomCalendarClose() {
      this.bottomCalendarShowing = false;
    },
    handleWorkerUpdate(e) {
      this.workers = e;
    },
    handleWorkersRetrieved(e) {
      this.selectableWorkers = e;
    },
    handleSaveAsActive(e) {
      this.saveAsActive = e;
    },
    handleSelectedProject(e) {
      this.selectedProject = e;
    }
  },
  computed: {
    startTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") + " " + this.startTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") + " " + this.endTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    repeatEndDateFormatted() {
      const time =
        moment(this.repeatEndDate).format("YYYY-MM-DD") + " " + this.endTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    }
  },
  validations() {
    if (!this.isAllDay) {
      return {
        title: {
          required
        },
        startDate: {
          required
        },
        startTime: {
          required
        },
        endTime: {
          required
        },
        description: {
          maxLength: maxLength(1024)
        }
      };
    } else {
      return {
        title: {
          required
        },
        description: {
          maxLength: maxLength(1024)
        },
        startDate: {
          required
        }
      };
    }
  }
};
</script>

<style lang="scss">
.add-task-modal {
  max-height: 85vh;
}

.add-task-date-selector {
  div {
    input {
      @apply bg-white w-full shadow-sm;
    }
  }
  .vdp-datepicker__calendar {
    @apply rounded-md border-none w-full mt-1 p-2 mr-2;
  }
  &.is-active {
    div {
      input {
        @apply bg-white w-full;
      }
    }
    .vdp-datepicker__calendar {
      @apply rounded-b-md border-none w-full shadow-sm;
    }
  }
  &.error {
    div {
      @apply bg-white;
      input {
        @apply shadow-dangeroutline;
      }
    }
  }
}

.repeat-date-selector {
  .vdp-datepicker {
    div {
      input {
        @apply w-full;
      }
    }
  }
}
.disabled-repeat-date-selector {
  .vdp-datepicker {
    div {
      input {
        @apply bg-offwhite text-bordergrey w-full;
      }
    }
  }
}
.task-input-container {
  @apply w-1/4 flex-col;
}
@media screen and (max-width: 1750px) and (min-width: 992px) {
  .task-input-container {
    @apply w-1/2 flex-col mb-2;
  }
}
@media screen and (max-width: 992px) {
  .task-input-container {
    @apply w-full flex-col mb-4;
  }
}
</style>
