<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center justify-between mb-4">
      <h4 class="mr-4 mobile:mr-0">Pakkumise PDF</h4>
      <button class="new-button-pending" @click="generatePdfPreview(false)">
        <span class="typcn typcn-credit-card icon"></span>
        <span class="label">Eelvaade</span>
      </button>
    </div>
    <div class="flex flex-col mobile:flex-col mb-4" v-if="dataLoaded">
      <div
        class="flex flex-col w-full mr-2 mt-2 mobile:mr-0 mobile:w-full mb-4"
      >
        <div
          class="flex items-center justify-between mobile:flex-col mobile:items-start"
        >
          <span class="font-medium">Kliendi andmed</span>
          <div class="flex flex-row items-center">
            <span class="mr-2">Klient on ettevõte</span>
            <input
              class="ml-2 shadow-none focus:shadow-none"
              type="checkbox"
              v-model="pdfData.is_company"
              @input="debounceSaveChanges"
            />
          </div>
        </div>
        <h5 class="">
          Nimi<span class="font-medium required-tooltip">*</span>
        </h5>
        <input
          class="mb-2"
          :class="
            $v.$error && $v.pdfData.client_name.$invalid
              ? 'shadow-dangeroutline'
              : ''
          "
          type="text"
          id="clientName"
          v-model="pdfData.client_name"
          placeholder="Kliendi nimi"
          @input="debounceSaveChanges"
        />
        <h5 v-if="pdfData.is_company">
          Registrikood<span class="font-medium required-tooltip">*</span>
        </h5>
        <input
          v-if="pdfData.is_company"
          type="text"
          class="mb-2"
          id="regCode"
          v-model="pdfData.reg_code"
          :class="
            $v.$error && $v.pdfData.reg_code && $v.pdfData.reg_code.$invalid
              ? 'shadow-dangeroutline'
              : ''
          "
          @input="debounceSaveChanges"
          placeholder="Registrikood"
        />
        <h5 v-if="pdfData.is_company">KMRK kood</h5>
        <input
          v-if="pdfData.is_company"
          class="mb-2"
          type="text"
          id="taxCode"
          v-model="pdfData.tax_code"
          @input="debounceSaveChanges"
          placeholder="KMRK kood"
        />
        <h5>Aadress<span class="font-medium required-tooltip">*</span></h5>
        <span
          v-if="!editingAddress"
          class="bg-offwhite py-2 px-3 rounded-xl shadow mb-2 cursor-pointer"
          :class="
            pdfData.address && pdfData.address.length === 0
              ? 'font-medium text-danger shadow-dangeroutline'
              : ''
          "
          @click="editingAddress = true"
          >{{
            pdfData.address && pdfData.address.length > 0
              ? pdfData.address
              : "Aadress tühi"
          }}</span
        >
        <location-inaddress
          v-if="editingAddress"
          @locationChanged="handleLocationChange"
          @clearLocation="clearLocation"
          :location="pdfData.address"
          :show-error="$v.$error && $v.pdfData.address.$invalid"
          :show-delete-text="false"
          v-on-clickaway:mousedown="
            editingAddress
              ? () => {
                  editingAddress = false;
                }
              : ''
          "
        >
        </location-inaddress>
      </div>
      <div class="flex flex-col w-full mobile:ml-0 mobile:w-full">
        <h4 class="mb-2">Pakkumise andmed</h4>
        <h5>Pakkumise kirjeldus</h5>
        <textarea
          class="mb-4"
          v-model="pdfData.details"
          id="details"
          rows="5"
          @input="debounceSaveChanges"
          placeholder="Pakkumise kirjeldus"
        />
        <div
          class="flex flex-col items-start mobile:items-start mb-2 mobile:flex-col"
        >
          <h5>Pakkumise kehtivus</h5>
          <div
            class="flex flex-row items-center rounded-xl w-full"
            id="offer-datepicker"
          >
            <date-picker
              v-model="pdfData.expiry_date"
              :language="ee"
              :format="formatDate"
              :monday-first="true"
              class="w-8/12"
              @input="debounceSaveChanges"
              :class="saving ? 'disabled' : ''"
              :disabled-dates="disabledDates"
            />
            <div v-if="!pdfData.expiry_date" class="w-4/12">
              <select
                class="add-offer-input"
                v-model="tempExpiryDate"
                @change="changeExpiryDate"
              >
                <option value="null" disabled class="text-bordergrey"
                  >Vali päevade arv</option
                >
                <option :value="7">7 päeva</option>
                <option :value="14">14 päeva</option>
                <option :value="21">21 päeva</option>
                <option :value="30">30 päeva</option>
              </select>
            </div>
            <div class="ml-2" v-if="pdfData.expiry_date">
              <button class="new-button-danger" @click="removeExpiryDate">
                <span class="typcn typcn-times icon"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pdfViewActive && pdfSrc">
      <pdf-modal
        :pdf-src="pdfSrc"
        @closeModal="closePdfView"
        @saveFile="generatePdfPreview(true)"
      ></pdf-modal>
    </div>
    <div
      class="flex flex-row w-full items-center justify-start mb-2 mt-auto mobile:justify-between"
    >
      <saving-loader :saving="saving" :saved="saved"></saving-loader>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PdfModal from "@/components/reusable/PdfModal";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import DatePicker from "vuejs-datepicker";
import RequestHandler from "@/assets/mixins/RequestHandler";
import SavingLoader from "@/components/reusable/SavingLoader";
import debounce from "lodash/debounce";
import { required } from "vuelidate/lib/validators";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  name: "PdfCompleter",
  components: { LocationInaddress, PdfModal, DatePicker, SavingLoader },
  props: {
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      pdfSrc: null,
      pdfViewActive: false,
      ee: ee,
      lastActiveField: null,
      pdfData: {
        details: "",
        client_name: "",
        is_company: false,
        reg_code: "",
        tax_code: "",
        address: "",
        expiry_date: null
      },
      saving: false,
      saved: false,
      savedTimeout: null,
      dataLoaded: false,
      tempExpiryDate: null,
      editingAddress: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    loadPdfInfo() {
      this.apiRequest(
        "projects/" + this.draft.uuid + "/" + this.offer.id + "/pdf/",
        "get",
        true
      ).then(res => {
        if (res) {
          this.pdfData.details = res.data.details;
          if (res.data.client_name) {
            this.pdfData.client_name = res.data.client_name;
          } else {
            this.pdfData.client_name = this.draft.client.name;
          }
          this.pdfData.is_company = res.data.is_company;
          this.pdfData.reg_code = res.data.reg_code;
          this.pdfData.tax_code = res.data.tax_code;
          this.pdfData.expiry_date = res.data.expiry_date;
          this.pdfData.address = res.data.address;

          if (this.lastActiveField) {
            let self = this;
            setTimeout(function() {
              document.getElementById(self.lastActiveField).focus();
            }, 100);
          }
          this.dataLoaded = true;
        } else {
          this.dataLoaded = true;
        }
      });
    },
    clearLocation() {
      this.pdfData.address = "";
      this.editPdfInfo();
    },
    handleLocationChange(e) {
      this.pdfData.address = e.ipikkaadress;
      this.editingAddress = false;
    },
    debounceSaveChanges: debounce(function() {
      this.editPdfInfo();
    }, 1000),
    removeExpiryDate() {
      this.pdfData.expiry_date = null;
      this.editPdfInfo();
    },
    editPdfInfo() {
      if (this.dataLoaded) {
        this.$parent.saved = false;
        this.$parent.saving = true;
        let self = this;
        this.lastActiveField = document.activeElement.id;
        if (this.savedTimeout) {
          clearTimeout(this.savedTimeout);
          this.savedTimeout = null;
        }
        if (this.pdfData.expiry_date) {
          this.pdfData.expiry_date = moment(this.pdfData.expiry_date).format(
            "YYYY-MM-DD"
          );
        }
        this.apiRequest(
          "projects/" + this.draft.uuid + "/" + this.offer.id + "/pdf/edit/",
          "patch",
          true,
          this.pdfData
        ).then(res => {
          if (res.status === 200) {
            this.$parent.saving = false;
            this.$parent.saved = true;
            this.savedTimeout = setTimeout(function() {
              self.$parent.saved = false;
            }, 5000);
            this.loadPdfInfo();
          }
        });
      }
    },
    generatePdfPreview(createFile) {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          "notes/draft/" +
          this.draft.uuid +
          "/" +
          this.offer.id +
          "/generate/" +
          (createFile ? "?save_file=True" : ""),
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    },
    changeExpiryDate() {
      this.pdfData.expiry_date = moment()
        .add(this.tempExpiryDate, "days")
        .toDate();
      this.tempExpiryDate = null;
      this.editPdfInfo();
    },
    activeElement() {
      return document.activeElement;
    },
    confirmDataComplete() {
      this.editPdfInfo();
      this.$emit("pdfDataSaved");
    }
  },
  mounted() {
    this.dataLoaded = false;
    this.loadPdfInfo();
  },
  computed: {
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    validations() {
      return this.$v;
    }
  },
  watch: {
    pdfData: {
      deep: true,
      handler() {
        if (this.dataLoaded) {
          this.debounceSaveChanges();
        }
      }
    }
  },
  validations() {
    if (this.pdfData.is_company) {
      return {
        pdfData: {
          client_name: {
            required
          },
          reg_code: {
            required
          },
          address: {
            required
          }
        }
      };
    } else {
      return {
        pdfData: {
          client_name: {
            required
          },
          address: {
            required
          }
        }
      };
    }
  }
};
</script>

<style lang="scss">
#offer-datepicker {
  .vdp-datepicker {
    div {
      @apply mr-4;
      input {
        @apply w-full;
      }
    }
  }
}
</style>
