<template>
  <div class="flex flex-col rounded-xl">
    <div class="flex items-start w-full">
      <div
        class="flex w-full mobile:p-2 bg-offwhite shadow items-center duration-75 rounded-b-xl mobile:flex-col"
        :class="[
          isFocused ? 'shadow-outline mobile:shadow-none' : '',
          showAddresses ? 'rounded-t-xl' : 'rounded-xl',
          showError ? 'shadow-dangeroutline' : ''
        ]"
      >
        <span
          class="mobile:flex mobile:self-start mobile:items-center mobile:mb-2"
        >
          <img
            src="/bc21/search.svg"
            class="h-4 w-4 mobile:h-3 mobile:w-3 ml-2 mobile:ml-1 mobile:mr-3"
          />
          <h4 class="hidden mobile:flex">Otsi asukohta</h4>
        </span>
        <input
          v-model="searchString"
          type="text"
          class="add-project-input shadow-none focus:shadow-none mobile:focus:shadow-outline w-auto flex-grow truncate mobile:w-full mobile:bg-white mobile:mb-2"
          :class="[isWhite ? 'bg-white' : '']"
          :placeholder="text"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <div class="w-auto py-1 flex items-center">
          <button
            class="square-button-danger mr-1"
            v-if="searchString.length > 0"
            @click="clearSelection"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label hidden mobile:flex">
              Tühjenda
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col shadow rounded-b-xl" v-if="showAddresses">
      <div
        class="flex flex-col"
        v-for="(address, index) in foundAddresses"
        :key="address.tunnus"
      >
        <div
          @click="selectLocation(address)"
          :class="index === foundAddresses.length - 1 ? 'rounded-b-xl' : ''"
          class="cursor-pointer px-2 py-3 justify-between flex flex-row items-center hover:bg-offwhite hover:bg-opacity-50"
        >
          <span class="flex w-full">{{ address.ipikkaadress }}</span>
        </div>
        <div
          class="h-1 w-full bg-offwhite rounded-full"
          v-if="index !== foundAddresses.length - 1"
        ></div>
      </div>
      <div class="flex p-2" v-if="foundAddresses.length === 0">
        <span>
          Selle nimega asukohta ei leitud
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import debounce from "lodash/debounce";

export default {
  name: "LocationInaddress",
  mixins: [RequestHandler],
  props: {
    text: {
      type: String,
      default: "Sisestage asukoha aadress"
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: ""
    },
    showError: {
      type: Boolean,
      default: false
    },
    showDeleteText: {
      type: Boolean,
      default: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    emitStandardizedObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchString: "",
      foundAddresses: [],
      showAddresses: false,
      isFocused: false,
      presetLocationExists: false
    };
  },
  methods: {
    clearSelection() {
      this.searchString = "";
      this.foundAddresses = [];
      this.showAddresses = false;
      this.$emit("clearLocation");
    },
    loadAddresses() {
      this.foundAddresses = [];
      this.showAddresses = false;
      if (this.searchString.length > 0) {
        this.apiRequest(
          "https://inaadress.maaamet.ee/inaadress/gazetteer?address=" +
            this.searchString.replace(" ", "+"),
          "get"
        ).then(res => {
          if (Array.isArray(res.data.addresses)) {
            this.foundAddresses = res.data.addresses;
          }
          this.showAddresses = true;
        });
      }
    },
    selectLocation(input) {
      this.showAddresses = false;
      let address;
      if (this.emitStandardizedObject) {
        address = {};
        address.long_address = input.ipikkaadress;
        address.ads_oid = input.ads_oid;
        address.latitude = input.viitepunkt_b;
        address.longitude = input.viitepunkt_l;
        address.full_object = input;
      } else {
        address = input;
      }
      this.$emit("locationChanged", address);
    },
    debounceSearchQuery: debounce(function() {
      this.loadAddresses();
    }, 500)
  },
  watch: {
    searchString() {
      if (!this.presetLocationExists) {
        this.debounceSearchQuery();
      } else {
        this.presetLocationExists = false;
      }
    }
  },
  mounted() {
    if (this.location.length > 0) {
      this.searchString = this.location;
      this.presetLocationExists = true;
    }
  }
};
</script>
