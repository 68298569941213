import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
import Vuelidate from "vuelidate";
import Vue2Touch from "vue2-touch-events";
import VueGtm from "vue-gtm";
import ScreenResizer from "@/assets/mixins/ScreenResizer";
import RequestHandler from "@/assets/mixins/RequestHandler";
import UserRoleParser from "@/assets/mixins/UserRoleParser";
import "@/assets/typicons/typicons.min.css";
import "@/assets/scss/tailwind.scss";
import "leaflet/dist/leaflet.css";
import Checkbox from "@/components/globals/Checkbox";
import FormLabel from "@/components/globals/FormLabel";

Vue.use(Vuelidate);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM,
  enabled: false,
  vueRouter: router
});
Vue.use(Vue2Touch, {
  swipeTolerance: 60
});

Vue.component("base-checkbox", Checkbox);
Vue.component("form-label", FormLabel);
Vue.mixin(ScreenResizer);
Vue.mixin(RequestHandler);
Vue.mixin(UserRoleParser);
if (window.Cypress) {
  window.store = store;
}
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
