<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center justify-between mb-4">
      <h4 class="mr-4">Arve PDF</h4>
      <button class="new-button-pending" @click="generatePdfPreview(false)">
        <span class="typcn typcn-credit-card icon"></span>
        <span class="label">Eelvaade</span>
      </button>
    </div>
    <div class="flex flex-col mb-4" v-if="dataLoaded">
      <div class="flex flex-col w-full mt-2 mobile:mr-0 mobile:w-full">
        <div class="flex justify-between items-center">
          <span class="font-medium">Kliendi andmed</span>
          <div class="flex flex-row items-center">
            <span class="mr-2">Klient on ettevõte</span>
            <input
              class="ml-2 shadow-none focus:shadow-none"
              type="checkbox"
              v-model="pdfData.is_company"
              @input="debounceSaveChanges"
              :class="$parent.saving ? 'disabled' : ''"
            />
          </div>
        </div>
        <span class="text-sm font-medium ml-2"
          >Nimi<span class="font-medium required-tooltip">*</span></span
        >
        <input
          class="mb-2"
          type="text"
          v-model="pdfData.client_name"
          placeholder="Kliendi nimi"
          @input="debounceSaveChanges"
          :class="[
            $parent.saving ? 'disabled' : '',
            $v.$error && $v.pdfData.client_name.$invalid
              ? 'shadow-dangeroutline'
              : ''
          ]"
        />
        <span class="text-sm font-medium ml-2" v-if="pdfData.is_company"
          >Registrikood<span class="font-medium required-tooltip">*</span></span
        >
        <input
          v-if="pdfData.is_company"
          type="text"
          class="mb-2"
          v-model="pdfData.reg_code"
          @input="debounceSaveChanges"
          placeholder="Registrikood"
          :class="$parent.saving ? 'disabled' : ''"
        />
        <span class="text-sm font-medium ml-2" v-if="pdfData.is_company"
          >KMRK kood</span
        >
        <input
          v-if="pdfData.is_company"
          class="mb-2"
          type="text"
          v-model="pdfData.tax_code"
          @input="debounceSaveChanges"
          placeholder="KMRK kood"
          :class="$parent.saving ? 'disabled' : ''"
        />
        <span class="text-sm font-medium ml-2"
          >Aadress<span class="font-medium required-tooltip">*</span></span
        >
        <span
          v-if="!editingAddress"
          class="bg-offwhite py-2 px-3 rounded-xl shadow mb-2 cursor-pointer"
          :class="
            !pdfData.address ||
            (pdfData.address && pdfData.address.length === 0)
              ? 'font-medium text-danger shadow-dangeroutline'
              : ''
          "
          @click="editingAddress = true"
          >{{
            pdfData.address && pdfData.address.length > 0
              ? pdfData.address
              : "Aadress tühi"
          }}</span
        >
        <location-inaddress
          v-if="!$parent.saving && editingAddress"
          @locationChanged="handleLocationChange"
          @clearLocation="clearLocation"
          class="mb-2"
          :location="pdfData.address ? pdfData.address : null"
          :show-error="$v.$error && $v.pdfData.address.$invalid"
          :class="$parent.saving ? 'disabled' : ''"
          :show-delete-text="false"
          v-on-clickaway:mousedown="
            editingAddress
              ? () => {
                  editingAddress = false;
                }
              : ''
          "
        >
        </location-inaddress>
      </div>
      <div class="flex flex-col w-full mt-2 mobile:ml-0 mobile:w-full">
        <span class="py-2 font-medium">Arve andmed</span>
        <span class="text-sm font-medium ml-2"
          >Arve number<span class="font-medium required-tooltip">*</span></span
        >
        <span
          v-if="invoiceNrInUse"
          class="text-sm font-medium text-danger ml-2 my-1"
        >
          Arve number on juba kasutuses!
        </span>
        <input
          type="number"
          v-model="pdfData.invoice_nr"
          @input="debounceSaveChanges"
          :class="[
            $parent.saving ? 'disabled' : '',
            ($v.$error && $v.pdfData.invoice_nr.$invalid) || invoiceNrInUse
              ? 'shadow-dangeroutline'
              : ''
          ]"
          placeholder="Arve nr"
          pattern="^\d+(?:\.\d{1,2})?$"
          class="mb-2"
        />
        <span class="text-sm font-medium ml-2">Kirjeldus</span>
        <textarea
          class="mb-2"
          v-model="pdfData.details"
          @input="debounceSaveChanges"
          placeholder="Arve kirjeldus"
          :class="$parent.saving ? 'disabled' : ''"
        />
        <span class="text-sm font-medium ml-2">Maksetähtaeg</span>
        <div
          class="flex flex-row items-center rounded-xl w-full mb-2"
          id="invoice-datepicker"
        >
          <date-picker
            v-model="pdfData.payment_date"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            class="w-8/12"
            @input="debounceSaveChanges"
            :class="$parent.saving ? 'disabled' : ''"
            :disabled-dates="disabledDates"
          />
          <div v-if="!pdfData.payment_date" class="w-4/12">
            <select
              class="add-offer-input"
              v-model="tempExpiryDate"
              @change="changeExpiryDate"
            >
              <option value="null" disabled class="text-bordergrey"
                >Vali päevade arv</option
              >
              <option :value="7">7 päeva</option>
              <option :value="14">14 päeva</option>
              <option :value="21">21 päeva</option>
              <option :value="30">30 päeva</option>
            </select>
          </div>
          <div v-if="pdfData.payment_date" @click="removePaymentDate">
            <button class="new-button-danger">
              <span class="typcn typcn-times icon"></span>
              <span class="label">Eemalda</span>
            </button>
          </div>
        </div>
        <span class="text-sm font-medium ml-2">Viivis</span>
        <div class="flex items-center">
          <input
            type="number"
            v-model="pdfData.interest_rate"
            @input="debounceSaveChanges"
            class="w-1/5 mobile:w-1/4"
            :class="$parent.saving ? 'disabled' : ''"
            placeholder="Viivis"
            step="0.1"
            pattern="^\d+(?:\.\d{1,2})?$"
          />
          <span class="ml-1">%</span>
        </div>
      </div>
    </div>
    <div v-if="pdfViewActive && pdfSrc">
      <pdf-modal
        :pdf-src="pdfSrc"
        @closeModal="closePdfView"
        @saveFile="generatePdfPreview(true)"
      ></pdf-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import PdfModal from "@/components/reusable/PdfModal";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import debounce from "lodash/debounce";
import DatePicker from "vuejs-datepicker";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  name: "Pdf",
  components: { LocationInaddress, PdfModal, DatePicker },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      pdfSrc: null,
      pdfViewActive: false,
      ee: ee,

      pdfData: {
        details: "",
        client_name: "",
        is_company: false,
        reg_code: "",
        tax_code: "",
        address: "",
        invoice_nr: null,
        payment_date: null,
        interest_rate: null
      },
      dataLoaded: false,
      tempExpiryDate: null,
      editingAddress: false,
      invoiceNrInUse: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    loadPdfInfo() {
      this.apiRequest(
        "projects/" + this.draft.uuid + "/invoice/" + this.invoice.id + "/pdf/",
        "get",
        true
      ).then(res => {
        if (res) {
          this.pdfData.details = res.data.details;
          this.pdfData.client_name = res.data.client_name;
          this.pdfData.is_company = res.data.is_company;
          this.pdfData.reg_code = res.data.reg_code;
          this.pdfData.tax_code = res.data.tax_code;
          this.pdfData.address = res.data.address;
          this.pdfData.expiry_date = res.data.expiry_date;
          this.pdfData.address = res.data.address;
          this.pdfData.invoice_nr = res.data.invoice_nr;
          this.pdfData.payment_date = res.data.payment_date;
          this.pdfData.interest_rate = res.data.interest_rate;
        }
        this.dataLoaded = true;
        this.$v.$touch();
      });
    },
    debounceSaveChanges: debounce(function() {
      this.editPdfInfo();
    }, 1000),
    editPdfInfo() {
      this.$parent.saved = false;
      this.$parent.saving = true;
      let self = this;
      if (this.pdfData.payment_date) {
        this.pdfData.payment_date = moment(this.pdfData.payment_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.pdfData.interest_rate === "") {
        this.pdfData.interest_rate = null;
      }
      this.invoiceNrInUse = false;
      this.apiRequest(
        "projects/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/pdf/edit/",
        "patch",
        true,
        this.pdfData
      ).then(
        res => {
          if (res.status === 200) {
            this.$parent.saving = false;
            this.$parent.saved = true;
            this.$parent.savedTimeout = setTimeout(function() {
              self.$parent.saved = false;
            }, 5000);
            this.loadPdfInfo();
          }
        },
        error => {
          const errObj = { error };
          if (errObj.error.response.status === 409) {
            this.$parent.saved = true;
            this.$parent.saving = false;
            this.invoiceNrInUse = true;
          }
        }
      );
    },
    clearLocation() {
      this.pdfData.address = "";
      this.editPdfInfo();
    },
    handleLocationChange(e) {
      this.pdfData.address = e.ipikkaadress;
      this.editingAddress = false;
      this.editPdfInfo();
    },
    generatePdfPreview(createFile) {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          "notes/draft/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/generate/" +
          (createFile ? "?save_file=True" : ""),
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    },
    removePaymentDate() {
      this.pdfData.payment_date = null;
      this.editPdfInfo();
    },
    changeExpiryDate() {
      this.pdfData.payment_date = moment()
        .add(this.tempExpiryDate, "days")
        .toDate();
      this.tempExpiryDate = null;
      this.editPdfInfo();
    },
    confirmDataComplete() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editPdfInfo();
        this.$emit("pdfDataSaved");
      }
    }
  },
  mounted() {
    this.loadPdfInfo();
  },
  computed: {
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    }
  },
  validations() {
    if (this.pdfData.is_company) {
      return {
        pdfData: {
          client_name: {
            required
          },
          reg_code: {
            required
          },
          address: {
            required
          },
          invoice_nr: {
            required
          }
        }
      };
    } else {
      return {
        pdfData: {
          client_name: {
            required
          },
          address: {
            required
          },
          invoice_nr: {
            required
          }
        }
      };
    }
  }
};
</script>

<style lang="scss">
.required-tooltip {
  @apply cursor-default ml-1;
  &:hover {
    @apply text-green;
    &:after {
      @apply text-black bg-offwhite rounded-xl ml-1 px-2 shadow;
      content: "Kohustuslik";
    }
  }
}
#invoice-datepicker {
  .vdp-datepicker {
    div {
      @apply mr-4;
      input {
        @apply w-full;
      }
    }
  }
}
</style>
