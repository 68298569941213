<template>
  <div class="off-status-menu z-50 mobile:bg-white">
    <transition-group name="fade" :duration="150" mode="out-in" class="w-full">
      <span
        class="status-menu-item mobile:bg-white"
        v-if="data.status !== 2"
        v-html="parseDraftOfferStatus(2)"
        @click="changeOfferStatus(2)"
        :key="2"
      >
      </span>
      <span
        v-if="data.status !== 4"
        class="status-menu-item mobile:bg-white"
        v-html="parseDraftOfferStatus(4)"
        @click="changeOfferStatus(4)"
        :key="4"
      >
      </span>
      <span
        v-if="data.status !== 5"
        class="status-menu-item mobile:bg-white"
        v-html="parseDraftOfferStatus(5)"
        @click="changeOfferStatus(5)"
        :key="5"
      >
      </span>
    </transition-group>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [StatusParser],
  methods: {
    changeOfferStatus(status) {
      this.$emit("changeStatus", status);
    }
  }
};
</script>

<style lang="scss">
.off-status-menu {
  animation: dropdownslide 0.1s;
  @apply absolute bg-white shadow rounded-b-xl flex flex-col items-center w-full;
  top: 100%;
  .status-menu-item {
    @apply py-2 w-full justify-start flex cursor-pointer items-center;
    .status-label {
      @apply justify-start;
    }
    &:hover {
      @apply bg-offwhite;
    }
  }
}
</style>
