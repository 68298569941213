import jwtdecode from "jwt-decode";
import cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";

export const userData = {
  state: {
    user: {},
    retrieved: false,
    cookieState: {
      consentPopup: false,
      coreCookie: true,
      activationPopup: false,
      feedbackBanner: false
    },
    subWarning: false
  },
  namespaced: true,
  mutations: {
    setUserData(state, data) {
      state.retrieved = false;
      state.user = data;
      state.user.currency = "€";
      state.retrieved = true;
    },
    clear(state) {
      state.user = {};
      state.retrieved = false;
    },
    openSubWarning(state) {
      state.subWarning = true;
    },
    closeSubWarning(state) {
      state.subWarning = false;
    },
    initCookieState(state) {
      const cookieObject = { core: true };
      if (cookies.get("gwd_analyticsEnabled")) {
        cookieObject.consentPopup = false;
        if (cookies.get("gwd_analyticsEnabled") === "true") {
          cookieObject.analyticsEnabled = true;
        } else {
          cookieObject.analyticsEnabled = false;
        }
      } else {
        cookieObject.consentPopup = true;
      }
      if (cookies.get("gwd_activationPopup")) {
        cookieObject.activationPopup = false;
      } else {
        cookieObject.activationPopup = true;
      }
      if (cookies.get("gwd_feedbackBanner")) {
        cookieObject.feedbackBanner = false;
      } else {
        cookieObject.feedbackBanner = true;
      }
      state.cookieState = cookieObject;
    }
  },
  actions: {
    retrieveProfileData({ commit }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `profiles/account/${
            jwtdecode(localStorage.getItem("access")).user_id
          }/`,
          "get",
          true
        )
          .then(
            async res => {
              commit("clear");
              commit("setUserData", res.data);
              resolve(res);
            },
            err => {
              reject(err);
            }
          )
          .catch(err => {
            reject(err);
          });
      });
    },
    clearUserData({ commit }) {
      commit("clear");
    },
    initCookieState({ commit }, userId) {
      commit("initCookieState", userId);
    },
    setSiteFeatureCookies({ dispatch }, siteFeatureCookieState) {
      cookies.set("gwd_consent_id", uuidv4(), { expires: 9999 });
      cookies.set("gwd_consent_timeset", Date.now(), {
        expires: 9999
      });
      cookies.set("gwd_coreEnabled", true, { expires: 9999 });
      if (siteFeatureCookieState.analytics) {
        cookies.set("gwd_analyticsEnabled", siteFeatureCookieState.analytics, {
          expires: 9999
        });
      }
      dispatch("initCookieState");
    },
    setActivationPopupCookie({ dispatch }) {
      cookies.set("gwd_activationPopup", true, { expires: 1 });
      dispatch("initCookieState");
    },
    openSubWarning({ commit }) {
      commit("openSubWarning");
    },
    closeSubWarning({ commit }) {
      commit("closeSubWarning");
    }
  }
};
