const ActivateAccount = () => import("@/views/core/ActivateAccount");
const AddSubscription = () => import("@/views/account/AddSubscription");
const SubscriptionRedirect = () =>
  import("@/views/account/SubscriptionRedirect");
const IndividualSubscriptionRedirect = () =>
  import("@/views/account/IndividualSubscriptionRedirect");
const ActivateTrial = () => import("@/views/account/ActivateTrial");
const ResendActivation = () => import("@/views/account/ResendActivation");
const Settings = () => import("@/views/account/Settings");

const accountRoutes = [
  {
    path: "/activate-account/:token",
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: {
      public: true,
      title: "Aktiveeri kasutaja | Gewodo"
    }
  },
  {
    path: "/subscription/redirect/",
    name: "SubscriptionRedirect",
    component: SubscriptionRedirect,
    meta: {
      public: false,
      title: "Tagasisuunamine | Gewodo"
    }
  },
  {
    path: "/subscription/:companyID/individual/redirect/",
    name: "IndividualSubscriptionRedirect",
    component: IndividualSubscriptionRedirect,
    meta: {
      public: false,
      title: "Tagasisuunamine | Gewodo"
    }
  },
  {
    path: "/subscriptions/add",
    name: "AddSubscription",
    component: AddSubscription,
    meta: {
      public: false,
      title: "Lisa pakett | Gewodo",
      sidebar: true
    }
  },
  {
    path: "/trial",
    name: "ActivateTrial",
    component: ActivateTrial,
    meta: {
      public: false,
      title: "Aktiveeri prooviperiood | Gewodo",
      sidebar: true
    }
  },
  {
    path: "/resend-activation",
    name: "ResendActivation",
    component: ResendActivation,
    meta: {
      public: false,
      title: "Saada aktivatsioonimeil uuesti | Gewodo",
      sidebar: true
    }
  },
  {
    path: "/account",
    name: "AccountSettings",
    component: Settings,
    meta: {
      public: false,
      title: "Kontoseaded | Gewodo",
      sidebar: true
    }
  }
];

export default accountRoutes;
