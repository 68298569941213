<template>
  <div
    class="fixed consent-popup bg-offwhite text-black w-full px-5 py-8 flex-row mobile:flex-col flex z-50"
    :class="menuActive ? 'mobile:h-full h-auto' : 'h-auto'"
  >
    <div
      class="w-9/12 mobile:w-full mx-auto flex mobile:flex-col"
      :class="menuActive ? 'mobile:h-full' : ''"
    >
      <div
        class="flex w-8/12 mobile:w-full flex-col justify-center mobile:mb-4"
        v-if="!menuActive"
      >
        <h4 class="mobile:mb-4">
          Sellel veebilehel kasutatakse küpsiseid.
        </h4>
        <p>
          Me kasutame küpsiseid, et mõõta reklaamikampaaniate efektiivsust ning
          parandada kasutajakogemust. Kui soovite rohkem infot lehe tööks
          vajalike küpsiste kohta, palun lugege meie küpsiste kasutamise infot.
        </p>
      </div>
      <div
        class="flex w-8/12 mobile:w-full flex-col justify-center mobile:flex-wrap mobile:mb-auto"
        v-if="menuActive"
      >
        <div class="flex flex-row w-full mobile:flex-wrap  mb-3">
          <div
            class="w-3/12 mobile:w-full flex flex-col mobile:flex-row items-center justify-center mobile:justify-around mobile:mb-3"
          >
            <h4>Vajalikud</h4>
            <label class="switch cursor-default"
              ><input type="checkbox" disabled v-model="coreEnabled"/><span
                class="slider round"
              ></span
            ></label>
          </div>
          <div class="w-9/12 mobile:mx-auto flex flex-col justify-center">
            <p>
              Need küpsised talitavad veebilehe tööd, hõlmates nt.
              sisselogimist, turvalisust, jne. Neid pole võimalik välja lülitada
            </p>
          </div>
        </div>
        <div class="flex flex-row w-full mobile:flex-col">
          <div
            class="w-3/12 mobile:w-full flex flex-col items-center justify-center mobile:flex-row mobile:justify-around"
          >
            <h4>Analüütika</h4>
            <label class="switch cursor-default"
              ><input type="checkbox" v-model="analyticsEnabled"/><span
                class="slider round"
              ></span
            ></label>
          </div>
          <div class="w-9/12 mobile:mx-auto flex flex-col justify-center">
            <p>
              Need küpsised hõlmavad analüütikat ning turundusega seonduvat
              infot. Nendest saadud infot kasutame kasutajakogemuse parandamise
              ning reklaamide täiendamise eesmärgil.
            </p>
          </div>
        </div>
      </div>
      <div
        class="flex w-4/12 mobile:w-full flex-col py-3 px-8 justify-center"
        :class="menuActive ? 'mt-auto' : ''"
      >
        <button @click="acceptCookies(true)" class="new-button-green mb-2">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Luba kõik küpsised</span>
        </button>
        <button
          @click="viewPreferences"
          v-if="!menuActive"
          class="new-button-transparent"
        >
          <span class="icon typcn typcn-cog"></span>
          <span class="label py-1">Vaata seadeid</span>
        </button>
        <button
          @click="acceptCookies"
          class="mb-2 new-button-transparent"
          v-if="menuActive"
        >
          <span class="icon typcn typcn-cog"></span>
          <span class="label">Luba valitud küpsised</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuActive: false,
      coreEnabled: true,
      analyticsEnabled: false
    };
  },
  methods: {
    acceptCookies(all) {
      if (all) {
        this.$emit("acceptCookies", {
          core: true,
          analytics: true
        });
      } else {
        this.$emit("acceptCookies", {
          core: true,
          analytics: this.analyticsEnabled
        });
      }
    },
    viewPreferences() {
      this.menuActive = true;
    }
  }
};
</script>

<style lang="scss">
.consent-popup {
  bottom: 0;
}
</style>
