<template>
  <div class="">
    <div
      class="mb-2 relative items-center rounded-xl justify-between flex"
      :class="
        canClick ? 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer' : ''
      "
      @click="expanded = !expanded"
    >
      <div class="flex items-center">
        <span class="p-2 rounded-xl shadow-sm bg-darkgrey text-white mr-2">
          <img src="/bc21/dokumendid.svg" class="h-4 w-4 filter-to-white"
        /></span>
        <h4 class="mr-2 pl-2">Dokumendid</h4>
        <span
          class="note-amount flex justify-center items-center font-semibold leading-none"
        >
          {{ files.length }}
        </span>
      </div>
      <span
        class="typcn typcn-chevron-right transform duration-100 px-2"
        :class="!expanded > 0 ? 'rotate-90' : '-rotate-90'"
        v-if="canClick"
      ></span>
    </div>
    <div v-if="expanded">
      <div v-if="fileList.length === 0">
        <compact-file-display
          v-for="file in files"
          :key="file.id"
          :file="file"
          :draft="draft"
          :company="company"
          @deleteFile="deleteFile"
        >
        </compact-file-display>
      </div>

      <div class="flex flex-col mb-2">
        <div
          v-for="(item, index) in fileList"
          :key="index"
          class="bg-offwhite font-semibold p-1 rounded-xl mb-1 w-full text-darkgrey shadow"
        >
          <div
            class="flex py-1 px-1 items-center w-full h-full justify-between"
          >
            <span class="flex">{{ item.name }}</span>
            <button
              class="bg-danger rounded-xl h-8 w-8 flex justify-center items-center hover:bg-dangerdark hover:shadow-dangeroutline"
              @click="removeFile(item)"
            >
              <span class="typcn typcn-times text-white"></span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex flex-row justify-start mobile:justify-between mt-2 mb-2"
        v-if="
          !$store.state.companyData.isClient &&
            ['R0', 'R1', 'R2', 'R3', 'R5'].includes(
              $store.state.companyData.activeCompany.worker_permission
            ) &&
            taskStatus !== 6
        "
      >
        <input ref="file" type="file" multiple hidden @change="updateFiles" />
        <button class="alt-button-pending" @click="selectFile">
          <span class="typcn typcn-document-add icon"></span>
          <span class="label">Lisa faile</span>
        </button>
        <button
          v-if="fileList.length > 0"
          @click="saveTaskFiles"
          class="new-button-green ml-3 mobile:ml-0"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Salvesta</span>
        </button>
      </div>
    </div>

    <file-modal
      v-if="$store.state.fileViewer.displayFileModal"
      :file="$store.state.fileViewer.fileToDisplay"
      :company="$store.state.fileViewer.company"
      :draft="$store.state.fileViewer.draft"
      @fileDeleted="fileDeleted"
    ></file-modal>
  </div>
</template>

<script>
import CompactFileDisplay from "@/components/reusable/CompactFileDisplay";
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileModal from "@/components/reusable/FileModal";
export default {
  name: "TaskFiles",
  components: { FileModal, CompactFileDisplay },
  props: {
    task: { type: Number, default: null },
    files: {
      type: Array,
      default: () => {
        return [];
      }
    },
    draft: {
      type: Object,
      default: null
    },
    company: { type: String, default: "" },
    taskStatus: { type: Number, default: null }
  },
  data() {
    return {
      fileList: [],
      expanded: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    deleteFile(e) {
      this.$emit("deleteFile", e);
    },
    fileDeleted() {
      this.$emit("fileDeleted");
    },
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
      // Do something with chosen file
    },
    updateFiles() {
      this.fileList = [...this.$refs.file.files];
    },
    removeFile(index) {
      if (this.fileList.find(item => item === index) !== undefined) {
        this.fileList.splice(
          this.fileList.findIndex(item => item === index),
          1
        );
      }
    },
    saveTaskFiles() {
      const data = new FormData();
      this.$store.state.loading = true;
      if (this.fileList.length > 0) {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          const fileToAppend = new File([file], file.name, { type: file.type });
          data.append("files", fileToAppend, file.name);
        }
      }
      this.apiRequest(
        "tasks/" + this.task + "/files/add/",
        "post",
        true,
        data
      ).then(res => {
        if (res.status === 201) {
          this.$emit("loadFiles");
          this.$store.state.loading = false;
          this.fileList = [];
        }
      });
    }
  },
  computed: {
    canClick() {
      return (
        (this.files.length === 0 &&
          !this.$store.state.companyData.isClient &&
          ["R0", "R1", "R2", "R3", "R5"].includes(
            this.$store.state.companyData.activeCompany.worker_permission
          ) &&
          this.taskStatus !== 6) ||
        this.files.length > 0
      );
    }
  }
};
</script>
