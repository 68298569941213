<template>
  <div class="flex flex-col w-full z-10" v-if="invoice && itemsLoaded">
    <div class="w-full grid grid-cols-12 service-ex-row-headers">
      <div
        class="flex col-span-4 mobile:col-span-5 justify-start font-medium"
        :class="editMode ? 'justify-start mobile:hidden pl-2' : 'justify-start'"
      >
        Kirjeldus
      </div>
      <div
        class="flex font-medium mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        Kogus
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        Ühik
      </div>
      <div
        class="flex font-medium  mobile:hidden truncate"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        Ühikuhind<span class="medium:hidden">(€)</span>
      </div>
      <div
        class="flex font-medium  mobile:hidden truncate"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        <h4 class="truncate">Summa <span class="medium:hidden">(-km)</span></h4>
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        KM%
      </div>
      <div
        class="flex font-medium  mobile:hidden"
        :class="editMode ? 'justify-start pl-2' : 'justify-start'"
      >
        KM
      </div>
      <div
        class="flex font-medium mobile:col-span-5"
        :class="
          editMode
            ? 'justify-start mobile:hidden pl-2'
            : 'justify-start col-span-1'
        "
      >
        Summa
      </div>
    </div>
    <div
      class="w-full h-1 rounded-full mt-2 duration-100 bg-offwhite mb-1"
    ></div>
    <div class="flex flex-col service-container z-10" id="service-container">
      <transition-group name="list">
        <div
          class="flex flex-col"
          v-for="(item, index) in items"
          :key="item.id"
        >
          <service-row
            :data="item"
            :is-white="true"
            :offer-status="invoice.status"
            :edit-mode="editMode"
            :service-templates="serviceTemplates"
            @serviceUpdated="handleServiceUpdate"
            @removeService="handleRemoveService"
            v-if="!editMode"
          ></service-row>
          <new-service-row
            :service="item"
            @removeService="handleRemoveService"
            :service-templates="serviceTemplates"
            :index="index"
            :is-white="true"
            v-if="editMode"
            class="mb-1"
            :active-template-selector="activeTemplateSelector"
            @openTemplateSelector="changeActiveTemplateSelector"
          ></new-service-row>
          <div class="flex w-full h-1 bg-offwhite mb-1"></div>
        </div>
      </transition-group>
      <div v-if="showServices && editMode">
        <div class="flex flex-col">
          <transition-group name="list">
            <new-service-row
              v-for="item in newServices"
              :key="item.index"
              :service="item"
              @removeService="removeService"
              :index="item.index"
              :service-templates="serviceTemplates"
              :active-template-selector="activeTemplateSelector"
              :is-white="true"
              class="mb-1"
              @openTemplateSelector="changeActiveTemplateSelector"
            ></new-service-row>
          </transition-group>
        </div>
      </div>
    </div>
    <div class="flex py-3 mt-3" v-if="editMode">
      <button @click="addService" class="alt-button-green">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa rida</span>
      </button>
    </div>
    <div
      class="flex items-center pt-2"
      v-if="!$store.state.companyData.isClient"
    >
      <button
        class="alt-button-pending"
        @click="editMode = true"
        v-if="!editMode"
      >
        <span class="typcn typcn-edit icon"></span>
        <span class="label">Muuda</span>
      </button>
      <button
        v-if="editMode"
        @click="saveOfferItems"
        class="new-button-green mr-4"
      >
        <span class="typcn typcn-tick icon"></span>
        <span class="label">Salvesta</span>
      </button>
      <button
        @click="editMode = false"
        v-if="editMode"
        class="new-button-danger"
      >
        <span class="typcn typcn-times icon"></span>
        <span v-if="editMode" class="label">Tühista</span>
      </button>
    </div>
  </div>
</template>

<script>
import NewServiceRow from "@/components/project/offer/services/NewServiceRow";
import ServiceRow from "@/components/project/offer/services/ServiceRowExistingOffer";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  components: {
    NewServiceRow,
    ServiceRow
  },
  data() {
    return {
      editMode: false,
      newServices: [],
      items: [],
      itemsLoaded: false,
      serviceTemplates: [],
      activeTemplateSelector: null,
      startIndex: 0,
      showServices: true
    };
  },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [RequestHandler],
  mounted() {
    this.getItems();
    this.loadTemplates();
  },
  methods: {
    loadTemplates() {
      if (!this.$store.state.companyData.isClient) {
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/templates/details/",
          "get",
          true
        ).then(res => {
          this.detailTemplates = res.data.filter(x => x.type === 1);
        });
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/templates/items/",
          "get",
          true
        ).then(res => {
          this.serviceTemplates = res.data;
        });
      }
    },
    getItems() {
      let url;
      if (!this.$store.state.companyData.isClient) {
        url =
          "notes/project/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/items/";
      } else {
        url =
          "projects/client/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/items/";
      }
      this.apiRequest(url, "get", true).then(res => {
        this.items = res.data;
        this.itemsLoaded = true;
      });
    },
    genId(item) {
      const gennedId = Math.round(Math.random() * 100000);
      if (item) {
        this.items.find(x => x.id === item.id).index = gennedId;
      }
      return gennedId;
    },
    saveOfferItems() {
      this.$emit("serviceUpdated", {
        items: this.items,
        newServices: this.newServices
      });
      this.editMode = false;
      this.newServices = [];
    },
    addService() {
      let data = {
        index: this.startIndex + 1,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20
      };
      this.startIndex += 1;
      this.newServices.push(data);
      setTimeout(function() {
        document
          .getElementById("service-container")
          .scroll({ left: 0, top: 999999, behavior: "smooth" });
      }, 100);
    },
    handleRemoveService(e) {
      if (this.items.find(item => item.id === e) !== undefined) {
        this.items.splice(
          this.items.findIndex(x => x.id === e),
          1
        );
      } else if (
        this.newServices.find(item => item.index === e) !== undefined
      ) {
        this.newServices.splice(
          this.newServices.findIndex(x => x.index === e),
          1
        );
      }
    },
    removeService(e) {
      if (this.newServices.find(x => (x.index === e) !== undefined)) {
        this.newServices.splice(
          this.newServices.findIndex(x => x.index === e),
          1
        );
      }
    },
    handleServiceUpdate(e) {
      let itemToChange = this.items.findIndex(item => item.id === e.id);
      this.items[itemToChange] = e;
    },
    changeActiveTemplateSelector(e) {
      this.activeTemplateSelector = e.id;
    }
  },
  watch: {
    newServices() {
      this.showServices = false;
      this.showServices = true;
    }
  }
};
</script>
