var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-1 rounded-xl bg-offwhite flex justify-between items-center shadow cursor-pointer",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":_vm.openFileDisplay}},[_c('span',{staticClass:"w-10/12 flex px-2 py-2",class:!_vm.$store.state.companyData.isClient &&
      ['R0', 'R1', 'R2'].includes(
        _vm.$store.state.companyData.activeCompany.worker_permission
      )
        ? 'w-10/12'
        : 'w-full'},[_vm._v(" "+_vm._s(_vm.file.file_name))]),(
      !_vm.$store.state.companyData.isClient &&
        ['R0', 'R1', 'R2'].includes(
          _vm.$store.state.companyData.activeCompany.worker_permission
        )
    )?_c('div',{staticClass:"w-2/12 justify-end flex h-full items-center"},[_c('span',{staticClass:"p-2 rounded-xl shadow-sm bg-danger text-white mr-2",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.confirmDeleteActive = true}}},[_c('img',{staticClass:"h-4 w-4 filter-to-white",attrs:{"src":"/bc21/trash.svg"}})])]):_vm._e(),(_vm.confirmDeleteActive)?_c('confirm-modal',{attrs:{"text":"Olete kindel, et soovite valitud faili kustutada?"},on:{"confirm":_vm.deleteFile,"closeModal":function($event){_vm.confirmDeleteActive = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }