<template>
  <div class="w-full" v-if="todosLoaded">
    <h4 class="mb-2">Tegevused</h4>
    <span v-if="!canEdit && todos.length === 0">
      Tegevused puuduvad!
    </span>
    <div class="w-full">
      <div
        v-for="(todo, index) in todos"
        :key="todo.id"
        class="flex flex-col justify-start"
      >
        <task-todo
          :todo="todo"
          :task="task"
          :index="index + 1"
          :can-edit="canEdit"
          class="mb-3"
          @addNewItem="handleNewItem"
          @todoChanged="changeTodoStatus"
          @todoItemSaved="retrieveTaskTodos"
          @deleteTodo="deleteTodo"
          @triggerReload="retrieveTaskTodos"
        ></task-todo>
      </div>
    </div>
    <div v-if="taskStatus === 6 && todos.length === 0">
      <span>Tegevused puuduvad</span>
    </div>
    <div class="flex" v-if="!addNewTodo && canEdit">
      <button class="alt-button-green" @click="addNewTodo = true">
        <span class="icon typcn typcn-plus"></span>
        <span class="label">Lisa uus nimekiri</span>
      </button>
    </div>
    <div v-if="addNewTodo && canEdit" class="w-full">
      <h4 class="mb-2">Lisa uus tegevusnimekiri</h4>
      <input
        type="text"
        class="mb-4 w-full"
        v-model="newTodoData.description"
        placeholder="Pealkiri"
      />
      <div class="flex">
        <button class="new-button-green mr-4" @click="saveNewTodo">
          <span class="icon typcn typcn-plus"></span>
          <span class="label">Lisa</span>
        </button>
        <button class="new-button-danger" @click="addNewTodo = false">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TaskTodo from "@/components/tasks/todo/TaskTodo";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "TaskTodos",
  mixins: [RequestHandler],
  components: { TaskTodo },
  props: {
    task: { type: Number, default: null },
    taskStatus: { type: Number, default: null },
    userRole: { type: String, default: null }
  },
  data() {
    return {
      addNewTodo: false,
      showAddButton: false,
      startingIndex: 1,
      todos: null,
      todosLoaded: false,
      newTodoData: {
        description: "",
        index: 1,
        sub_items: []
      }
    };
  },
  methods: {
    retrieveTaskTodos(partial = true) {
      if (!partial) {
        this.todosLoaded = false;
      }

      let url;
      if (this.$store.state.companyData.isClient) {
        url = "tasks/" + this.task + "/client/todos/";
      } else {
        url = "tasks/" + this.task + "/todos/";
      }
      this.apiRequest(url, "get", true).then(res => {
        if (res.status === 200) {
          this.todos = res.data;
          this.todosLoaded = true;
        }
      });
    },
    existingItemsAddingActive(e) {
      this.showAddButton = e;
    },
    deleteTodo(e) {
      this.$emit("deleteTodo", e);
    },
    handleNewItem() {
      this.$emit("newItemAdded");
    },
    editTodoStatus(e) {
      this.apiRequest(
        "tasks/" + this.task + "/todos/" + e + "/",
        "put",
        true
      ).then(res => {
        if (res.status === 200) {
          this.retrieveTaskTodos();
        }
      });
    },
    saveNewTodo() {
      this.apiRequest(
        "tasks/" + this.task + "/todos/create/",
        "post",
        true,
        this.newTodoData
      ).then(res => {
        if (res) {
          this.retrieveTaskTodos();
          this.addNewTodo = false;
        }
      });
    },
    subItemNewItemAdded(parentIndex, newIndex, description) {
      let item = this.newTodoData.sub_items.find(x => x.index === parentIndex);
      item.sub_items.push({
        index: newIndex,
        description: description
      });
    },
    addItem() {
      this.newTodoData.sub_items.push({
        description: "",
        index: this.startingIndex,
        sub_items: []
      });
      this.startingIndex += 1;
    },
    changeTodoStatus(e) {
      this.editTodoStatus(e);
    },
    saveTodo() {
      this.$emit("saveTodo", this.newTodoData);
      this.addNewTodo = false;
    }
  },
  mounted() {
    this.retrieveTaskTodos(false);
  },
  computed: {
    canEdit() {
      return ["R0", "R1", "R2", "R3", "R5"].includes(this.userRole);
    }
  }
};
</script>
