<template>
  <div
    class="mb-1 rounded-xl bg-offwhite flex justify-between items-center shadow cursor-pointer"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="openFileDisplay"
  >
    <span
      class="w-10/12 flex px-2 py-2"
      :class="
        !$store.state.companyData.isClient &&
        ['R0', 'R1', 'R2'].includes(
          $store.state.companyData.activeCompany.worker_permission
        )
          ? 'w-10/12'
          : 'w-full'
      "
    >
      {{ file.file_name }}</span
    >
    <div
      v-if="
        !$store.state.companyData.isClient &&
          ['R0', 'R1', 'R2'].includes(
            $store.state.companyData.activeCompany.worker_permission
          )
      "
      class="w-2/12 justify-end flex h-full items-center"
    >
      <span
        @click.stop.prevent="confirmDeleteActive = true"
        class="p-2 rounded-xl shadow-sm bg-danger text-white mr-2"
      >
        <img src="/bc21/trash.svg" class="h-4 w-4 filter-to-white"
      /></span>
    </div>
    <confirm-modal
      v-if="confirmDeleteActive"
      text="Olete kindel, et soovite valitud faili kustutada?"
      @confirm="deleteFile"
      @closeModal="confirmDeleteActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  name: "CompactFileDisplay",
  components: { ConfirmModal },
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: null
    },
    company: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hover: false,
      confirmDeleteActive: false
    };
  },
  methods: {
    openFileDisplay() {
      if (!this.confirmDeleteActive) {
        if (this.draft) {
          this.$store.dispatch("fileViewer/openFileModal", {
            file: this.file,
            type: "companyFile",
            company: this.$store.state.companyData.isClient
              ? this.company
              : this.$store.state.companyData.activeCompany.uuid,
            draft: this.$store.state.companyData.isClient
              ? this.$route.params.projectId
              : this.draft.object_uuid
          });
        } else {
          this.$store.dispatch("fileViewer/openFileModal", {
            file: this.file,
            type: "companyFile",
            company: this.$store.state.companyData.activeCompany.uuid
          });
        }
      }
    },
    deleteFile() {
      if (!this.$store.state.companyData.isClient) {
        this.$emit("deleteFile", this.file);
      }
    }
  }
};
</script>
