export const taskViewer = {
  state: {
    taskModal: {
      show: false,
      data: null
    },
    addTaskModal: {
      show: false,
      data: null
    }
  },
  namespaced: true,
  mutations: {
    retrieveTasks(state, data) {
      state.tasks = data;
    },
    openTaskModal(state, data) {
      state.taskModal.data = data;
      state.taskModal.show = true;
    },
    closeTaskModal(state) {
      state.taskModal.show = false;
      state.taskModal.data = null;
    },
    openAddTaskModal(state, data) {
      state.addTaskModal.data = data;
      state.addTaskModal.show = true;
    },
    closeAddTaskModal(state) {
      state.addTaskModal.show = false;
      state.addTaskModal.data = null;
    }
  },
  actions: {
    retrieveTasks({ commit }, data) {
      commit("retrieveTasks", data);
    },
    openTaskModal({ commit }, data) {
      commit("openTaskModal", data);
    },
    closeTaskModal({ commit }) {
      commit("closeTaskModal");
    },
    openAddTaskModal({ commit }, data) {
      commit("openAddTaskModal", data);
    },
    closeAddTaskModal({ commit }) {
      commit("closeAddTaskModal");
    }
  }
};
