const Calendar = () => import("@/views/company/Calendar");
const Projects = () => import("@/views/company/Projects");
const NewProject = () => import("@/views/company/NewProject");
const Todo = () => import("@/views/company/Todo");
const Documents = () => import("@/views/company/Documents");
const Workers = () => import("@/views/company/Workers");
const Worker = () => import("@/views/company/Worker");
const Offers = () => import("@/views/company/Offers");
const Clients = () => import("@/views/company/Clients");
const Client = () => import("@/views/company/Client");
const NewClient = () => import("@/views/company/NewClient");
const Subscriptions = () => import("@/views/account/Subscriptions");
const Invoices = () => import("@/views/company/Invoices");
const NewCompany = () => import("@/views/company/NewCompany");
const Templates = () => import("@/views/company/Templates");
const Settings = () => import("@/views/company/Settings");
const NewWorker = () => import("@/views/company/NewWorker");

const companyRoutes = [
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { public: false, title: "Kalender | Gewodo", sidebar: true }
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: { public: false, title: "Projektid | Gewodo", sidebar: true }
  },
  {
    path: "/projects/new",
    name: "NewProject",
    component: NewProject,
    meta: { public: false, title: "Uus projekt | Gewodo", sidebar: true }
  },
  {
    path: "/todo",
    name: "Todo",
    component: Todo,
    meta: { public: false, title: "Märkmik | Gewodo", sidebar: true }
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    meta: { public: false, title: "Dokumendid | Gewodo", sidebar: true }
  },
  {
    path: "/workers",
    name: "Workers",
    component: Workers,
    meta: { public: false, title: "Töötajad | Gewodo", sidebar: true }
  },
  {
    path: "/workers/new",
    name: "NewWorker",
    component: NewWorker,
    meta: { public: false, title: "Lisa uus töötaja | Gewodo", sidebar: true }
  },
  {
    path: "/workers/:id",
    name: "Worker",
    component: Worker,
    meta: { public: false, title: "Töötaja | Gewodo", sidebar: true }
  },
  {
    path: "/offers",
    name: "Offers",
    component: Offers,
    meta: { public: false, title: "Pakkumised | Gewodo", sidebar: true }
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: { public: false, title: "Arved | Gewodo", sidebar: true }
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { public: false, title: "Kliendid | Gewodo", sidebar: true }
  },
  {
    path: "/clients/new",
    name: "NewClient",
    component: NewClient,
    meta: { public: false, title: "Lisa klient | Gewodo", sidebar: true }
  },
  {
    path: "/clients/:clientId",
    name: "Client",
    component: Client,
    meta: { public: false, title: "Klient | Gewodo", sidebar: true }
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    meta: { public: false, title: "Tellimused | Gewodo", sidebar: true }
  },
  {
    path: "/company/new",
    name: "PostCompany",
    component: NewCompany,
    meta: {
      public: true,
      title: "Lisa ettevõte | Gewodo"
    }
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    meta: {
      public: false,
      title: "Mallid | Gewodo",
      sidebar: true
    }
  },
  {
    path: "/settings",
    name: "CompanySettings",
    component: Settings,
    meta: {
      title: "Ettevõtte seaded | Gewodo",
      public: false,
      sidebar: true
    }
  }
];

export default companyRoutes;
