const ClientProjects = () => import("@/views/client/Projects");
const Companies = () => import("@/views/client/Companies");
const clientRoutes = [
  {
    path: "/client/projects",
    name: "ClientProjects",
    component: ClientProjects,
    meta: {
      title: "Projektid | Gewodo",
      public: false,
      sidebar: true
    }
  },
  {
    path: "/client/companies",
    name: "Companies",
    component: Companies,
    meta: {
      public: false,
      sidebar: true
    }
  }
];

export default clientRoutes;
