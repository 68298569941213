<template>
  <div class="modal">
    <div class="modal-body modal-small px-8 pb-5 pt-3 shadow-outline">
      <div class="w-full mx-auto flex flex-col items-center">
        <h3 class="mb-3">Kinnitus</h3>
        <div class="flex bg-white rounded-md p-3 text-justify mb-3">
          <span class="mobile:w-full">{{ text }}</span>
        </div>
        <div class="flex justify-around w-9/12 mobile:w-full">
          <button @click.stop.prevent="confirm()" class="new-button-green">
            <span class="icon typcn typcn-tick"></span>
            <span class="label">{{ affirmButton }}</span>
          </button>
          <button @click.stop.prevent="closeModal()" class="new-button-danger">
            <span class="icon typcn typcn-times"></span>
            <span class="label">{{ rejectButton }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Olete kindel, et soovite teha?"
    },
    affirmButton: {
      type: String,
      default: "Jah"
    },
    rejectButton: {
      type: String,
      default: "Ei"
    },
    headerText: {
      type: String,
      default: "Kinnitus"
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
