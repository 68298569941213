export default {
  methods: {
    parseDraftOfferStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span><span class='text-sm text-center text-attention'>Edastamata</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Edastatud</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Lõpetatud</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span><span class='text-sm text-center text-danger'>Tagasilükatud</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-green items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-green mr-2'></span><span class='text-sm text-center text-green'>Vastuvõetud</span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseDraftOfferStatusNoText(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-green items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-green mr-2'></span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseDraftInvoiceStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span><span class='text-sm text-center text-attention'>Edastamata</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Edastatud</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Lõpetatud</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span><span class='text-sm text-center text-danger'>Üle tähtaja</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-green items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-green mr-2'></span><span class='text-sm text-center text-green'>Makstud</span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseDraftInvoiceStatusNoText(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-green items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-green mr-2'></span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseDraftStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-pending'>Edastamata</span></span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span><span class='text-attention'>Käigus</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-green items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-green mr-2'></span><span class='text-green'>Lõpetatud</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span><span class='text-danger'>Tagasilükatud</span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseTaskStatusNoText(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-grey mr-2'></span></div>";
          break;
        case 2:
          label =
            "<div class='status-label items-center font-medium text-pending' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-pending mr-2'></span></div>";
          break;
        case 3:
          label =
            "<div class='status-label items-center font-medium text-attention' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-attention mr-2'></span></div>";
          break;
        case 4:
          label =
            "<div class='status-label items-center font-medium text-danger' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-danger mr-2'></span></div>";
          break;
        case 5:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 border-green border-4 rounded-full bg-grey mr-2 items-center'></span></div>";
          break;
        case 6:
          label =
            "<div class='status-label items-center font-medium text-green' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-green mr-2'></span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseTaskStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-grey mr-2'></span><span class='text-grey'>Ootel</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label items-center font-medium text-pending' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-pending mr-2'></span><span class='text-pending'>Aktiivne</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label items-center font-medium text-attention' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-attention mr-2'></span><span class='text-attention'>Käigus</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label items-center font-medium text-danger' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-danger mr-2'></span><span class='text-danger'>Probleem</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label items-center font-medium text-turquoise' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-turquoise mr-2'></span><span class='text-turquoise'>Lõpetatud</span></div>";
          break;
        case 6:
          label =
            "<div class='status-label items-center font-medium text-green' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-green mr-2 items-center'></span><span class='text-green'>Kinnitatud</span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseProjectStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-grey mr-2'></span><span class='text-grey'>Ootel</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label items-center font-medium text-pending' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-pending mr-2'></span><span class='text-pending'>Aktiivne</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label items-center font-medium text-attention' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-attention mr-2'></span><span class='text-attention'>Käigus</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label items-center font-medium text-danger' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-danger mr-2'></span><span class='text-danger'>Tõrge</span></span></div>";
          break;
        case 5:
          label =
            "<div class='status-label items-center font-medium text-turquoise' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-turquoise mr-2 items-center'></span><span class='text-turquoise'>Lõpetatud</span></div>";
          break;
        case 6:
          label =
            "<div class='status-label items-center font-medium text-green' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-green mr-2'></span><span class='text-green'>Kinnitatud</span></div>";
          break;
        default:
          label = "";
      }
      return label;
    },
    parseTaskStatusColor(status) {
      let color = "";
      switch (status) {
        case 1:
          color = "bg-grey";
          break;
        case 2:
          color = "bg-pending";
          break;
        case 3:
          color = "bg-attention";
          break;
        case 4:
          color = "bg-danger";
          break;
        case 5:
          color = "bg-turquoise";
          break;
        case 6:
          color = "bg-green";
          break;
        default:
          color = "bg-pending";
          break;
      }
      return color;
    }
  }
};
