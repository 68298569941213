<template>
  <div
    class="notification-tray"
    :class="active ? 'notification-tray-open' : 'notification-tray-closed'"
  >
    <transition :duration="300" name="fade">
      <div
        class="w-full flex justify-center items-center font-semibold flex-grow bg-white rounded-b-xl"
        v-if="$store.state.notifications.unread.length === 0 && active"
      >
        <span>Teavitusi pole!</span>
      </div>
    </transition>
    <transition name="fade" :duration="300">
      <div
        class="notification-card-container"
        v-if="$store.state.notifications.unread.length > 0 && active"
      >
        <notification-card
          v-for="item in $store.state.notifications.unread"
          :key="item.id"
          :notification="item"
          @close="closeTray()"
        ></notification-card>
      </div>
    </transition>
    <transition name="fade" :duration="300"
      ><div class="w-full mt-3 bg-offwhite self-end rounded-b-md" v-if="active">
        <div class="flex flex-col rounded-md">
          <button class="mb-2 new-button-green" @click="markAllRead()">
            Märgi kõik loetuks
          </button>
        </div>
      </div></transition
    >
  </div>
</template>

<script>
import NotificationCard from "@/components/notifications/NotificationCard";
export default {
  props: {
    active: Boolean
  },
  methods: {
    closeTray() {
      this.$emit("closeTray");
    },
    markAllRead() {
      this.$store.dispatch("notifications/readAllUnread");
    }
  },
  components: {
    NotificationCard
  }
};
</script>
