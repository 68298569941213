<template>
  <div class="flex flex-col w-full">
    <div class="flex self-end mobile:hidden items-center p-2">
      <transition name="fade">
        <clip-loader
          class="items-center leading-none"
          v-if="saving"
        ></clip-loader>
        <span
          class="typcn typcn-tick p-2 bg-green rounded-full text-white leading-none"
          v-if="saved"
        ></span>
      </transition>
      <transition name="fade">
        <span v-if="saving" class="mx-2 font-semibold">Salvestan...</span>
        <span v-if="saved" class="mx-2 font-semibold">Salvestatud</span>
      </transition>
    </div>
    <div class="flex flex-row mobile:flex-col mb-4" v-if="dataLoaded">
      <div class="flex flex-col w-6/12 mr-2 mt-2 mobile:mr-0 mobile:w-full">
        <span class="mb-2 font-medium">Kliendi andmed</span>
        <input
          class="mb-2"
          type="text"
          v-model="pdfData.client_name"
          placeholder="Kliendi nimi"
          @input="debounceSaveChanges"
          :class="saving ? 'disabled' : ''"
        />
        <div class="flex flex-row items-center mb-2">
          <span class="mr-2">Klient on ettevõte</span>
          <input
            class="ml-2 shadow-none focus:shadow-none"
            type="checkbox"
            v-model="pdfData.is_company"
            @input="debounceSaveChanges"
            :class="saving ? 'disabled' : ''"
          />
        </div>
        <input
          v-if="pdfData.is_company"
          type="text"
          class="mb-2"
          v-model="pdfData.reg_code"
          @input="debounceSaveChanges"
          placeholder="Registrikood"
          :class="saving ? 'disabled' : ''"
        />
        <input
          v-if="pdfData.is_company"
          class="mb-2"
          type="text"
          v-model="pdfData.tax_code"
          @input="debounceSaveChanges"
          placeholder="KMRK kood"
          :class="saving ? 'disabled' : ''"
        />
        <location-inaddress
          v-if="!saving"
          @locationChanged="handleLocationChange"
          @clearLocation="clearLocation"
          class="mb-2"
          :class="saving ? 'disabled' : ''"
          :location="pdfData.address ? pdfData.address : ''"
          :show-delete-text="false"
        >
        </location-inaddress>
      </div>
      <div class="flex flex-col w-6/12 ml-2 mt-2 mobile:ml-0 mobile:w-full">
        <span class="mb-2 font-medium">Arve andmed</span>
        <textarea
          class="mb-2"
          v-model="pdfData.details"
          @input="debounceSaveChanges"
          placeholder="Arve kirjeldus"
          :class="saving ? 'disabled' : ''"
        />
        <input
          type="number"
          v-model="pdfData.invoice_nr"
          @input="debounceSaveChanges"
          :class="saving ? 'disabled' : ''"
          placeholder="Arve nr"
          pattern="^\d+(?:\.\d{1,2})?$"
          class="mb-2"
        />
        <div class="flex flex-col items-start mb-2 mobile:items-start">
          <span class="mobile:mb-2">Arve maksetähtaeg</span>
          <div class="flex flex-row w-full items-center mobile:flex-wrap">
            <div class="mr-2 mobile:mr-0 mobile:w-8/12">
              <date-picker
                v-model="pdfData.payment_date"
                :language="ee"
                :format="formatDate"
                :monday-first="true"
                @input="debounceSaveChanges"
                class="mobile:ml-0 mr-2"
                :class="saving ? 'disabled' : ''"
                :disabled-dates="disabledDates"
              />
            </div>
            <div
              v-if="!pdfData.payment_date"
              class="mobile:w-full ml-2 mobile:ml-0 mobile:mt-2"
            >
              <select
                class="add-offer-input"
                v-model="tempExpiryDate"
                @change="changeExpiryDate"
              >
                <option value="null" disabled class="text-bordergrey"
                  >Vali päevade arv</option
                >
                <option :value="7">7 päeva</option>
                <option :value="14">14 päeva</option>
                <option :value="21">21 päeva</option>
                <option :value="30">30 päeva</option>
              </select>
            </div>
            <div
              class="ml-2 mobile:w-4/12 mobile:ml-0 flex mobile:justify-end"
              v-if="pdfData.payment_date"
              @click="removePaymentDate"
            >
              <button class="new-button-danger">
                <span class="typcn typcn-times icon"></span>
              </button>
            </div>
          </div>
        </div>
        <input
          type="number"
          v-model="pdfData.interest_rate"
          @input="debounceSaveChanges"
          :class="saving ? 'disabled' : ''"
          placeholder="Viivis"
          pattern="^\d+(?:\.\d{1,2})?$"
        />
      </div>
    </div>
    <div v-if="pdfViewActive && pdfSrc">
      <pdf-modal
        :pdf-src="pdfSrc"
        @closeModal="closePdfView"
        @saveFile="generatePdfPreview(true)"
      ></pdf-modal>
    </div>
    <div
      class="flex flex-row w-full items-start justify-start mb-2 mobile:flex-col mt-auto"
    >
      <button class="new-button-pending" @click="generatePdfPreview(false)">
        <span class="typcn typcn-credit-card icon"></span>
        <span class="label">Arve PDF eelvaade</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PdfModal from "@/components/reusable/PdfModal";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import debounce from "lodash/debounce";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import DatePicker from "vuejs-datepicker";
import LocationInaddress from "@/components/reusable/LocationInaddress";

export default {
  name: "Pdf",
  components: { LocationInaddress, PdfModal, ClipLoader, DatePicker },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      pdfSrc: null,
      pdfViewActive: false,
      ee: ee,

      pdfData: {
        details: "",
        client_name: "",
        is_company: false,
        reg_code: "",
        tax_code: "",
        address: "",
        invoice_nr: null,
        payment_date: null,
        interest_rate: null
      },
      saving: false,
      saved: false,
      savedTimeout: null,
      dataLoaded: false,
      tempExpiryDate: null
    };
  },
  mixins: [RequestHandler],
  methods: {
    clearLocation() {
      this.pdfData.address = "";
      this.editPdfInfo();
    },
    handleLocationChange(e) {
      this.pdfData.address = e.ipikkaadress;
      this.editPdfInfo();
    },
    loadPdfInfo() {
      this.dataLoaded = false;
      this.apiRequest(
        "projects/" + this.draft.uuid + "/invoice/" + this.invoice.id + "/pdf/",
        "get",
        true
      ).then(res => {
        if (res) {
          this.pdfData.details = res.data.details;
          if (
            this.draft.client &&
            (res.data.client_name === "" || !res.data.client_name)
          ) {
            this.pdfData.client_name = this.draft.client.name;
          } else {
            this.pdfData.client_name = res.data.client_name;
          }
          this.pdfData.is_company = res.data.is_company;
          this.pdfData.reg_code = res.data.reg_code;
          this.pdfData.tax_code = res.data.tax_code;
          this.pdfData.address = res.data.address;
          this.pdfData.address = res.data.address;
          this.pdfData.invoice_nr = res.data.invoice_nr;
          this.pdfData.payment_date = res.data.payment_date;
          this.pdfData.interest_rate = res.data.interest_rate;
        }
        this.dataLoaded = true;
      });
    },
    debounceSaveChanges: debounce(function() {
      this.editPdfInfo();
    }, 1000),
    editPdfInfo() {
      this.saving = true;
      let self = this;

      if (this.savedTimeout) {
        clearTimeout(this.savedTimeout);
        this.savedTimeout = null;
      }
      if (this.pdfData.payment_date) {
        this.pdfData.payment_date = moment(this.pdfData.payment_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.pdfData.interest_rate === "") {
        this.pdfData.interest_rate = null;
      }
      this.apiRequest(
        "projects/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/pdf/edit/",
        "patch",
        true,
        this.pdfData
      ).then(res => {
        if (res.status === 200) {
          this.saving = false;
          this.saved = true;
          this.savedTimeout = setTimeout(function() {
            self.saved = false;
          }, 5000);
          this.loadPdfInfo();
        }
      });
    },
    generatePdfPreview(createFile) {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          "notes/draft/" +
          this.draft.uuid +
          "/invoice/" +
          this.invoice.id +
          "/generate/" +
          (createFile ? "?save_file=True" : ""),
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    },
    removePaymentDate() {
      this.pdfData.payment_date = null;
      this.editPdfInfo();
    },
    changeExpiryDate() {
      this.pdfData.payment_date = moment()
        .add(this.tempExpiryDate, "days")
        .toDate();
      this.tempExpiryDate = null;
      this.editPdfInfo();
    }
  },
  mounted() {
    this.loadPdfInfo();
  },
  computed: {
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    }
  }
};
</script>
