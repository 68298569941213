<template>
  <div
    class="flex flex-col"
    :class="[
      stopper.stopper_end === null ? 'mt-2 mb-2' : '',
      stopper.start_location || stopper.end_location ? 'cursor-pointer' : ''
    ]"
  >
    <div
      class="flex flex-row items-center"
      :class="
        stopper.stopper_end === null
          ? 'bg-offwhite text-darkgrey font-medium rounded-xl shadow'
          : 'bg-white py-2'
      "
    >
      <button
        v-if="
          stopper.stopper_end === null && !$store.state.timetracking.loading
        "
        @click="stopTracking()"
        class="bg-danger text-darkgrey hover:bg-dangerdark hover:shadow-dangeroutline p-0 rounded-xl leading-none flex"
      >
        <span
          @click.prevent
          class="typcn typcn-media-stop text-xl rounded-full p-2 text-white"
        ></span>
      </button>
      <span class="ml-2"> Algus: {{ parseDate(stopper.stopper_start) }}</span>
      <span v-if="stopper.stopper_end" class="ml-auto mr-3">
        Lõpp: {{ parseDate(stopper.stopper_end) }}
      </span>
      <span v-if="stopper.stopper_end === null" class="ml-auto mr-3">{{
        timer
      }}</span>
    </div>
    <div
      v-if="stopper.stopper_end !== null"
      class="bg-offwhite w-full h-1"
    ></div>
    <location-modal
      v-if="startLocationActive"
      :start-location="stopper.start_location"
      :end-location="stopper.end_location"
      :task-location="taskLocation"
      @closeModal="startLocationActive = false"
    >
    </location-modal>
  </div>
</template>

<script>
import moment from "moment";
import LocationModal from "@/components/reusable/LocationModal";
export default {
  components: { LocationModal },
  props: {
    stopper: {
      type: Object,
      default: () => {
        return {};
      }
    },
    taskLocation: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      timer: null,
      timerInterval: null,
      startLocationActive: false
    };
  },
  mounted() {
    if (this.stopper.stopper_end === null) {
      this.timer = moment

        .utc(
          moment
            .duration(moment().diff(moment(this.stopper.stopper_start)))
            .asMilliseconds()
        )
        .format("HH:mm:ss");
      this.timerInterval = setInterval(() => {
        this.timer = moment

          .utc(
            moment
              .duration(moment().diff(moment(this.stopper.stopper_start)))
              .asMilliseconds()
          )
          .format("HH:mm:ss");
      }, 1000);
    }
  },
  methods: {
    stopTracking() {
      this.$store.dispatch("timetracking/stopTimer");
    },
    parseDate(time) {
      return moment(time).format("HH:mm");
    },
    openMap() {
      if (
        (this.stopper.start_location &&
          Object.keys(this.stopper.start_location).length > 0) ||
        (this.stopper.end_location &&
          Object.keys(this.stopper.end_location).length > 0)
      ) {
        this.startLocationActive = true;
      }
    }
  }
};
</script>
