<template>
  <div
    class="flex items-center bg-offwhite rounded-xl px-3 py-1"
    v-if="timerInited && !$store.state.timetracking.loading"
  >
    <div class="flex pr-4">
      <h4>Aktiivne ajajälgimine</h4>
    </div>
    <div class="flex flex-col px-2">
      <h4>Ülesanne</h4>
      <span @click="openTaskModal">{{ timerObject.title }}</span>
    </div>
    <div class="flex flex-col px-2">
      <h4>Kestvus</h4>
      {{ timer }}
    </div>
    <div class="flex flex-col justify-center pl-4">
      <button
        class="square-button-danger"
        @click="$store.dispatch('timetracking/stopTimer')"
      >
        <span class="typcn typcn-media-stop"></span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      timer: null,
      timerInterval: null,
      timerObject: null,
      timerInited: false
    };
  },
  mounted() {
    if (this.$store.state.timetracking.hasActiveTimer) {
      this.initTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.timer = null;
    this.timerInited = false;
  },
  methods: {
    initTimer() {
      this.timerObject = this.$store.state.timetracking.timer;
      this.timer = moment
        .utc(
          moment
            .duration(
              moment().diff(
                moment(this.$store.state.timetracking.timer.stopper_start)
              )
            )
            .asMilliseconds()
        )
        .format("HH:mm:ss");
      this.timerInterval = setInterval(() => {
        this.timer = moment
          .utc(
            moment
              .duration(
                moment().diff(
                  moment(this.$store.state.timetracking.timer.stopper_start)
                )
              )
              .asMilliseconds()
          )
          .format("HH:mm:ss");
      }, 1000);
      this.timerInited = true;
    },
    openTaskModal() {
      this.$store.dispatch("taskViewer/openTaskModal", {
        task: this.timerObject.task_id
      });
    }
  },
  watch: {
    "$store.state.timetracking.hasActiveTimer": {
      handler(val) {
        if (val && !this.timerInterval) {
          this.initTimer();
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.timer = null;
          this.timerInited = false;
        }
      }
    }
  }
};
</script>
