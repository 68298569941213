var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-sidebar-cont"},[_c('transition',{attrs:{"name":"fade","duration":{ enter: 300, leave: 20 }}},[(_vm.expanded && _vm.$store.state.companyData.retrieved)?_c('div',{staticClass:"dashboard-sidebar"},[_c('div',{staticClass:"flex flex-col justify-center items-center text-lg font-semibold w-full"},[_c('div',{staticClass:"flex flex-row justify-start items-center relative w-full py-3 mobile:order-2"},[(_vm.$store.state.companyData.retrieved)?_c('span',{staticClass:"break-all px-3"},[_vm._v(_vm._s(_vm.$store.state.companyData.activeCompany.company_name))]):_vm._e(),_c('span',{staticClass:"typcn typcn-arrow-sorted-down company-selector-button",class:_vm.companySelectorActive ? 'company-selector-button-active' : '',on:{"click":function($event){_vm.companySelectorActive = !_vm.companySelectorActive}}})]),(_vm.companySelectorActive)?_c('div',{staticClass:"company-selector w-full pb-2 px-2 mobile:order-1 mobile:pt-3"},[_c('div',{staticClass:"flex flex-col items-center rounded-gwdhalf bg-offwhite"},[_c('span',{staticClass:"text-lg text-center pt-2"},[_vm._v("Vali aktiivne firma")]),_c('span',{staticClass:"heading-underline-small mb-2"}),_vm._l((_vm.$store.state.companyData.companies),function(item,index){return _c('div',{key:item.uuid,staticClass:"w-full hover:bg-darkgreen hover:text-white flex justify-center items-center py-2 cursor-pointer px-2",class:[
                _vm.$store.state.companyData.activeCompany.uuid === item.uuid
                  ? 'text-green'
                  : '',
                index === _vm.$store.state.companyData.companies.length - 1
                  ? 'rounded-b-gwdhalf'
                  : ''
              ],on:{"click":function($event){return _vm.changeActiveCompany(item.uuid)}}},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(item.company_name))])])})],2)]):_vm._e()]),_c('sidebar-link',{attrs:{"target":"/calendar","icon":"/bc21/calendar.svg","text":"Kalender"}}),_c('sidebar-link',{attrs:{"target":"/projects","icon":"/bc21/clipboard.svg","text":"Projektid"}}),_c('sidebar-link',{attrs:{"target":"/workers","icon":"/bc21/töötajad.svg","text":"Töötajad","permissions":['R0', 'R1', 'R2', 'R3']}}),_c('sidebar-link',{attrs:{"target":("/workers/" + (_vm.$store.state.companyData.activeCompany.worker_id)),"icon":"/bc21/töötajad.svg","text":"Minu profiil","permissions":['R5']}}),_c('sidebar-link',{attrs:{"target":"/clients","icon":"/bc21/kliendid.svg","text":"Kliendid","permissions":['R0', 'R1', 'R2']}}),_c('sidebar-link',{attrs:{"target":"/documents","icon":"/bc21/dokumendid.svg","text":"Dokumendid","permissions":['R0', 'R1', 'R2']}}),(
          _vm.$store.state.companyData.activeCompany.subscription_permissions
            .invoices
        )?_c('sidebar-link',{attrs:{"target":"/offers","icon":"/bc21/project.svg","text":"Pakkumised","permissions":['R0', 'R1', 'R2']}}):_vm._e(),(
          _vm.$store.state.companyData.activeCompany.subscription_permissions
            .invoices
        )?_c('sidebar-link',{attrs:{"target":"/invoices","icon":"/pricing/inv.svg","text":"Arved","permissions":['R0', 'R1', 'R2']}}):_vm._e(),(
          _vm.$store.state.companyData.activeCompany.subscription_permissions
            .invoices
        )?_c('sidebar-link',{attrs:{"target":"/templates","icon":"/icons/templates/templates.svg","text":"Mallid","permissions":['R0', 'R1', 'R2']}}):_vm._e(),_c('sidebar-link',{attrs:{"target":"/todo","icon":"/bc21/märkmik.svg","text":"Märkmik","permissions":['R0', 'R1', 'R2']}}),_c('sidebar-link',{staticClass:"mb-2",attrs:{"target":"/subscriptions","icon":"/icons/logonohighlight.png","text":"Pakett","permissions":['R0', 'R1', 'R2']}}),(
          ['R0', 'R1', 'R2'].includes(
            this.$store.state.companyData.activeCompany.worker_permission
          )
        )?_c('div',{staticClass:"flex w-full mobile:mt-4"},[_c('router-link',{staticClass:"flex w-full justify-start px-2 add-project-button",attrs:{"to":"/projects/new"}},[_c('button',{staticClass:"new-button-green w-full"},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa projekt")])])])],1):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }