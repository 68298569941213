<template>
  <div class="modal">
    <div
      class="modal-body modal-vwide h-full"
      v-if="data.invoice"
      v-on-clickaway:mousedown="closeModal"
    >
      <div
        class="flex bg-white rounded-gwdhalf items-center flex-col duration-100 h-full modal-content"
      >
        <div
          class="flex w-full justify-center mobile:justify-start items-center top-row mobile:flex-col"
        >
          <div class="w-full flex justify-start flex-col">
            <div class="flex items-center mobile:flex-col mb-2">
              <span class="text-xl font-semibold mr-4"
                >Arve #{{ data.invoice.invoice_index }}</span
              >
              <div
                class="flex relative justify-start items-end self-start flex-col mobile:w-full"
              >
                <div
                  v-if="
                    $store.state.companyData.isClient ||
                      $store.state.companyData.activeCompany
                        .worker_permission === 'R6'
                  "
                  v-html="parseDraftInvoiceStatus(data.invoice.status)"
                ></div>
                <div
                  class="flex relative w-64 mobile:w-full"
                  v-if="
                    !$store.state.companyData.isClient &&
                      $store.state.companyData.activeCompany
                        .worker_permission !== 'R6'
                  "
                >
                  <div
                    class="flex items-center hover:bg-offerwhite w-full cursor-pointer justify-center"
                    :class="statusMenuOpen ? 'rounded-t-xl' : 'rounded-xl'"
                    @click="
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                        ? (statusMenuOpen = !statusMenuOpen)
                        : $store.dispatch('userData/openSubWarning')
                    "
                  >
                    <div
                      v-if="
                        $store.state.companyData.activeCompany
                          .worker_permission !== 'R6'
                      "
                      class="z-10 relative w-full justify-center flex"
                      :class="statusMenuOpen ? 'rounded-t-xl' : 'rounded-xl'"
                      v-html="parseDraftInvoiceStatus(data.invoice.status)"
                    ></div>
                    <span
                      class="typcn typcn-arrow-sorted-down transform duration-100"
                      :class="statusMenuOpen ? 'rotate-180' : ''"
                    ></span>
                  </div>
                  <offer-status-selector
                    v-if="
                      statusMenuOpen &&
                        $store.state.companyData.activeCompany
                          .subscription_permissions.client_portal
                    "
                    :data="data.invoice"
                    @changeStatus="handleStatusChange"
                  >
                  </offer-status-selector>
                </div>
              </div>
            </div>
            <div class="flex flex-col mobile:px-2">
              <span class="font-medium">Loomise kuupäev</span>
              {{ moment(data.invoice.created_at).format("DD.MM.YYYY") }}
              <span class="font-medium">Hind</span>
              <span>{{ data.invoice.price }}€</span>
            </div>
          </div>
          <div class="tab-tray mb-4 self-end">
            <div
              @click="activeView = 1"
              class="tab-tray-item rounded-l-xl w-1/3"
              :class="activeView === 1 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/bc21/project.svg" />
              </span>
              <span class="tab-tray-label">Teenused</span>
            </div>
            <div
              @click="activeView = 2"
              class="tab-tray-item w-1/3"
              :class="activeView === 2 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/bc21/mail.svg" />
              </span>
              <span class="tab-tray-label">Meil</span>
            </div>
            <div
              @click="activeView = 3"
              class="tab-tray-item w-1/3 rounded-r-xl"
              :class="activeView === 3 ? 'tab-tray-item-active' : ''"
            >
              <span class="tab-tray-icon">
                <img src="/icons/pdf.svg" />
              </span>
              <span class="tab-tray-label">PDF</span>
            </div>
          </div>
        </div>
        <div class="flex bg-offwhite rounded-xl h-1 w-full mt-2 mb-4"></div>
        <div class="flex flex-col w-full rounded-md">
          <div class="flex mb-4 w-full">
            <services
              v-if="activeView === 1"
              :item-data="tempData"
              :draft="data.draft"
              :invoice="data.invoice"
              @serviceUpdated="saveOffer"
              ref="services"
            ></services>
            <add-invoice-email
              v-if="activeView === 2 && !$store.state.companyData.isClient"
              class="w-full"
              :draft="data.draft"
              :invoice="data.invoice"
              @close="showEmailForm = false"
              @update="updateOfferData"
              @send="sendEmail"
              @emailSent="invoiceUpdated"
              ref="email"
            ></add-invoice-email>
            <client-invoice-email
              v-if="activeView === 2 && $store.state.companyData.isClient"
              class="w-full"
              :draft="data.draft"
              :invoice="data.invoice"
            ></client-invoice-email>
            <pdf
              v-if="activeView === 3 && !$store.state.companyData.isClient"
              class="w-full"
              :invoice="data.invoice"
              :draft="data.draft"
            >
            </pdf>
            <client-pdf
              v-if="activeView === 3 && $store.state.companyData.isClient"
              class="w-full"
              :object-id="data.invoice.id"
              :object-name="'invoice'"
              :draft="data.draft"
            ></client-pdf>
          </div>

          <div
            class="hidden flex-row w-full items-start justify-start mb-2 mobile:flex-col mt-auto"
            v-if="
              !showEmailForm &&
                !$store.state.companyData.isClient &&
                $store.state.companyData.activeCompany.subscription_permissions
                  .client_portal
            "
          >
            <button
              class="new-button-danger"
              @click="confirmRemoveModalActive = true"
              v-if="!editMode"
            >
              <span class="icon"
                ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
              /></span>
              <span class="label">Kustuta</span>
            </button>
          </div>

          <confirm-modal
            @closeModal="confirmRemoveModalActive = false"
            @confirm="deleteOffer"
            text="Olete kindel, et soovite valitud arve kustutada?"
            v-if="confirmRemoveModalActive"
          ></confirm-modal>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div
          class="flex mr-3"
          v-if="
            !showEmailForm &&
              !$store.state.companyData.isClient &&
              $store.state.companyData.activeCompany.subscription_permissions
                .client_portal
          "
        >
          <button
            class="new-button-danger mobile:w-full"
            @click="confirmRemoveModalActive = true"
            v-if="!editMode"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label">Kustuta arve</span>
          </button>
        </div>
        <div class="flex">
          <div class="flex">
            <button class="new-button-danger" @click="closeModal">
              <span class="icon typcn typcn-times"></span>
              <span class="label">Sulge</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <display-file-modal
      v-if="$store.state.fileViewer.displayFileModal"
      :file="$store.state.fileViewer.fileToDisplay"
      :draft="$store.state.fileViewer.draft"
      :company="$store.state.fileViewer.company"
      @fileDeleted="$refs.email.loadSavedEmail()"
    ></display-file-modal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
import AddInvoiceEmail from "@/components/project/invoice/AddInvoiceEmail";
import Services from "@/components/project/invoice/modal/Services";
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import OfferStatusSelector from "@/components/reusable/InvoiceStatusSelector";
import axios from "axios";
import moment from "moment";
import { directive as onClickaway } from "vue-clickaway2";
import Pdf from "@/components/project/invoice/modal/Pdf";
import ClientInvoiceEmail from "@/components/project/invoice/modal/ClientInvoiceEmail";
import ClientPdf from "@/components/project/offer/modal/ClientPdf";
import DisplayFileModal from "@/components/reusable/FileModal";
import EventBus from "@/assets/mixins/EventBus";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    DisplayFileModal,
    ClientPdf,
    ClientInvoiceEmail,
    Pdf,
    ConfirmModal,
    AddInvoiceEmail,
    Services,
    OfferStatusSelector
  },
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      activeView: 1,
      tempData: null,
      showEmailForm: false,
      editMode: false,
      details: "",
      moment: moment,
      confirmRemoveModalActive: false,
      statusMenuOpen: false,
      pdfViewActive: false,
      pdfSrc: "",
      emailFormActive: false
    };
  },
  mixins: [StatusParser, RequestHandler],
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  methods: {
    invoiceUpdated() {
      EventBus.$emit("updateInvoices");
    },
    closeModal() {
      if (!this.$store.state.fileViewer.displayFileModal) {
        this.$store.dispatch("invoiceViewer/closeInvoiceModal");
      }
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    },
    handleStatusChange(status) {
      if (status !== this.data.invoice.status) {
        this.apiRequest(
          "notes/draft/" +
            this.data.draft.uuid +
            "/invoice/" +
            this.data.invoice.id +
            "/edit/",
          "patch",
          true,
          { status: status }
        ).then(res => {
          if (res) {
            EventBus.$emit("updateInvoices");
            this.statusMenuOpen = false;
          }
        });
      } else {
        this.statusMenuOpen = false;
      }
    },
    generatePdfPreview(createFile) {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          this.data.draft.uuid +
          "/" +
          this.data.invoice.id +
          "/generate/" +
          (createFile ? "?save_file=True" : ""),
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    downloadPDF() {
      this.apiRequest(
        "files/private/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.data.invoice.pdf[this.invoice.pdf.length - 1].id +
          "/?type=file",
        "get",
        true
      ).then(res => {
        const url = res.data.file_url;
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", "arve.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    updateOfferAsClient(accept) {
      if (this.$store.state.companyData.isClient) {
        this.apiRequest(
          "notes/draft/" +
            this.data.draft.uuid +
            "/invoice/" +
            this.data.invoice.id +
            "/client/update/",
          "put",
          true,
          { accept: accept }
        ).then(res => {
          if (res.status === 200) {
            EventBus.$emit("updateInvoices");
          }
        });
      }
    },
    handleServiceUpdateNew(e) {
      let itemToChange = this.newServices.findIndex(
        item => item.index === e.index
      );
      this.newServices[itemToChange] = e;
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    handleServiceUpdate(e) {
      let itemToChange = this.tempData.findIndex(item => item.id === e.id);
      this.tempData[itemToChange] = e;
    },
    sendEmail(data) {
      data["details"] = this.details;
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/invoice/" +
          this.data.invoice.id +
          "/edit/",
        "patch",
        true,
        data
      ).then(res => {
        if (res) {
          this.$store.state.loading = true;
          this.apiRequest(
            "notes/draft/" +
              this.data.draft.uuid +
              "/" +
              this.data.invoice.id +
              "/send/",
            "post",
            true
          ).then(res2 => {
            if (res2) {
              this.$store.state.loading = false;
              EventBus.$emit("updateInvoices");
            }
          });
        }
      });
    },
    updateOfferData(data) {
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/invoice/" +
          this.data.invoice.id +
          "/edit/",
        "patch",
        true,
        data
      ).then(res => {
        if (res) {
          EventBus.$emit("updateInvoices");
        }
      });
    },
    saveNewServices(newData) {
      let data = [];
      for (let i = 0; i < newData.length; i++) {
        let obj = {};
        obj.name = newData[i].name;
        obj.amount = newData[i].amount;
        obj.unit = newData[i].unit;
        obj.price = newData[i].price;
        obj.vat = newData[i].vat;
        obj.invoice = this.data.invoice.id;
        data.push(obj);
      }
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/invoice/" +
          this.data.invoice.id +
          "/items/add/",
        "post",
        true,
        data
      ).then(res => {
        res;
      });
    },
    deleteOffer() {
      this.apiRequest(
        "notes/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.data.draft.uuid +
          "/invoice/" +
          this.data.invoice.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.$store.dispatch("invoiceViewer/closeInvoiceModal");
          EventBus.$emit("updateInvoices");
        }
      });
    },
    saveOffer(svcData) {
      let data = {};

      data.items = svcData.items;
      this.apiRequest(
        "notes/draft/" +
          this.data.draft.uuid +
          "/invoice/" +
          this.data.invoice.id +
          "/items/edit/",
        "patch",
        true,
        svcData.items
      ).then(res => {
        if (svcData.newServices.length > 0) {
          this.saveNewServices(svcData.newServices);
        }
        if (res.status === 200) {
          let sum = 0;
          for (let i = 0; i < svcData.items.length; i++) {
            sum +=
              parseFloat(svcData.items[i].price) *
              parseFloat(svcData.items[i].amount);
            if (svcData.items[i].vat === 20) {
              sum +=
                parseFloat(svcData.items[i].price) *
                parseFloat(svcData.items[i].amount) *
                0.2;
            }
          }
          for (let i = 0; i < svcData.newServices.length; i++) {
            sum +=
              parseFloat(svcData.newServices[i].price) *
              parseFloat(svcData.newServices[i].amount);
            if (svcData.newServices[i].vat === 20) {
              sum +=
                parseFloat(svcData.newServices[i].price) *
                parseFloat(svcData.newServices[i].amount) *
                0.2;
            }
          }
          sum = sum.toFixed(2);
          let data = {
            post: this.data.draft.id,
            status: this.data.invoice.status,
            details: this.details,
            price: sum
          };
          this.apiRequest(
            "notes/draft/" +
              this.data.draft.uuid +
              "/invoice/" +
              this.data.invoice.id +
              "/edit/",
            "patch",
            true,
            data
          ).then(res => {
            if (res.status === 201) {
              EventBus.$emit("updateInvoices");
              this.$refs.services.getItems();
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.ofm-close-button {
  position: absolute;
  @apply bg-danger w-8 h-8 rounded-full z-10 justify-center items-center;
  right: 3%;
  top: 2%;
}
</style>
