<template>
  <div
    class="flex h-8 w-8 border-2 justify-center items-center rounded-xl duration-100 cursor-pointer"
    :class="[
      val && !hovered ? 'bg-green border-green' : '',
      val && hovered ? 'bg-danger border-danger' : '',
      !val && hovered ? 'bg-offwhite border-green mobile:bg-white' : '',
      !val && !hovered ? 'border-green' : ''
    ]"
    @click="toggle"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <span
      class="typcn text-xl leading-none text-white"
      :class="
        val
          ? 'text-white typcn-tick'
          : hovered
          ? 'text-green typcn-tick'
          : 'text-white'
      "
    ></span>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    baseVal: Boolean
  },
  mounted() {
    this.val = this.baseVal;
  },
  data() {
    return {
      val: false,
      hovered: false
    };
  },
  methods: {
    toggle() {
      this.val = !this.val;
      this.$emit("valueChanged", this.val);
    }
  }
};
</script>
