<template>
  <div
    class="w-full flex flex-col items-center duration-100 mobile:flex-wrap relative"
    :class="isBusy ? 'bg-danger bg-opacity-10' : 'bg-white'"
  >
    <div class="flex w-full items-center p-2">
      <div class="w-1/2 mobile:w-1/2 flex justify-start items-center">
        <div class="mr-3">
          <span class="typcn typcn-user table-icon" />
        </div>
        <span>{{ data.profile_name }}</span>
      </div>
      <div
        class="w-1/2 items-center flex mobile:order-4"
        :class="[
          isBusy
            ? cautionHovered
              ? buttonFaded
                ? 'justify-start'
                : 'justify-between'
              : 'justify-between'
            : 'justify-end'
        ]"
      >
        <div
          class="flex items-center justify-start duration-150 w-auto"
          v-if="isBusy"
        >
          <div
            v-if="!cautionHovered && busyTextFaded"
            class="text-white rounded-xl leading-none w-8 h-8 flex justify-center items-center font-extrabold cursor-default mr-3"
            :class="cautionHovered ? 'bg-dangerdark' : 'bg-danger'"
            @mouseenter="cautionHovered = true"
            @mouseleave="cautionHovered = false"
          >
            !
          </div>
          <transition
            name="fade"
            :duration="{ enter: 300, leave: 100 }"
            @before-enter="busyTextFaded = false"
            @after-leave="busyTextFaded = true"
          >
            <span
              v-if="cautionHovered && buttonFaded"
              class="text-sm py-1"
              @mouseenter="cautionHovered = true"
              @mouseleave="cautionHovered = false"
              >Töötaja on valitud ajal teisele tööle määratud</span
            >
          </transition>
        </div>
        <transition
          name="fade"
          :duration="{ enter: 300, leave: 100 }"
          @before-enter="buttonFaded = false"
          @after-leave="buttonFaded = true"
        >
          <button
            v-if="!cautionHovered && busyTextFaded"
            @click="toggleStatus"
            :class="isSelected ? 'new-button-danger' : 'new-button-green'"
            class="h-8"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          >
            <div
              class="transform duration-150 rounded-gwdfull icon items-center justify-center flex"
              :class="isSelected ? 'rotate-45 translate-x-0.5' : ''"
            >
              <span
                class="typcn typcn-plus text-2xl leading-none transform text-white"
              ></span>
            </div>
          </button>
        </transition>
      </div>
    </div>
    <div class="flex w-full">
      <span class="h-1 w-full bg-offwhite rounded-xl"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isSelected: Boolean,
    isBusy: Boolean
  },
  data() {
    return {
      isHovered: false,
      cautionHovered: false,
      buttonFaded: false,
      busyTextFaded: true
    };
  },
  methods: {
    toggleStatus() {
      this.$emit("toggleSelect", this.data.id);
    }
  }
};
</script>

<style lang="scss">
.worker-card-tooltip {
  position: absolute;
  //bottom: 2%;
  width: 100%;
  right: 0;
  height: 100%;
  @apply bg-white z-10 px-6 py-4 rounded-xl flex items-center;
}
</style>
