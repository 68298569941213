var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col",class:[
    _vm.stopper.stopper_end === null ? 'mt-2 mb-2' : '',
    _vm.stopper.start_location || _vm.stopper.end_location ? 'cursor-pointer' : ''
  ]},[_c('div',{staticClass:"flex flex-row items-center",class:_vm.stopper.stopper_end === null
        ? 'bg-offwhite text-darkgrey font-medium rounded-xl shadow'
        : 'bg-white py-2'},[(
        _vm.stopper.stopper_end === null && !_vm.$store.state.timetracking.loading
      )?_c('button',{staticClass:"bg-danger text-darkgrey hover:bg-dangerdark hover:shadow-dangeroutline p-0 rounded-xl leading-none flex",on:{"click":function($event){return _vm.stopTracking()}}},[_c('span',{staticClass:"typcn typcn-media-stop text-xl rounded-full p-2 text-white",on:{"click":function($event){$event.preventDefault();}}})]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" Algus: "+_vm._s(_vm.parseDate(_vm.stopper.stopper_start)))]),(_vm.stopper.stopper_end)?_c('span',{staticClass:"ml-auto mr-3"},[_vm._v(" Lõpp: "+_vm._s(_vm.parseDate(_vm.stopper.stopper_end))+" ")]):_vm._e(),(_vm.stopper.stopper_end === null)?_c('span',{staticClass:"ml-auto mr-3"},[_vm._v(_vm._s(_vm.timer))]):_vm._e()]),(_vm.stopper.stopper_end !== null)?_c('div',{staticClass:"bg-offwhite w-full h-1"}):_vm._e(),(_vm.startLocationActive)?_c('location-modal',{attrs:{"start-location":_vm.stopper.start_location,"end-location":_vm.stopper.end_location,"task-location":_vm.taskLocation},on:{"closeModal":function($event){_vm.startLocationActive = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }