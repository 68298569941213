<template>
  <div class="modal w-8/12 bg-offwhite mobile:w-full">
    <div
      class="modal-body modal-small mobile:h-full min-h-100 rounded-md flex items-center flex-col mobile:pb-12 p-4 w-full"
      v-on-clickaway:mousedown="closeModal"
      :style="{
        minWidth: screenSize,
        maxWidth: screenSize,
        minHeight: screenHeight,
        maxHeight: screenHeight
      }"
    >
      <div class="z-1 flex flex-row items-start justify-end w-full h-8">
        <div
          class="mr-1 cursor-pointer"
          v-if="totalPages > 1"
          @click="currentPage === 1 ? '' : (currentPage -= 1)"
        >
          <span class="bg-pending p-2 rounded-full">
            <span class="typcn typcn-chevron-left icon h-5 text-white"></span>
          </span>
        </div>
        <div
          class="mx-1 cursor-pointer"
          v-if="totalPages > 1"
          @click="currentPage === totalPages ? '' : (currentPage += 1)"
        >
          <span class="bg-pending p-2 rounded-full">
            <span class="typcn typcn-chevron-right icon h-5 text-white"></span>
          </span>
        </div>
        <div class="mx-1 cursor-pointer" @click="downloadPdf">
          <span class="bg-pending p-2 rounded-full">
            <span
              class="typcn typcn-download bg-pending icon h-5 text-white"
            ></span>
          </span>
        </div>
        <div class="ml-1 cursor-pointer" @click="closeModal">
          <span class="bg-danger p-2 rounded-full">
            <span
              class="typcn typcn-times bg-danger icon h-5 text-white"
            ></span>
          </span>
        </div>
      </div>
      <pdf
        :src="pdfSrc"
        :page="currentPage"
        @num-pages="initPages"
        class="w-full border-2 border-black mt-2"
      ></pdf>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  name: "PdfModal",
  components: { pdf },
  props: {
    pdfSrc: { type: String, default: "" },
    isInvoice: Boolean
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 1
    };
  },
  methods: {
    initPages(e) {
      this.totalPages = e;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    saveFile() {
      this.$emit("saveFile");
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.pdfSrc;
      if (this.isInvoice) {
        link.setAttribute("download", "arve.pdf");
      } else {
        link.setAttribute("download", "pakkumine.pdf");
      }
      document.body.appendChild(link);
      link.click();
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  computed: {
    screenSize() {
      const screen = window.innerWidth;
      if (screen < 700) {
        return screen + "px";
      } else if (screen <= 992) {
        return "40^";
      } else {
        return "35%";
      }
    },
    screenHeight() {
      const screen = window.innerHeight;
      if (screen >= 1280) {
        return "80%";
      } else {
        return "90%";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pdf {
  @apply bg-offwhite;
  min-height: 80%;
  max-height: 80%;
  min-width: 35%;
  max-width: 35%;
}
</style>
