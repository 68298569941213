<template>
  <nav
    class="w-full flex h-navbar z-40 bg-white shadow px-12 mobile:px-0 relative"
    :class="
      !$store.state.userData.user.is_email_verified &&
      $store.state.auth.status.loggedIn &&
      this.$route.name !== 'ResendActivation'
        ? 'mb-16'
        : ''
    "
  >
    <div class="w-full flex mx-auto flex-row mobile:py-3 mobile:mx-0">
      <div
        class="w-3/12 mobile:w-1/2 flex mobile:justify-between justify-start items-center mobile:px-3"
        :class="
          $store.state.auth.status.loggedIn ? 'mobile:w-1/2' : 'mobile:w-full'
        "
      >
        <router-link
          :to="logoLink"
          :class="
            logoLink === $route.path ? 'cursor-default' : 'cursor-pointer'
          "
        >
          <img
            src="/favicon.ico"
            class="mobile:h-12 h-16 p-3 mobile:p-1 mobile:flex hidden"
          />
          <img
            src="/logonotext.png"
            class="mobile:h-12 h-16 p-3 mobile:p-1 mobile:hidden"
          />
        </router-link>
        <button
          class="h-10 w-10 duration-100 hidden mobile:flex"
          :class="
            hamburgerActive
              ? 'bg-white shadow-dangeroutline focus:bg-white text-danger'
              : 'bg-green text-white focus:text-green focus:bg-white focus:shadow-outline'
          "
          v-if="
            !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
          "
          @click="hamburgerActive = !hamburgerActive"
        >
          <span
            class="typcn "
            :class="
              hamburgerActive ? 'text-3xl typcn-times' : 'typcn-th-menu text-xl'
            "
          ></span>
        </button>
      </div>

      <div class="mobile-hamburger-menu" v-if="hamburgerActive">
        <div class="nav-item">
          <router-link
            class="nav-link"
            to="/"
            @click.native="hamburgerActive = false"
            >Avaleht</router-link
          >
        </div>
        <div class="nav-item">
          <router-link
            class="nav-link"
            to="/product"
            @click.native="hamburgerActive = false"
            >Toode</router-link
          >
        </div>
        <div class="nav-item">
          <router-link
            class="nav-link"
            to="/pricing"
            @click.native="hamburgerActive = false"
            >Hinnastamine</router-link
          >
        </div>
        <div class="nav-item">
          <router-link
            class="nav-link"
            to="/about-us"
            @click.native="hamburgerActive = false"
            >Meist</router-link
          >
        </div>
        <router-link
          to="/login"
          class="font-medium p-2 rounded-xl mb-4 text-center mx-3"
          :class="
            $route.name === 'Login'
              ? 'bg-white text-green shadow-outline'
              : 'bg-green text-white shadow '
          "
          @click.native="hamburgerActive = false"
        >
          <span>Logi sisse</span>
        </router-link>
        <router-link
          to="/register"
          :class="
            $route.name === 'Register'
              ? 'bg-white text-green shadow-outline'
              : 'bg-green text-white shadow '
          "
          class="font-medium p-2 rounded-xl text-center mx-3"
          @click.native="hamburgerActive = false"
          >Registreeri</router-link
        >
      </div>
      <div
        class="flex justify-center w-6/12 h-16 mobile:hidden medium:self-center"
        v-if="!$store.state.auth.status.loggedIn && !$route.meta.isProto"
      >
        <div class="nav-item">
          <router-link class="nav-link" to="/">Avaleht</router-link>
        </div>
        <div class="nav-item">
          <router-link class="nav-link" to="/product">Toode</router-link>
        </div>
        <div class="nav-item">
          <router-link class="nav-link" to="/pricing">Hinnastamine</router-link>
        </div>
        <div class="nav-item">
          <router-link class="nav-link" to="/about-us">Meist</router-link>
        </div>
      </div>
      <div
        class="flex justify-center w-6/12 h-16 mobile:hidden medium:self-center items-center"
        v-if="$store.state.auth.status.loggedIn"
      >
        <transition name="fade" :duration="100" mode="out-in">
          <timer
            v-if="
              $store.state.timetracking.hasActiveTimer &&
                screenW &&
                screenW > 992 &&
                !$store.state.timetracking.loading
            "
          ></timer>
        </transition>
      </div>
      <div
        class="w-3/12 mobile:w-1/2 flex justify-end items-center flex-row mobile:px-3"
        v-if="
          $store.state.auth.status.loggedIn && $store.state.userData.retrieved
        "
      >
        <div class="flex items-center justify-end">
          <transition name="fade">
            <div
              class="h-10 w-10 rounded-full bg-offwhite mr-3 flex justify-center items-center hover:bg-darkgreen hover:text-white cursor-pointer"
              @click="$router.push('/calendar')"
              v-if="$route.name !== 'Calendar'"
            >
              <span
                class="typcn typcn-home h-7 w-7 leading-none text-2xl flex justify-center items-center"
              ></span>
            </div>
          </transition>
          <div
            class="rounded-full p-2 leading-none relative mr-3 h-10 w-10 flex items-center justify-center duration-100 cursor-pointer"
            :class="
              $store.state.notifications.notificationsLoaded &&
              $store.state.notifications.unread.length > 0
                ? 'bg-green hover:bg-darkgreen'
                : 'bg-offwhite hover:bg-darkgreen'
            "
            @mouseenter="notificationTrayButtonHovered = true"
            @mouseleave="notificationTrayButtonHovered = false"
            @click="toggleNotificationTray"
          >
            <span class="text-white " role="button"
              ><img
                src="/bc21/bell.svg"
                class="h-7 w-7"
                :class="
                  $store.state.notifications.unread.length > 0
                    ? 'filter-to-white'
                    : notificationTrayButtonHovered
                    ? 'filter-to-white'
                    : ''
                "
            /></span>
            <span
              role="button"
              v-if="
                $store.state.notifications.notificationsLoaded &&
                  $store.state.notifications.unread.length > 0
              "
              class="absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer"
              >{{ $store.state.notifications.unread.length }}</span
            >
          </div>
          <div
            class="flex relative cursor-pointer duration-100"
            @click="toggleUserMenu()"
            @mouseenter="userMenuButtonHovered = true"
            @mouseleave="userMenuButtonHovered = false"
          >
            <img
              class="rounded-full cursor-pointer h-12 w-12 p-1 hover:shadow-outline navbar-profile-image"
              :src="profileImage"
              data-cy="usermenu"
              v-if="
                Object.keys(this.$store.state.userData.user.image).length > 0
              "
            />
            <div
              v-else
              class="bg-offwhite h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-darkgreen"
            >
              <span class="font-medium">{{ getInitials() }}</span>
            </div>
            <div class="gear-icon">
              <img src="/bc21/gear.svg" class="filter-to-white h-3 w-3" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-auto self-center flex justify-end items-center flex-row mobile:hidden"
        v-if="
          !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
        "
      >
        <router-link to="/login" class="mobile:hidden">
          <button
            class="my-3 mx-2 self-end mobile:text-sm new-button-green p-2"
            :class="$route.name === 'Login' ? 'bg-white' : ''"
          >
            <span class="label text-lg py-1">Logi sisse</span>
          </button>
        </router-link>
        <router-link to="/register">
          <button
            class="my-3 mx-2 self-end mobile:text-sm new-button-green p-2"
          >
            <span class="label text-lg py-1">Registreeri</span>
          </button>
        </router-link>
      </div>
      <div class="navbar-menu-container">
        <notification-tray
          :active="notificationTrayActive"
          @closeTray="notificationTrayActive = false"
        ></notification-tray>
        <user-menu :active="showUserMenu"></user-menu>
      </div>
    </div>
    <div
      class="verify-email-notification"
      v-if="
        $store.state.auth.status.loggedIn &&
          !$store.state.userData.user.is_email_verified &&
          $store.state.userData.retrieved &&
          this.$route.name !== 'ResendActivation'
      "
    >
      <p>
        Et kasutada lehe funktsioone, palun kinnitage oma meilikonto. Kui te ei
        ole vastavat e-kirja saanud, vajutage
        <router-link
          to="/resend-activation"
          class="cursor-pointer text-highlightblue"
          >siia</router-link
        >.
      </p>
    </div>
  </nav>
</template>

<script>
import jwtdecode from "jwt-decode";
import NotificationTray from "./navbar/NotificationTray";
import UserMenu from "./navbar/UserMenu";
import Timer from "@/components/reusable/Timer";
export default {
  data() {
    return {
      showUserMenu: false,
      navMenuVisible: false,
      notificationTrayActive: false,
      notificationTrayButtonHovered: false,
      hamburgerActive: false,
      timer: null,
      timerInterval: null
    };
  },
  components: {
    NotificationTray,
    UserMenu,
    Timer
  },
  methods: {
    toggleNotificationTray() {
      if (this.showUserMenu) {
        this.showUserMenu = false;
      }
      this.notificationTrayActive = !this.notificationTrayActive;
    },
    toggleUserMenu() {
      if (this.notificationTrayActive) {
        this.notificationTrayActive = false;
      }
      this.showUserMenu = !this.showUserMenu;
    },
    goToNotificationTarget(notification) {
      this.$store.dispatch("notifications/readNotification", notification.id);
      this.$router.push(notification.notification_target);
    },
    getInitials() {
      let username = this.$store.state.userData.user.display_username;
      username = username.split(" ");
      username = `${username[0][0]} ${username[1][0]}`;
      return username;
    }
  },
  computed: {
    profileId() {
      if (this.$store.state.auth.status.loggedIn) {
        return jwtdecode(this.$store.state.auth.user).user_id;
      } else {
        return "";
      }
    },
    profileImage() {
      if (Object.keys(this.$store.state.userData.user.image).length === 0) {
        return "/favicon.ico";
      } else {
        return (
          process.env.VUE_APP_MEDIA_URL +
          this.$store.state.userData.user.image.image
        );
      }
    },
    logoLink() {
      if (this.$store.state.auth.status.loggedIn) {
        return "/calendar";
      } else {
        return "/";
      }
    }
  },
  watch: {
    $route() {
      this.notificationTrayActive = false;
      this.showUserMenu = false;
      this.navMenuVisible = false;
    }
  }
};
</script>

<style lang="scss">
.navbar-cont {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(239, 239, 239, 1) 100%
  );
}
.navbar-logout {
  @apply bg-white;
}
.navbar-menu-container {
  @apply absolute w-64;
  right: 2%;
  top: 100%;
}
.mobile-hamburger-menu {
  display: none;
  position: absolute;
}
@media screen and (max-width: 992px) {
  .mobile-hamburger-menu {
    @apply bg-white flex-col px-3 py-4 shadow-lg;
    top: 100%;
    left: 0%;
    height: auto;
    width: 100vw;
    display: flex;
    .nav-item {
      @apply mb-4;
    }
  }
  .navbar-menu-container {
    width: 100vw;
    right: 0%;
  }
}
</style>
