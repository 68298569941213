<template>
  <div>
    <div
      class="p-3 flex items-center hover:bg-offwhite hover:bg-opacity-50 duration-100"
    >
      <div class="w-3/12 flex mobile:w-6/12  pr-4">
        <h4 class="truncate">{{ data.title }}</h4>
      </div>
      <div
        class="w-3/12 flex justify-start mobile:w-6/12 mobile:justify-end"
        v-html="offerStatus"
      ></div>
      <div class="w-3/12 flex mobile:hidden items-center">
        <div class="flex mr-2">
          <span class="typcn typcn-user table-icon"></span>
        </div>
        <span class="truncate">{{ data.client_name }}</span>
      </div>
      <div class="w-3/12 flex mobile:hidden items-center">
        <div class="flex mr-2">
          <div
            class="border-green border-2 rounded-full flex justify-center items-center h-7 w-7 leading-none mr-2"
          >
            <img src="/bc21/placeholder.svg" class="h-4 filter-to-green" />
          </div>
        </div>

        <span class="text-sm pl-2 truncate">{{
          data.location ? data.location.long_address : "-"
        }}</span>
      </div>
    </div>
    <div class="flex w-full h-1 bg-offwhite rounded-full"></div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";

export default {
  name: "DraftDescription",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [StatusParser],

  computed: {
    offerStatus() {
      return this.parseProjectStatus(this.data.status);
    }
  }
};
</script>
