<template>
  <div class="modal">
    <div
      class="modal-body modal-vwide bg-white rounded-xl items-center flex-col cursor-pointer duration-100"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-content flex-col w-full">
        <div class="flex w-full">
          <div class="flex-col flex w-1/2">
            <h3 class="self-start">Lisa uus arve</h3>
            <h4 class="self-start" v-if="data.offer">
              Pakkumine #{{ data.offer.offer_index }}
            </h4>
            <span
              >Kuupäev:
              {{
                data.offer
                  ? moment(data.offer.created_at).format("DD.MM.YYYY")
                  : moment().format("DD.MM.YYYY")
              }}</span
            >
            <span v-if="data.offer">Hind: {{ data.offer.price }}€</span>
          </div>
        </div>
        <div class="flex flex-col w-full mobile:w-full mb-4">
          <h4>Arve kirjeldus</h4>
          <div
            v-if="detailTemplates.length > 0"
            class="flex flex-row items-center my-2"
          >
            <span class="mr-2">Vali kirjelduse mall</span>
            <select class="ml-2" v-model="details">
              <option
                v-for="detailsItem in detailTemplates"
                :key="detailsItem.id"
                :value="detailsItem.details"
              >
                {{ detailsItem.description }}
              </option>
            </select>
          </div>
          <textarea class="add-offer-input mb-3" v-model="details"></textarea>
        </div>
        <services
          :draft="
            $store.state.invoiceViewer.newInvoiceModal.data.draft
              ? $store.state.invoiceViewer.newInvoiceModal.data.draft
              : data.draft
          "
          :offer="
            $store.state.invoiceViewer.newInvoiceModal.data.offer
              ? $store.state.invoiceViewer.newInvoiceModal.data.offer
              : null
          "
          @serviceUpdated="handleServiceUpdate"
          ref="services"
          class="mb-4"
        ></services>
      </div>
      <div class="modal-bottom-bar w-full">
        <button @click="saveInvoice" class="new-button-green mr-3">
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button @click="closeModal" class="new-button-danger">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import Services from "@/components/project/invoice/modal/AddInvoiceServices";
import { directive as onClickaway } from "vue-clickaway2";
import moment from "moment";
import EventBus from "@/assets/mixins/EventBus";

export default {
  name: "AddInvoiceModal",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    activeOfferId: {
      type: Number,
      default: null
    }
  },
  mixins: [StatusParser, RequestHandler],
  data() {
    return {
      detailTemplates: [],
      tempData: null,
      newServices: [],
      moment: moment,
      invoiceId: null,
      invoiceLoaded: false,
      editMode: true,
      details: "",
      detailsLoaded: false
    };
  },
  components: {
    Services
  },
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    loadTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/",
        "get",
        true
      ).then(res => {
        if (
          res.data.filter(x => x.type === 2 && x.default === true).length === 1
        ) {
          this.details = res.data.filter(
            x => x.type === 2 && x.default === true
          )[0].details;
        }
        this.detailTemplates = res.data.filter(x => x.type === 2);
      });
    },
    addService() {
      let data = {
        index: null,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20
      };
      this.newServices.push(data);
    },
    handleServiceUpdate(e) {
      this.newServices = e.newServices;
    },
    saveInvoice() {
      const data = {};
      this.$refs.services.saveOfferItems();
      data.details = this.details;
      let sum = 0;
      for (let i = 0; i < this.$refs.services.items.length; i++) {
        sum +=
          parseFloat(this.$refs.services.items[i].price) *
          parseFloat(this.$refs.services.items[i].amount);
        if (this.$refs.services.items[i].vat === 20) {
          sum +=
            parseFloat(this.$refs.services.items[i].price) *
            parseFloat(this.$refs.services.items[i].amount) *
            0.2;
        }
      }

      for (let i = 0; i < this.$refs.services.newServices.length; i++) {
        sum +=
          parseFloat(this.$refs.services.newServices[i].price) *
          parseFloat(this.$refs.services.newServices[i].amount);

        if (this.newServices[i].vat === 20) {
          sum +=
            parseFloat(this.$refs.services.newServices[i].price) *
            parseFloat(this.$refs.services.newServices[i].amount) *
            0.2;
        }
      }
      data.price = sum.toFixed(2);
      this.apiRequest(
        "notes/draft/" + this.data.draft.uuid + "/invoice/new/",
        "post",
        true,
        data
      ).then(res => {
        let itemData = [];
        for (let i = 0; i < this.$refs.services.items.length; i++) {
          let obj = {};
          if (this.$refs.services.items[i].name.length) {
            obj.name = this.$refs.services.items[i].name;
            obj.amount = this.$refs.services.items[i].amount;
            obj.unit = this.$refs.services.items[i].unit;
            obj.price = this.$refs.services.items[i].price;
            obj.vat = this.$refs.services.items[i].vat;
            obj.invoice = res.data.invoice_id;
            itemData.push(obj);
          }
        }
        for (let i = 0; i < this.$refs.services.newServices.length; i++) {
          let obj = {};
          if (this.$refs.services.newServices[i].name.length > 0) {
            obj.name = this.$refs.services.newServices[i].name;
            obj.amount = this.$refs.services.newServices[i].amount;
            obj.unit = this.$refs.services.newServices[i].unit;
            obj.price = this.$refs.services.newServices[i].price;
            obj.vat = this.$refs.services.newServices[i].vat;
            obj.invoice = res.data.invoice_id;
            itemData.push(obj);
          }
        }
        this.apiRequest(
          "notes/draft/" +
            this.data.draft.uuid +
            "/invoice/" +
            res.data.invoice_id +
            "/items/add/",
          "post",
          true,
          itemData
        ).then(res2 => {
          if (res2.status === 201) {
            EventBus.$emit("updateInvoices");
            this.closeModal();
            this.$store.dispatch("invoiceViewer/closeAddInvoiceModal");
          }
        });
      });
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    handleRemoveService(e) {
      this.tempData.splice(
        this.tempData.findIndex(x => x.id === e),
        1
      );
    },
    closeModal() {
      this.$store.dispatch("invoiceViewer/closeAddInvoiceModal");
      this.$emit("closeModal");
    }
  },
  mounted() {
    this.loadTemplates();
  },
  computed: {}
};
</script>
