<template>
  <div
    class="fixed flex top-0 w-screen h-screen bg-darkgrey bg-opacity-50 overflow-y-hidden justify-center items-center"
    id="loadingmodal"
  >
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
#loadingmodal {
  z-index: 9999;
}
</style>
