<template>
  <div class="flex items-center">
    <select
      id="hour-selector"
      v-model="hourSelector"
      @change="updateValue()"
      class="time-selector"
      :class="isWhite ? 'bg-white' : 'bg-offwhite'"
    >
      <option value="00">00</option>
      <option value="01">01</option>
      <option value="02">02</option>
      <option value="03">03</option>
      <option value="04">04</option>
      <option value="05">05</option>
      <option value="06">06</option>
      <option value="07">07</option>
      <option value="08">08</option>
      <option value="09">09</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
    </select>
    <span class="px-1">:</span>
    <select
      id="minute-selector"
      v-if="!fullMinutes"
      v-model="minuteSelector"
      @change="updateValue()"
      class="time-selector"
      :class="isWhite ? 'bg-white' : 'bg-offwhite'"
    >
      <option value="00">
        00
      </option>
      <option value="15">
        15
      </option>
      <option value="30">
        30
      </option>
      <option value="45">
        45
      </option>
    </select>
    <select
      id="minute-selector"
      v-if="fullMinutes"
      v-model="minuteSelector"
      @change="updateValue()"
      class="time-selector"
      :class="isWhite ? 'bg-white' : 'bg-offwhite'"
    >
      <option
        v-for="minute in Array.from(Array(60).keys())"
        :value="formatMinute(minute)"
        :key="minute"
      >
        {{ formatMinute(minute) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "00:00"
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    fullMinutes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hourSelector: null,
      minuteSelector: null
    };
  },
  watch: {
    value() {
      this.hourSelector = this.value.split(":")[0];
      this.minuteSelector = this.value.split(":")[1];
    }
  },
  mounted() {
    this.initValue();
  },
  methods: {
    initValue() {
      this.hourSelector = this.value.split(":")[0];
      this.minuteSelector = this.value.split(":")[1];
    },
    updateValue() {
      this.$emit("input", this.hourSelector + ":" + this.minuteSelector);
    },
    formatMinute(minute) {
      if (String(minute).length === 1) {
        return "0" + String(minute);
      }
      return String(minute);
    }
  }
};
</script>

<style lang="scss">
.time-selector {
  @apply shadow text-center;
  &:focus {
    @apply shadow-outline;
  }
}
.ui-select {
  @apply shadow-lg;
}
/* This is to remove the arrow of select element in IE */
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
</style>
