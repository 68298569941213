<template>
  <div class="modal">
    <div class="modal-body">
      <div class="modal-content flex-col">
        <div
          class="flex items-center mb-3 mobile:justify-center mobile:flex-col"
        >
          <h3 class="mr-3 mobile:order-2">Vali projekt</h3>
          <div
            class="flex relative items-center justify-center mobile:self-center mobile:w-full mobile:order-3"
          >
            <div
              class="flex items-center justify-center w-full mobile:self-center"
            >
              <div
                class="items-center flex flex-row cursor-pointer hover:bg-offwhite hover:bg-opacity-50 duration-50 relative"
                @click="menuActive = !menuActive"
                :class="menuActive ? 'rounded-t-xl' : 'rounded-xl'"
              >
                <span
                  v-if="statusFilter"
                  v-html="parseProjectStatus(statusFilter.status)"
                />
                <span v-if="!statusFilter" class="pl-4 pr-8 py-2"
                  >Filtreeri staatuse järgi</span
                >
                <span class="typcn typcn-arrow-sorted-down mx-3"></span>
                <div class="status-menu" v-if="menuActive">
                  <span
                    v-for="status in statusFilters"
                    :key="status.status"
                    class="status-menu-item cursor-pointer"
                    v-html="parseProjectStatus(status.status)"
                    @click.stop="selectStatus(status)"
                  ></span>
                </div>
              </div>

              <div class="ml-2">
                <button
                  class="new-button-danger"
                  v-if="statusFilter"
                  @click="selectStatus(null)"
                >
                  <span class="icon"
                    ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
                  /></span>
                  <span class="label">Tühjenda</span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="ml-auto mobile:ml-0 mobile:w-full flex items-center bg-offwhite rounded-full duration-100 mobile:mb-3 mobile:order-4"
            :class="focusSearch ? 'shadow-outline' : 'shadow'"
          >
            <div class="w-1/12 px-1 items-center justify-center flex">
              <span class="">
                <img src="/bc21/search.svg" class="h-4 w-4" />
              </span>
            </div>
            <input
              type="text"
              v-model="searchQuery"
              @input="debounceSearch"
              placeholder="Otsi projekti"
              class="shadow-none focus:shadow-none hover:shadow-none rounded-full w-11/12"
              @focus="focusSearch = true"
              @blur="focusSearch = false"
            />
          </div>
        </div>
        <div v-if="projectsLoaded" class="mb-3 w-full">
          <project
            v-for="(item, index) in projects.results"
            :key="item.uuid"
            :data="item"
            :is-first="index === 0"
            :selected-project="selectedProject ? selectedProject.uuid : null"
            @projectSelected="toggleSelectedProject"
          ></project>
          <div
            v-if="projects.results.length === 0"
            class="mx-auto my-auto py-2 px-8 flex flex-col justify-center items-center mobile:w-full"
          >
            <div class="flex justify-center mb-4">
              <img src="/favicon.ico" class="h-24 w-24" />
            </div>
            <h3 class="mb-2 flex mt-auto">Selle staatusega projekte pole</h3>
          </div>
          <paginator
            class="self-center mt-4 mobile:justify-center"
            v-if="projects.count > 1"
            :page="page"
            :count="projects.count"
            @pageChange="pageChange"
          ></paginator>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button
          @click="selectedProject ? saveProject() : ''"
          class="mr-3"
          :class="selectedProject ? 'new-button-green' : 'new-button-disabled'"
        >
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import StatusParser from "@/assets/mixins/StatusParser";
import debounce from "lodash/debounce";
import Paginator from "@/components/reusable/Paginator";
import Project from "./Project";

const statusFilters = [
  {
    text: "Ootel",
    status: 1
  },
  {
    text: "Aktiivne",
    status: 2
  },
  {
    text: "Käigus",
    status: 3
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  },
  {
    text: "Kinnitatud",
    status: 6
  }
];
export default {
  name: "ProjectSelector",
  components: { Project, Paginator },
  mixins: [RequestHandler, StatusParser],
  props: {
    task: { type: Number, default: null },
    convert: { type: Boolean, default: true }
  },
  data() {
    return {
      projectsLoaded: false,
      projects: [],
      page: 1,
      searchQuery: "",
      focusSearch: false,
      statusFilter: null,
      statusFilters: [],
      menuActive: false,
      selectedProject: null
    };
  },
  methods: {
    toggleSelectedProject(project) {
      if (!this.selectedProject) {
        this.selectedProject = project;
      } else if (this.selectedProject.uuid === project.uuid) {
        this.selectedProject = null;
      } else {
        this.selectedProject = project;
      }
    },
    pageChange(e) {
      this.page = e;
      this.retrieveProjects();
    },
    selectStatus(status) {
      this.statusFilter = status;
      this.menuActive = false;
      this.retrieveProjects();
    },
    retrieveProjects() {
      this.projectsLoaded = false;
      this.apiRequest(
        "notes/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/drafts/?page_size=8&page=" +
          this.page +
          (this.searchQuery ? "&s=" + this.searchQuery : "") +
          (this.statusFilter ? "&state=" + this.statusFilter.status : ""),
        "get",
        true
      ).then(res => {
        this.projects = res.data;
        this.projectsLoaded = true;
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    debounceSearch: debounce(function() {
      this.retrieveProjects();
    }, 500),
    saveProject() {
      if (this.convert) {
        this.apiRequest(
          "tasks/" + this.task + "/convert/" + this.selectedProject.uuid + "/",
          "post",
          true
        ).then(res => {
          if (res.status === 200) {
            this.$emit("closeModal");
            this.$emit("projectAdded", res.data.task_id);
          }
        });
      } else {
        this.$emit("projectAdded", this.selectedProject);
        this.$emit("closeModal");
      }
    }
  },
  mounted() {
    this.statusFilters = statusFilters;

    this.retrieveProjects();
  }
};
</script>
