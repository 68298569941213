<template>
  <div class="fixed consent-popup bg-offwhite z-50 py-8 px-5 w-full">
    <div class="flex flex-col">
      <h2 class="mb-2">Tere tulemast Gewodo-sse!</h2>
      <span class="mb-2"
        >Kui soovid alustada oma tasuta 14-päevast Gewodo Premium-i
        testiperioodi, siis klikka all olevale nupule. Kui aga soovid enne
        baaskeskkonnaga tutvuda, siis leiad testperioodi aktiveerimise "Pakett"
        lehe alt.
      </span>
      <span class="font-medium mb-4">Head kasutamist!</span>
      <div class="flex mt-auto mobile:mb-16 mobile:flex-col">
        <button
          class="new-button-green mr-4 mobile:mr-0 mobile:mb-4"
          @click="goToTrial"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Alusta prooviperioodi</span>
        </button>
        <button class="new-button-danger" @click="closeModal">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToTrial() {
      this.$store.dispatch("companyData/closeTrialPopup");
      this.$router.push("/trial");
    },
    closeModal() {
      this.$store.dispatch("companyData/closeTrialPopup");
    }
  }
};
</script>
