<template>
  <div class="dashboard-sidebar-cont">
    <transition name="fade" :duration="{ enter: 300, leave: 20 }">
      <div
        class="dashboard-sidebar"
        v-if="expanded && $store.state.companyData.retrieved"
      >
        <div
          class="flex flex-col justify-center items-center text-lg font-semibold w-full"
        >
          <div
            class="flex flex-row justify-start items-center relative w-full py-3 mobile:order-2"
          >
            <span
              class="break-all px-3"
              v-if="$store.state.companyData.retrieved"
              >{{ $store.state.companyData.activeCompany.company_name }}</span
            >
            <span
              class="typcn typcn-arrow-sorted-down company-selector-button"
              @click="companySelectorActive = !companySelectorActive"
              :class="
                companySelectorActive ? 'company-selector-button-active' : ''
              "
            ></span>
          </div>
          <div
            class="company-selector w-full pb-2 px-2 mobile:order-1 mobile:pt-3"
            v-if="companySelectorActive"
          >
            <div class="flex flex-col items-center rounded-gwdhalf bg-offwhite">
              <span class="text-lg text-center pt-2">Vali aktiivne firma</span>
              <span class="heading-underline-small mb-2"></span>
              <div
                class="w-full hover:bg-darkgreen hover:text-white flex justify-center items-center py-2 cursor-pointer px-2"
                v-for="(item, index) in $store.state.companyData.companies"
                :key="item.uuid"
                :class="[
                  $store.state.companyData.activeCompany.uuid === item.uuid
                    ? 'text-green'
                    : '',
                  index === $store.state.companyData.companies.length - 1
                    ? 'rounded-b-gwdhalf'
                    : ''
                ]"
                @click="changeActiveCompany(item.uuid)"
              >
                <span class="truncate">{{ item.company_name }}</span>
              </div>
            </div>
          </div>
        </div>
        <sidebar-link
          target="/calendar"
          icon="/bc21/calendar.svg"
          text="Kalender"
        ></sidebar-link>
        <sidebar-link
          target="/projects"
          icon="/bc21/clipboard.svg"
          text="Projektid"
        ></sidebar-link>
        <sidebar-link
          target="/workers"
          icon="/bc21/töötajad.svg"
          text="Töötajad"
          :permissions="['R0', 'R1', 'R2', 'R3']"
        ></sidebar-link>
        <sidebar-link
          :target="
            `/workers/${$store.state.companyData.activeCompany.worker_id}`
          "
          icon="/bc21/töötajad.svg"
          text="Minu profiil"
          :permissions="['R5']"
        ></sidebar-link>
        <sidebar-link
          target="/clients"
          icon="/bc21/kliendid.svg"
          text="Kliendid"
          :permissions="['R0', 'R1', 'R2']"
        ></sidebar-link>
        <sidebar-link
          target="/documents"
          icon="/bc21/dokumendid.svg"
          text="Dokumendid"
          :permissions="['R0', 'R1', 'R2']"
        ></sidebar-link>
        <sidebar-link
          target="/offers"
          icon="/bc21/project.svg"
          text="Pakkumised"
          :permissions="['R0', 'R1', 'R2']"
          v-if="
            $store.state.companyData.activeCompany.subscription_permissions
              .invoices
          "
        ></sidebar-link>
        <sidebar-link
          target="/invoices"
          icon="/pricing/inv.svg"
          text="Arved"
          :permissions="['R0', 'R1', 'R2']"
          v-if="
            $store.state.companyData.activeCompany.subscription_permissions
              .invoices
          "
        ></sidebar-link>
        <sidebar-link
          target="/templates"
          icon="/icons/templates/templates.svg"
          text="Mallid"
          :permissions="['R0', 'R1', 'R2']"
          v-if="
            $store.state.companyData.activeCompany.subscription_permissions
              .invoices
          "
        ></sidebar-link>
        <sidebar-link
          target="/todo"
          icon="/bc21/märkmik.svg"
          text="Märkmik"
          :permissions="['R0', 'R1', 'R2']"
        ></sidebar-link>
        <sidebar-link
          target="/subscriptions"
          icon="/icons/logonohighlight.png"
          text="Pakett"
          :permissions="['R0', 'R1', 'R2']"
          class="mb-2"
        ></sidebar-link>
        <div
          class="flex w-full mobile:mt-4"
          v-if="
            ['R0', 'R1', 'R2'].includes(
              this.$store.state.companyData.activeCompany.worker_permission
            )
          "
        >
          <router-link
            to="/projects/new"
            class="flex w-full justify-start px-2 add-project-button"
          >
            <button class="new-button-green w-full">
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa projekt</span>
            </button>
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SidebarLink from "@/components/reusable/SidebarLink";
export default {
  name: "CompanyNavbar",
  components: { SidebarLink },
  props: {
    step: {
      type: Number,
      default: null
    },
    showOffers: Boolean,
    showWorkers: Boolean,
    showFiles: Boolean,
    expanded: Boolean
  },
  data() {
    return {
      companySelectorActive: false,
      settingsHovered: false,
      settingsTabHovered: false
    };
  },
  methods: {
    toggleStep(stepToGoTo) {
      if (this.$route.name === "CompanyView") {
        this.$emit("stepChange", stepToGoTo);
        if (this.step === 3) {
          this.$emit("checkDocumentPageState");
        }
        if (this.step === 5) {
          this.$emit("checkWorkerPageState");
        }
        if (this.step === 8) {
          this.$emit("checkClientPageState");
        }
      } else {
        this.$router.push("/calendar?v=" + stepToGoTo);
      }
    },
    changeActiveCompany(companyId) {
      this.$store.dispatch("companyData/activateCompany", companyId);
      this.$emit("activeCompanyChanged");
    }
  }
};
</script>
