<template>
  <div class="modal">
    <div
      class="modal-body modal-medium mobile:p-0 relative z-10"
      v-on-clickaway:mousedown="closeModal"
    >
      <transition name="fade" :duration="200"
        ><div class="flex flex-col mobile:p-0 modal-content" v-if="taskLoaded">
          <div class="flex mb-4 mobile:flex-col">
            <div
              class="flex w-3/5 flex-col items-start pr-6 mobile:w-full mobile:pr-0"
            >
              <div
                class="flex flex-row items-center justify-between w-full mb-5"
              >
                <div class="flex items-center w-full flex-wrap">
                  <h3
                    v-if="!canEditStatus"
                    class="break-all mr-2"
                    :class="
                      ['R0', 'R1', 'R2'].includes(userRole)
                        ? 'cursor-pointer hover:text-darkgreen'
                        : ''
                    "
                    @click="
                      ['R0', 'R1', 'R2'].includes(userRole)
                        ? (editables.title.editing = true)
                        : null
                    "
                  >
                    {{ taskData.title }}
                  </h3>
                  <div class="flex pr-2 w-8/12 mobile:w-full">
                    <textarea
                      v-if="canEditStatus"
                      v-model="editables.title.content"
                      v-autogrow
                      rows="1"
                      :disabled="saving"
                      @input="debounceSaveChanges"
                      class="rounded-none text-xl font-semibold  w-full  border-b-2 border-green focus:border-lightgreen bg-transparent shadow-none focus:shadow-none p-1 overflow-y-hidden"
                    />
                  </div>
                  <div
                    class="flex relative w-4/12 mobile:w-full justify-center items-center mobile:justify-start z-10"
                  >
                    <div
                      class="justify-start flex w-full"
                      :class="[
                        canEditStatus ? 'cursor-pointer hover:bg-offwhite' : '',
                        statusMenuOpen
                          ? 'shadow rounded-none rounded-t-xl'
                          : 'rounded-xl'
                      ]"
                      @click="
                        canEditStatus ? (statusMenuOpen = !statusMenuOpen) : ''
                      "
                    >
                      <span v-html="parseTaskStatus(taskData.status)"></span>
                      <span
                        class="typcn typcn-arrow-sorted-down ml-auto transform duration-100 flex self-center mr-4 leading-none"
                        v-if="canEditStatus"
                        :class="statusMenuOpen ? 'rotate-180' : ''"
                      ></span>
                    </div>
                    <task-status-selector
                      v-if="statusMenuOpen"
                      :task-data="taskData"
                      :user-role="userRole"
                      @changeStatus="changeTaskStatus"
                      @endTask="confirmEndTaskModalActive = true"
                    ></task-status-selector>
                  </div>
                </div>
              </div>
              <div
                v-if="taskData.related_object"
                @click="goToProject"
                class="w-full"
              >
                <div
                  class="flex items-center leading-none mb-4 rounded-xl w-full mobile:bg-offwhite hover:bg-offwhite hover:bg-opacity-50 text-medium cursor-pointer duration-150"
                >
                  <span
                    class="p-2 rounded-xl shadow-sm bg-darkgrey text-white mr-2"
                  >
                    <img src="/bc21/project.svg" class="h-4 w-4 filter-to-white"
                  /></span>

                  <span class="flex truncate items-center h-8">{{
                    taskData.related_object.object_name
                  }}</span>
                </div>
              </div>
              <div
                v-if="!taskData.related_object"
                @click="
                  !$store.state.companyData.isClient &&
                  ['R0', 'R1', 'R2', 'R3'].includes(
                    $store.state.companyData.activeCompany.worker_permission
                  )
                    ? (projectSelectorActive = true)
                    : (projectSelectorActive = false)
                "
                class="w-full"
              >
                <div
                  class="flex items-center leading-none mb-4 rounded-xl w-full mobile:bg-offwhite hover:bg-offwhite hover:bg-opacity-75 text-medium cursor-pointer duration-150"
                >
                  <span
                    class="p-2 rounded-xl shadow-sm bg-darkgrey text-white mr-2"
                  >
                    <img src="/bc21/project.svg" class="h-4 w-4 filter-to-white"
                  /></span>
                  <span class="disabled">Vali projekt</span>
                </div>
              </div>

              <div
                class="flex items-center leading-none mb-4 rounded-xl w-full mobile:bg-offwhite duration-150"
                v-if="!locationEdit"
                :class="
                  !canEdit
                    ? ''
                    : 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer'
                "
              >
                <div class="flex">
                  <a
                    :href="
                      `https://maps.google.com/?q=${taskData.location.latitude},${taskData.location.longitude}`
                    "
                    target="_blank"
                    class="flex"
                    v-if="taskData.location"
                  >
                    <span
                      class="p-2 rounded-xl shadow-sm bg-darkgrey text-white hover:bg-green"
                    >
                      <img
                        src="/bc21/placeholder.svg"
                        class="h-4 w-4 filter-to-white"
                    /></span>
                  </a>

                  <span
                    class="p-2 rounded-xl shadow-sm bg-darkgrey text-white hover:bg-green"
                    v-else
                  >
                    <img
                      src="/bc21/placeholder.svg"
                      class="h-4 w-4 filter-to-white"
                  /></span>
                </div>
                <div
                  class="flex flex-grow pl-2 mobile:w-10/12"
                  @click="locationEdit ? '' : changeLocationEdit()"
                  @mouseenter="canEdit ? (locationHovered = true) : ''"
                  @mouseleave="canEdit ? (locationHovered = false) : ''"
                >
                  <span
                    class="truncate max-w-full"
                    :class="
                      taskData.location &&
                      Object.keys(taskData.location).length === 0
                        ? 'disabled'
                        : ''
                    "
                  >
                    {{
                      taskData.location &&
                      Object.keys(taskData.location).length === 0
                        ? $store.state.isClient
                          ? "Asukoht määramata"
                          : "Vali asukoht"
                        : taskData.location.long_address
                    }}</span
                  >
                </div>

                <span
                  class="square-button-pending ml-auto mobile:hidden"
                  v-if="!locationEdit && locationHovered"
                >
                  <span class="typcn typcn-edit"></span>
                </span>
              </div>
              <div
                class="flex items-center leading-none mb-4 rounded-xl w-full cursor-pointer duration-150"
                v-if="locationEdit"
              >
                <div class="flex w-full items-center mobile:flex-col">
                  <location-inaddress
                    @locationChanged="handleLocationChange"
                    class="w-full mobile:mb-2"
                    :location="
                      taskData.location ? taskData.location.long_address : null
                    "
                    :show-delete-text="false"
                  >
                  </location-inaddress>
                  <div
                    class="flex items-center ml-2 mobile:justify-end mobile:ml-0 mobile:w-full mobile:mt-2"
                  >
                    <button
                      class="square-button-green h-8 w-8 mobile:w-auto"
                      @click="saveLocation"
                    >
                      <span class="typcn typcn-tick icon"></span>
                      <span class="label hidden mobile:flex">Salvesta</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center leading-none mb-4 rounded-xl w-full mobile:bg-offwhite duration-150"
                :class="
                  canEdit
                    ? 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer'
                    : ''
                "
                @click="canEdit ? (timeEdit = !timeEdit) : ''"
                @mouseenter="canEdit ? (timeHovered = true) : ''"
                @mouseleave="canEdit ? (timeHovered = false) : ''"
              >
                <span
                  class="p-2 rounded-xl shadow-sm bg-darkgrey text-white mr-2"
                >
                  <img src="/bc21/calendar.svg" class="h-4 w-4 filter-to-white"
                /></span>
                <span>{{ date }}</span>
                <span
                  class="square-button-pending ml-auto"
                  v-if="!timeEdit && timeHovered"
                >
                  <span class="typcn typcn-edit"></span>
                </span>
                <span
                  class="square-button-danger ml-auto mobile:hidden"
                  v-if="timeEdit"
                >
                  <span class="typcn typcn-times"></span>
                </span>
              </div>
              <div
                class="flex flex-row mobile:flex-col items-centedangerng-none bg-white w-full mb-6"
                v-if="canEdit && timeEdit"
              >
                <div
                  class="flex flex-col items-start w-full pr-4 mobile:pr-0 mobile:mb-4"
                >
                  <h4 class="mb-2">Kuupäev</h4>
                  <date-picker
                    class="taskmodal-datepicker"
                    :monday-first="true"
                    :disabled-dates="disabledDates"
                    :format="formatDate"
                    :language="ee"
                    v-model="editables.time.startDate"
                  ></date-picker>
                </div>

                <div
                  class="flex items-start flex-col w-full mobile:pl-0 pl-4 mobile:mb-3"
                >
                  <h4 class="mb-2">Kellaaeg</h4>
                  <div class="flex flex-row items-center">
                    <time-selector
                      :value="editables.time.startTime"
                      v-model="editables.time.startTime"
                      class="mb-1"
                    ></time-selector>
                    <span class="mx-2 font-semibold">-</span>
                    <time-selector
                      :value="editables.time.endTime"
                      v-model="editables.time.endTime"
                    ></time-selector>
                  </div>
                </div>
              </div>
              <div class="flex items-center mb-1">
                <h4 class="mr-2">Kirjeldus</h4>
              </div>
              <div
                class="bg-white mb-6 mobile:mb-4 flex max-w-full"
                v-if="!canEdit"
              >
                <p class="max-w-full">{{ taskData.description }}</p>
              </div>
              <div class="flex flex-col w-full mb-6 mobile:mb-4" v-if="canEdit">
                <textarea
                  ref="descriptionField"
                  class="shadow focus:shadow-outline"
                  v-model="editables.description.content"
                  :rows="addingWorkers ? 3 : 7"
                ></textarea>
              </div>
              <div v-if="!addingWorkers" class="w-full mobile:mb-3">
                <task-todos
                  :task="data.task"
                  :todos="todos"
                  :task-status="taskData.status"
                  :user-role="userRole"
                  @deleteTodo="deleteTodo"
                >
                </task-todos>
              </div>
            </div>
            <div class="w-2/5 mobile:w-full flex flex-col pl-4 mobile:pl-0">
              <div class="flex self-end mb-4 items-center">
                <saving-loader :saving="saving" :saved="saved"></saving-loader>
              </div>
              <div class="flex items-center justify-between mb-2">
                <h4 v-if="!addingWorkers">Töötajad</h4>
                <button
                  @click="addingWorkers = true"
                  class="alt-button-pending"
                  v-if="
                    $store.state.companyData.isClient
                      ? false
                      : !addingWorkers &&
                        !$store.state.companyData.isClient &&
                        canAddWorkers &&
                        [1, 2, 3, 4].includes(taskData.status) &&
                        $store.state.companyData.activeCompany
                          .subscription_permissions.client_portal
                  "
                >
                  <span class="icon typcn typcn-edit"></span>
                  <span class="label">Muuda</span>
                </button>
              </div>
              <span
                v-if="
                  $store.state.companyData.isClient
                    ? taskData.workers.length === 0
                    : taskData.workers.length === 0 &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                "
                class="mb-2"
                >Ülesandele pole töötajaid määratud</span
              >
              <div
                class="flex flex-col mb-4"
                v-if="
                  $store.state.companyData.isClient
                    ? false
                    : !$store.state.companyData.activeCompany
                        .subscription_permissions.client_portal &&
                      $store.state.companyData.activeCompany
                        .worker_permission === 'R0'
                "
              >
                <span class="mb-4">
                  {{
                    `Selleks, et kasutada töötajahalduse funktsiooni, soetage
                Gewodo Premium pakett${
                  $store.state.companyData.isClient
                    ? " või aktiveerige prooviperiood."
                    : "."
                } `
                  }}
                </span>
                <button @click="goToSub" class="new-button-green mb-4">
                  <div class="rounded-full p-1 h-8 w-8">
                    <img
                      src="/icons/logonohighlight.png"
                      class="filter-to-white"
                    />
                  </div>
                  <span class="label">Uuenda paketti</span>
                </button>
                <router-link
                  v-if="
                    $store.state.companyData.isClient
                      ? true
                      : !$store.state.companyData.activeCompany
                          .trial_period_used
                  "
                  to="/trial"
                  class="w-full"
                >
                  <button class="new-button-green w-full">
                    <div class="rounded-full p-1 h-8 w-8">
                      <img
                        src="/icons/logonohighlight.png"
                        class="filter-to-white"
                      />
                    </div>
                    <span class="label">Aktiveeri Premium prooviperiood</span>
                  </button>
                </router-link>
              </div>
              <div
                v-if="
                  $store.state.companyData.isClient
                    ? true
                    : taskData.workers.length > 0 &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal &&
                      !addingWorkers
                "
                class="flex flex-col w-full mb-4"
              >
                <employee-time-card
                  v-for="worker in taskData.workers"
                  :key="worker.id"
                  :worker="worker"
                  :task-location="taskData.location"
                  :times="
                    timerData.filter(
                      x =>
                        x.worker === worker.profile &&
                        x.worker_name === worker.profile_name
                    )
                  "
                  :user-role="userRole"
                ></employee-time-card>
              </div>
              <div class="flex flex-col mb-4" v-if="addingWorkers">
                <worker-selector
                  :existing-workers="taskData.workers"
                  :start-time="startTimeFormatted"
                  :end-time="endTimeFormatted"
                  :task-id="data.task"
                  @workersUpdated="genWorkerArrays"
                  @workersRetrieved="workersRetrieved"
                  ref="workerSelector"
                ></worker-selector>
                <div class="flex justify-around">
                  <button @click="saveWorkers" class="new-button-green p-2">
                    <span class="icon typcn typcn-tick"></span>
                    <span class="label">Salvesta</span>
                  </button>
                  <button
                    class="new-button-danger p-2"
                    @click="addingWorkers = false"
                  >
                    <span class="icon typcn-times typcn"></span>
                    <span class="label">Tühista</span>
                  </button>
                </div>
              </div>
              <transition name="fade-in" :duration="100" mode="out-in">
                <div
                  v-if="
                    !$store.state.companyData.isClient &&
                      taskStoppers.filter(x => x.stopper_end === null).length >
                        0 &&
                      !$store.state.timetracking.loading &&
                      stoppersInited
                  "
                  class="items-center justify-center mb-2"
                >
                  <h4>Minu tööaeg</h4>
                  <div class="flex flex-col">
                    <stopper
                      v-for="stopperItem in taskStoppers.filter(
                        x => x.stopper_end === null
                      )"
                      :key="stopperItem.id"
                      :stopper="stopperItem"
                      :task-location="taskData.location"
                    ></stopper>
                  </div>
                </div>
                <div
                  v-if="
                    !$store.state.companyData.isClient &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal &&
                      ($store.state.timetracking.loading || !stoppersInited)
                  "
                >
                  <clip-loader></clip-loader>
                </div>
                <div
                  v-if="
                    $store.state.companyData.isClient
                      ? false
                      : taskData.workers.find(
                          x => x.profile === $store.state.userData.user.id
                        ) !== undefined &&
                        taskStoppers.filter(x => x.stopper_end === null)
                          .length === 0 &&
                        $store.state.companyData.activeCompany
                          .subscription_permissions.client_portal &&
                        !$store.state.timetracking.loading &&
                        stoppersInited
                  "
                  class="mb-6 flex"
                >
                  <transition name="fade" :duration="100" mode="out-in">
                    <button
                      v-if="
                        [1, 2, 3, 4].includes(taskData.status) &&
                          !$store.state.timetracking.hasActiveTimer
                      "
                      @click="startStopper"
                      class="new-button-green"
                    >
                      <span
                        class="typcn typcn-media-play icon"
                        @click.prevent
                      ></span>
                      <span class="label" @click.prevent
                        >Alusta aja mõõtmist</span
                      >
                    </button>
                  </transition>
                </div>
              </transition>

              <task-notes
                class="hidden"
                v-if="notesLoaded"
                :task="data.task"
                :notes="notes"
                :can-edit="canEdit"
                @noteAdded="retrieveTaskNotes"
              >
              </task-notes>
              <task-files
                v-if="filesLoaded"
                :task="data.task"
                :files="files"
                :draft="
                  taskData.related_object ? taskData.related_object : null
                "
                :company="data.company"
                :task-status="taskData.status"
                @loadFiles="retrieveTaskFiles"
                @fileDeleted="retrieveTaskFiles"
                @deleteFile="deleteFile"
                class="mb-6"
                :class="addingWorkers ? 'mobile:hidden' : ''"
              ></task-files>
              <h4
                class="mb-2"
                :class="addingWorkers ? 'mobile:hidden' : ''"
                v-if="
                  $store.state.companyData.isClient
                    ? false
                    : canEdit &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                "
              >
                Nähtavus
              </h4>
              <div
                class="flex flex-row items-center mb-2 w-full"
                :class="addingWorkers ? 'mobile:hidden' : ''"
                v-if="
                  $store.state.companyData.isClient
                    ? false
                    : canEdit &&
                      taskData.related_object &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                "
              >
                <span class="w-8/12">Nähtav kliendile</span>
                <base-checkbox
                  :base-val="editables.visibility.visible_to_client"
                  @valueChanged="
                    e => {
                      editables.visibility.visible_to_client = e;
                    }
                  "
                ></base-checkbox>
              </div>
              <div
                class="flex flex-row items-center w-full mb-2"
                :class="addingWorkers ? 'mobile:hidden' : ''"
                v-if="
                  $store.state.companyData.isClient
                    ? false
                    : canEdit &&
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                "
              >
                <span class="w-8/12">Nähtav igale töötajale</span>
                <base-checkbox
                  :base-val="editables.visibility.is_public"
                  @valueChanged="
                    e => {
                      editables.visibility.is_public = e;
                    }
                  "
                ></base-checkbox>
              </div>
            </div>
          </div></div
      ></transition>

      <div class="modal-bottom-bar" :class="taskLoaded ? '' : 'mt-auto'">
        <button class="new-button-danger mr-3" @click="closeModal">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
        <button
          class="new-button-danger"
          @click="confirmDeleteActive = true"
          v-if="taskLoaded && !addingWorkers && canEdit"
        >
          <span class="icon">
            <img src="/bc21/trash.svg" class="h-5 w-5 filter-to-white"
          /></span>
          <span class="label">Kustuta ülesanne</span>
        </button>
      </div>
      <project-selector
        v-if="projectSelectorActive"
        :task="data.task"
        @closeModal="projectSelectorActive = false"
        @projectAdded="projectAdded"
      >
      </project-selector>
      <confirm-modal
        v-if="confirmDeleteActive"
        text="Olete kindel, et soovite valitud ülesande kustutada?"
        @confirm="deleteTask()"
        @closeModal="confirmDeleteActive = false"
      ></confirm-modal>
      <confirm-modal
        v-if="confirmEndTaskModalActive"
        text="Olete kindel, et soovite valitud ülesande lõpetada? Peale selle staatuse valimist ei saa ülesandega seotud tegevusi enam teha."
        @confirm="changeTaskStatus(6)"
        @closeModal="confirmEndTaskModalActive = false"
      >
      </confirm-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WorkerSelector from "./WorkerSelector";
import { directive as onClickaway } from "vue-clickaway2";
import { TextareaAutogrowDirective } from "vue-textarea-autogrow-directive";
import EmployeeTimeCard from "./EmployeeTimeCard";
import Stopper from "./Stopper";
import StatusParser from "@/assets/mixins/StatusParser.js";
import DatePicker from "vuejs-datepicker";
import TimeSelector from "@/components/reusable/TimeSelector";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import SavingLoader from "@/components/reusable/SavingLoader";
import TaskStatusSelector from "@/components/reusable/TaskStatusSelector";
import debounce from "lodash/debounce";
import { ee } from "vuejs-datepicker/dist/locale";
import TaskNotes from "@/components/tasks/TaskNotes";
import TaskFiles from "@/components/tasks/TaskFiles";
import TaskTodos from "@/components/tasks/todo/TaskTodos";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import ProjectSelector from "@/components/tasks/ProjectSelector";
import EventBus from "@/assets/mixins/EventBus";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: {
    userRole: { type: String, default: "R6" },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      draft: null,
      draftLoaded: false,
      addingWorkers: false,
      taskLoaded: false,
      workersToAdd: [],
      workersToRemove: [],
      moment: moment,
      ee: ee,
      taskData: null,
      taskStoppers: [],
      conflictingWorkers: [],
      selectableWorkers: [],
      stopper: null,
      stopperTimers: [],
      stoppersInited: false,
      statusMenuOpen: false,
      saving: false,
      saved: false,
      timeHovered: false,
      savedTimeout: null,
      confirmDeleteActive: false,
      confirmEndTaskModalActive: false,
      preventSave: false,
      timerData: [],
      editables: {
        inited: false,
        description: {
          editing: false,
          content: null,
          originalContent: null
        },
        visibility: {
          is_public: false,
          visible_to_client: false
        },
        time: {
          editing: false,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          originalObj: {
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null
          }
        },
        location: null,
        title: {
          editing: false,
          content: null,
          originalContent: null
        }
      },
      notes: [],
      notesLoaded: false,
      files: [],
      filesLoaded: false,
      todos: [],
      timeEdit: false,
      locationEdit: false,
      projectSelectorActive: false,
      locationHovered: false,
      position: null
    };
  },
  components: {
    ProjectSelector,
    LocationInaddress,
    TaskTodos,
    TaskFiles,
    TaskNotes,
    WorkerSelector,
    EmployeeTimeCard,
    Stopper,
    DatePicker,
    TimeSelector,
    ConfirmModal,
    SavingLoader,
    TaskStatusSelector,
    ClipLoader
  },
  mixins: [StatusParser],
  methods: {
    projectAdded(newTaskId) {
      this.$store.dispatch("taskViewer/closeTaskModal").then(() => {
        EventBus.$emit("updateTasks");
        this.$store
          .dispatch("taskViewer/openTaskModal", {
            task: newTaskId,
            company: this.data.company
          })
          .then(() => {
            this.$store.dispatch("timetracking/retrieveTimers");
          });
      });
    },
    changeLocationEdit() {
      if (this.canEdit) {
        this.locationEdit = !this.locationEdit;
      }
    },
    handleLocationChange(e) {
      this.editables.location = e;
    },
    saveLocation() {
      this.locationEdit = false;
    },
    getData() {
      this.retrieveTask(true);
      this.retrieveTaskNotes();
      this.retrieveTaskFiles();
    },
    deleteFile(e) {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/delete/",
        "delete",
        true,
        {
          document_id_list: [e.id]
        }
      ).then(res => {
        if (res.status === 200) {
          this.retrieveTaskFiles();
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
        }
      });
    },
    deleteTodo(e) {
      this.apiRequest(
        "tasks/" + this.data.task + "/todos/" + e + "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.retrieveTaskTodos();
        }
      });
    },
    retrieveTaskNotes() {
      this.notesLoaded = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url = "tasks/" + this.data.task + "/client/notes/";
      } else {
        url = "tasks/" + this.data.task + "/notes/";
      }
      this.apiRequest(url, "get", true).then(res => {
        if (res.status === 200) {
          this.notes = res.data;
          this.notesLoaded = true;
        }
      });
    },
    retrieveTaskFiles() {
      this.filesLoaded = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url = "tasks/" + this.data.task + "/client/files/";
      } else {
        url = "tasks/" + this.data.task + "/files/";
      }

      this.apiRequest(url, "get", true).then(res => {
        if (res.status === 200) {
          this.files = res.data;
          this.filesLoaded = true;
        }
      });
    },
    retrieveTask(full) {
      if (this.$store.state.companyData.isClient) {
        this.apiRequest(
          "tasks/" + this.$route.params.projectId + "/" + this.data.task + "/",
          "get",
          true
        ).then(res => {
          this.taskData = res.data;
          this.initEditables();
          this.taskLoaded = true;
        });
      } else {
        if (full) {
          this.taskLoaded = false;
          this.retrieveTimers();
        }
        this.apiRequest("tasks/" + this.data.task + "/", "get", true).then(
          res => {
            this.taskData = res.data;
            if (this.taskData.related_object) {
              this.retrieveDraft();
            }
            if (full) {
              this.initEditables();
            }
            this.taskLoaded = true;
          }
        );
      }
    },
    retrieveTimers() {
      this.stoppersInited = false;
      this.apiRequest(`tasks/${this.data.task}/timers/`, "get", true).then(
        res => {
          this.timerData = res.data;
          if (res.data.length > 0) {
            this.taskStoppers = res.data.filter(
              x => x.worker === this.$store.state.userData.user.id
            );
            for (let i = 0; i < this.taskStoppers.length; i++) {
              if (this.taskStoppers[i].stopper_end === null) {
                this.stopperTimers.push(
                  setInterval(() => {
                    this.taskStoppers[i].time_elapsed = moment

                      .utc(
                        moment
                          .duration(
                            moment().diff(
                              moment(this.taskStoppers[i].stopper_start)
                            )
                          )
                          .asMilliseconds()
                      )
                      .format("HH:mm:ss");
                  }, 1000)
                );
              }
            }
            this.stoppersInited = true;
          } else {
            this.stoppersInited = true;
          }
        }
      );
    },
    retrieveDraft() {
      this.apiRequest(
        "company/drafts/" + this.taskData.related_object.object_uuid,
        "get",
        true
      ).then(res => {
        this.draft = res.data;
        this.draftLoaded = true;
      });
    },
    closeModal() {
      this.$store.dispatch("taskViewer/closeTaskModal");
    },
    genWorkerArrays(e) {
      this.selectableWorkers = e;
      this.workersToAdd = [];
      this.workersToRemove = [];
      for (let i = 0; i < this.taskData.workers.length; i++) {
        if (!e.includes(this.taskData.workers[i].id)) {
          this.workersToRemove.push(this.taskData.workers[i].id);
        }
      }
      for (let i = 0; i < e.length; i++) {
        if (
          this.taskData.workers.find(item => item.id === e[i]) === undefined
        ) {
          this.workersToAdd.push(e[i]);
        }
      }
    },
    saveWorkers() {
      this.apiRequest("tasks/" + this.data.task + "/workers/", "post", true, {
        add_workers: this.workersToAdd,
        remove_workers: this.workersToRemove
      }).then(res => {
        if (res.status === 200) {
          this.retrieveTask(true);
          this.addingWorkers = false;
        }
      });
    },
    parseDate(time) {
      return moment(time).format("HH:mm");
    },
    parseElapsedTime(item) {
      return item.time_elapsed;
    },
    startStopper() {
      this.$store.dispatch("timetracking/startTimer", this.data.task);
    },
    initEditables() {
      this.editables.inited = false;
      this.editables.description.content = this.taskData.description;
      this.editables.description.originalContent = this.taskData.description;

      this.editables.time.startDate = moment(this.taskData.start_time).toDate();
      this.editables.time.startTime = moment(this.taskData.start_time).format(
        "HH:mm"
      );
      this.editables.time.endDate = moment(this.taskData.end_time).toDate();
      this.editables.time.endTime = moment(this.taskData.end_time).format(
        "HH:mm"
      );
      this.editables.time.originalObj.startDate = moment(
        this.taskData.start_time
      ).toDate();
      this.editables.time.originalObj.startTime = moment(
        this.taskData.start_time
      ).format("HH:mm");
      this.editables.time.originalObj.endDate = moment(
        this.taskData.end_time
      ).toDate();
      this.editables.time.originalObj.endTime = moment(
        this.taskData.end_time
      ).format("HH:mm");
      this.editables.title.content = this.taskData.title;
      this.editables.title.originalContent = this.taskData.title;
      this.editables.visibility.is_public = this.taskData.is_public;
      this.editables.visibility.visible_to_client = this.taskData.visible_to_client;
      this.editables.inited = true;
      this.preventSave = false;
    },
    editTask() {
      this.saved = false;

      this.saving = true;
      let self = this;
      if (this.savedTimeout) {
        clearTimeout(this.savedTimeout);
        this.savedTimeout = null;
      }
      const data = {};
      if (this.editables.description.content !== this.taskData.description) {
        data.description = this.editables.description.content;
      }
      if (this.startTimeFormatted !== this.taskData.start_time) {
        data.start_time = this.startTimeFormatted;
      }
      if (this.endTimeFormatted !== this.taskData.end_time) {
        data.end_time = this.endTimeFormatted;
      }
      if (this.editables.title.content !== this.taskData.title) {
        data.title = this.editables.title.content;
      }
      if (this.editables.visibility.is_public !== this.taskData.is_public) {
        data.is_public = this.editables.visibility.is_public;
      }
      if (
        this.taskData.related_object &&
        this.taskData.visible_to_client !==
          this.editables.visibility.visible_to_client
      ) {
        data.visible_to_client = this.editables.visibility.visible_to_client;
      }
      if (this.editables.location) {
        if (
          this.taskData.location.long_address !==
          this.editables.location.ipikkaddress
        ) {
          data.location = this.editables.location;
          this.editables.location = null;
          this.locationEdit = false;
        } else if (Object.keys(this.taskData.location).length === 0) {
          data.location = this.editables.location;
          this.editables.location = null;
          this.locationEdit = false;
        } else {
          data.location = "";
          this.editables.location = null;
          this.locationEdit = false;
        }
      }
      if (Object.keys(data).length > 0) {
        this.apiRequest(
          "tasks/" + this.data.task + "/edit/",
          "patch",
          true,
          data
        ).then(res => {
          if (res.status === 200) {
            this.editables.description.editing = false;
            this.editables.time.editing = false;
            this.editables.title.editing = false;
            this.saving = false;
            this.retrieveTask(false);
            this.saved = true;
            EventBus.$emit("updateTasks");
            this.savedTimeout = setTimeout(function() {
              self.saved = false;
            }, 5000);
          }
        });
      } else {
        this.savedTimeout = setTimeout(function() {
          self.saved = false;
        }, 5000);
        this.editables.description.editing = false;
        this.editables.time.editing = false;
        this.editables.title.editing = false;
        this.saving = false;
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    deleteTask() {
      this.apiRequest(
        "tasks/" + this.taskData.id + "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          EventBus.$emit("updateTasks");
          this.closeModal();
        }
      });
    },
    debounceSaveChanges: debounce(function() {
      if (this.editables.inited === true && this.canEdit) {
        this.editTask();
      }
    }, 1000),
    changeTaskStatus(status) {
      const data = { status: status.toString() };

      this.apiRequest(
        "tasks/" + this.data.task + "/edit/",
        "put",
        true,
        data
      ).then(res => {
        if (res.status === 200) {
          EventBus.$emit("updateTasks");
          this.retrieveTask(false);
          this.statusMenuOpen = false;
          this.confirmEndTaskModalActive = false;
        }
      });
    },
    goToProject() {
      this.closeModal();
      this.$router.push(
        "/projects/" + this.taskData.related_object.object_uuid
      );
    },
    goToSub() {
      this.closeModal();
      this.$router.push("/subscriptions/add");
    },
    workersRetrieved(workers) {
      this.conflictingWorkers = [];
      if (this.taskData.workers && this.taskData.workers.length > 0) {
        this.apiRequest(
          "calendar/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/free/",
          "put",
          true,
          {
            start_time: this.startTimeFormatted,
            end_time: this.endTimeFormatted,
            workers: workers.results.map(x => x.id),
            task: this.data.task
          }
        ).then(res => {
          if (res.status === 200) {
            this.conflictingWorkers = res.data.filter(
              worker => !this.taskData.workers.map(x => x.id).includes(worker)
            );
          }
        });
      }
    }
  },
  mounted() {
    this.getData();
    document.body.classList.add("modal-open");
  },
  directives: {
    onClickaway,
    autogrow: TextareaAutogrowDirective
  },
  beforeDestroy() {
    for (let i = 0; i < this.stopperTimers.length; i++) {
      clearInterval(this.stopperTimers[i]);
    }
    document.body.classList.remove("modal-open");
  },
  computed: {
    date() {
      if (!this.data.task.all_day) {
        return (
          moment(this.taskData.start_time).format("DD.MM.YYYY HH:mm-") +
          moment(this.taskData.end_time).format("HH:mm")
        );
      } else {
        return moment(this.taskData.start_time).format("DD.MM.YYYY");
      }
    },
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    startTimeFormatted() {
      const time =
        moment(this.editables.time.startDate).format("YYYY-MM-DD") +
        " " +
        this.editables.time.startTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeFormatted() {
      const time =
        moment(this.editables.time.startDate).format("YYYY-MM-DD") +
        " " +
        this.editables.time.endTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    canAddWorkers() {
      const isBeforeNow =
        (!this.taskData.all_day &&
          moment(this.taskData.end_time).isAfter(moment())) ||
        (this.taskData.all_day &&
          moment(this.taskData.end_time).isAfter(moment()));
      const hasPermission = ["R0", "R1", "R2", "R3"].includes(
        this.$store.state.companyData.activeCompany.worker_permission
      );
      return isBeforeNow && hasPermission;
    },
    canEdit() {
      return (
        ["R0", "R1", "R2", "R3"].includes(this.userRole) &&
        [1, 2, 3, 4, 5].includes(this.taskData.status)
      );
    },
    canEditStatus() {
      return (
        (["R0", "R1", "R2", "R3"].includes(this.userRole) &&
          [1, 2, 3, 4, 5].includes(this.taskData.status)) ||
        (["R4", "R5"].includes(this.userRole) &&
          [2, 3, 4].includes(this.taskData.status))
      );
    }
  },
  watch: {
    editables: {
      deep: true,
      handler() {
        if (
          this.editables.time.startTime !==
          this.editables.time.originalObj.startTime
        ) {
          let startTimeObject = moment(this.editables.time.startDate);
          startTimeObject.hour(
            moment(this.editables.time.startTime, "HH:mm").hour()
          );
          startTimeObject.minute(
            moment(this.editables.time.startTime, "HH:mm").minute()
          );
          if (
            moment(this.editables.time.startTime, "HH:mm").isAfter(
              moment(this.editables.time.endTime, "HH:mm")
            )
          ) {
            this.editables.time.endTime = moment(startTimeObject)
              .add(1, "hours")
              .format("HH:mm");
          }
        }
        if (
          this.editables.time.endTime !==
          this.editables.time.originalObj.endTime
        ) {
          let endTimeObject = moment(this.editables.time.startDate);
          endTimeObject.hour(
            moment(this.editables.time.endTime, "HH:mm").hour()
          );
          endTimeObject.minute(
            moment(this.editables.time.endTime, "HH:mm").minute()
          );
          if (
            moment(this.editables.time.endTime, "HH:mm").isBefore(
              moment(this.editables.time.startTime, "HH:mm")
            )
          ) {
            this.editables.time.startTime = moment(endTimeObject)
              .subtract(1, "hours")
              .format("HH:mm");
          }
        }
        if (this.addingWorkers) {
          this.$refs.workerSelector.checkIfWorkersFree();
        }
        if (this.editables.inited && !this.preventSave) {
          this.debounceSaveChanges();
        }
      }
    },
    "$store.state.timetracking.hasActiveTimer"() {
      this.retrieveTimers();
    }
  }
};
</script>

<style lang="scss">
.taskmodal-datepicker {
  @apply w-full;
  div {
    input {
      @apply shadow w-full;
    }
  }
  .vdp-datepicker__calendar {
    @apply absolute z-50;
  }
}
.taskmodal-close-button {
  @apply bg-danger text-white shadow-none p-2 leading-none;
  &:hover {
    @apply bg-white border-danger text-danger shadow-dangeroutline;
    &:after {
      padding-left: 0.5rem;
      content: "Sulge";
    }
  }
}
.loading-taskmodal {
  @apply border-green;
  div {
    @apply border-green;
  }
}
</style>
