<template>
  <div>
    <task-modal
      v-if="$store.state.taskViewer.taskModal.show"
      :data="$store.state.taskViewer.taskModal.data"
      :user-role="
        $store.state.companyData.isClient
          ? 'R6'
          : $store.state.companyData.activeCompany.worker_permission
      "
    ></task-modal>
    <invoice-modal
      v-if="$store.state.invoiceViewer.invoiceModal.show"
      :data="$store.state.invoiceViewer.invoiceModal.data"
    ></invoice-modal>
    <new-invoice-modal
      v-if="$store.state.invoiceViewer.newInvoiceModal.show"
      :data="$store.state.invoiceViewer.newInvoiceModal.data"
    ></new-invoice-modal>
    <offer-modal
      v-if="$store.state.offerViewer.offerModal.show"
      :data="$store.state.offerViewer.offerModal.data"
    ></offer-modal>
    <new-offer-modal
      v-if="$store.state.offerViewer.newOfferModal.show"
      :data="$store.state.offerViewer.newOfferModal.data"
    ></new-offer-modal>
    <add-task-modal
      v-if="$store.state.taskViewer.addTaskModal.show"
      :data="$store.state.taskViewer.addTaskModal.data"
    >
    </add-task-modal>
  </div>
</template>

<script>
import TaskModal from "@/components/tasks/TaskModal";
import InvoiceModal from "@/components/project/invoice/modal/InvoiceModal";
import NewInvoiceModal from "@/components/project/invoice/modal/AddInvoiceModal";
import OfferModal from "@/components/project/offer/OfferModal";
import NewOfferModal from "@/components/project/offer/NewOfferModal";
import AddTaskModal from "@/components/tasks/AddTaskModal";
export default {
  components: {
    TaskModal,
    InvoiceModal,
    NewInvoiceModal,
    OfferModal,
    NewOfferModal,
    AddTaskModal
  }
};
</script>
