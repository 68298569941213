var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-menu"},[(
      _vm.taskData.status !== 1 &&
        _vm.taskData.status === 2 &&
        ['R0', 'R1', 'R2'].includes(_vm.userRole)
    )?_c('span',{staticClass:"status-menu-item",domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(1))},on:{"click":function($event){return _vm.changeTaskStatus(1)}}}):_vm._e(),(_vm.taskData.status !== 2 && ['R0', 'R1', 'R2'].includes(_vm.userRole))?_c('span',{staticClass:"status-menu-item",domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(2))},on:{"click":function($event){return _vm.changeTaskStatus(2)}}}):_vm._e(),(
      (_vm.taskData.status !== 3 && ['R0', 'R1', 'R2'].includes(_vm.userRole)) ||
        (['R4', 'R5'].includes(_vm.userRole) && _vm.taskData.status === 4)
    )?_c('span',{staticClass:"status-menu-item",domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(3))},on:{"click":function($event){return _vm.changeTaskStatus(3)}}}):_vm._e(),(_vm.taskData.status !== 4)?_c('span',{staticClass:"status-menu-item",domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(4))},on:{"click":function($event){return _vm.changeTaskStatus(4)}}}):_vm._e(),(_vm.taskData.status !== 5)?_c('span',{staticClass:"status-menu-item",class:_vm.taskData.status !== 6 ? 'rounded-b-xl' : '',domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(5))},on:{"click":function($event){return _vm.changeTaskStatus(5)}}}):_vm._e(),(_vm.taskData.status !== 6 && ['R0', 'R1', 'R2'].includes(_vm.userRole))?_c('span',{staticClass:"status-menu-item",class:_vm.taskData.status !== 6 ? 'rounded-b-xl' : '',domProps:{"innerHTML":_vm._s(_vm.parseTaskStatus(6))},on:{"click":_vm.endTask}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }