<template>
  <div
    @click="toggleSelectedProject"
    class="cursor-pointer"
    :class="selectedProject === this.data.uuid ? 'bg-green bg-opacity-10' : ''"
  >
    <draft-description :data="data"> </draft-description>
  </div>
</template>

<script>
import DraftDescription from "@/components/reusable/DraftDescription";
export default {
  name: "Project",
  components: { DraftDescription },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isFirst: Boolean,
    selectedProject: { type: String, default: "" }
  },
  methods: {
    toggleSelectedProject() {
      this.$emit("projectSelected", this.data);
    }
  }
};
</script>
