<template>
  <div class="flex flex-col w-full flex-grow">
    <div class="flex flex-col w-full modal-content flex-grow">
      <h2 class="mb-2">Lisa pakkumine</h2>
      <div class="flex flex-col">
        <div class="form-group">
          <span class="font-medium mb-2">Pakkumise kirjeldus</span>
          <div
            v-if="detailTemplates.length > 0"
            class="flex flex-row items-center my-2"
          >
            <span class="mr-2">Vali kirjelduse mall</span>
            <select class="ml-2" v-model="offer.details">
              <option
                v-for="details in detailTemplates"
                :key="details.id"
                :value="details.details"
              >
                {{ details.description }}
              </option>
            </select>
          </div>
          <textarea
            class="add-offer-input mb-3"
            v-model="offer.details"
          ></textarea>
        </div>
      </div>
      <div class="flex flex-col mobile:flex-row w-full mobile:flex-wrap">
        <div class="flex items-center mb-1">
          <span class="mb-2 font-medium">Pakutavad teenused</span>
        </div>
        <div
          class="w-full grid grid-cols-12 text-sm mobile:grid-cols-1 mobile:grid-rows-8 mobile:w-4/12 mobile:hidden"
        >
          <div
            class="flex col-span-4 mobile:col-span-1 justify-start mobile:items-center pl-1"
          >
            <h4>Kirjeldus</h4>
          </div>
          <div class="flex justify-start pl-3 mobile:items-center">
            <h4>Kogus</h4>
          </div>
          <div class="flex pl-3 justify-start mobile:items-center">
            <h4>Ühik</h4>
          </div>
          <div class="flex pl-3 justify-start mobile:items-center">
            <h4 class="truncate">
              Ühikuhind<span class="medium:hidden">(€)</span>
            </h4>
          </div>
          <div class="flex pl-3 justify-start mobile:items-center">
            <h4 class="truncate">
              Summa <span class="medium:hidden">(-km)</span>
            </h4>
          </div>
          <div class="flex pl-3 justify-start mobile:items-center">
            <h4>KM%</h4>
          </div>
          <div class="flex pl-3 justify-start mobile:items-center">
            <h4>KM</h4>
          </div>
          <div class="flex col-span-1 justify-start mobile:items-center">
            <h4>Summa</h4>
          </div>
        </div>
        <new-service-row
          v-for="item in services"
          :key="item.index"
          :service="item"
          :service-templates="serviceTemplates"
          class="mb-2"
          @serviceUpdated="handleServiceUpdate"
          @removeService="removeService"
          :active-template-selector="activeTemplateSelector"
          @openTemplateSelector="changeActiveTemplateSelector"
          :index="item.index"
          :is-white="false"
        ></new-service-row>
        <div class="flex mb-4">
          <button @click="addService" class="alt-button-green">
            <span class="typcn typcn-plus icon"></span>
            <span class="label">Lisa rida</span>
          </button>
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="saveOfferConfirm"
      :text="'Kas Te olete kindel, et Te soovite pakkumise salvestada?'"
      :affirm-button="'Salvesta'"
      :reject-button="'Tühista'"
      @confirm="saveOffer"
      @closeModal="saveOfferConfirm = false"
    ></confirm-modal>
    <confirm-modal
      v-if="closeOfferConfirm"
      :text="'Kas Te olete kindel, et Te soovite pakkumise lisamise tühistada?'"
      :affirm-button="'Jah'"
      :reject-button="'Ei'"
      @confirm="closeForm"
      @closeModal="closeOfferConfirm = false"
    ></confirm-modal>
    <div class="modal-bottom-bar">
      <div class="flex justify-start" v-if="!emailActive">
        <button class="new-button-green mr-3" @click="saveOfferConfirm = true">
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeOfferConfirm = true">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NewServiceRow from "@/components/project/offer/services/NewServiceRow";
import RequestHandler from "@/assets/mixins/RequestHandler";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import EventBus from "@/assets/mixins/EventBus";
export default {
  data() {
    return {
      detailTemplates: [],
      serviceTemplates: [],
      services: [],
      offer: {
        details: "",
        price: null
      },
      vat: 0.2,
      saveOfferConfirm: false,
      closeOfferConfirm: false,
      emailActive: false,
      activeTemplateSelector: null,
      serviceItemIndex: 1
    };
  },
  mixins: [RequestHandler],
  components: {
    ConfirmModal,
    NewServiceRow
  },
  methods: {
    loadTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/",
        "get",
        true
      ).then(res => {
        if (
          res.data.filter(x => x.type === 1 && x.default === true).length === 1
        ) {
          this.offer.details = res.data.filter(
            x => x.type === 1 && x.default === true
          )[0].details;
        }
        this.detailTemplates = res.data.filter(x => x.type === 1);
      });
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/",
        "get",
        true
      ).then(res => {
        this.serviceTemplates = res.data;
      });
    },
    saveOfferAndShowEmail() {
      this.saveOfferConfirm = true;
      this.emailActive = true;
    },
    addService() {
      let serviceObj = {};
      serviceObj.name = "";
      serviceObj.amount = 0;
      serviceObj.unit = 1;
      serviceObj.price = 0.0;
      serviceObj.vat = 20;
      serviceObj.index = this.serviceItemIndex;
      serviceObj.calculatedVat = null;
      serviceObj.calculatedSum = null;
      serviceObj.calculatedSumNoVat = null;
      this.serviceItemIndex += 1;

      this.services.push(serviceObj);
    },
    removeService(e) {
      this.services.splice(
        this.services.findIndex(item => item.index === e),
        1
      );
    },
    changeActiveTemplateSelector(e) {
      this.activeTemplateSelector = e.id;
    },
    handleServiceUpdate(e) {
      let itemToChange = this.services.findIndex(
        item => item.index === e.index
      );
      this.services[itemToChange] = e;
    },

    saveOffer() {
      const data = {};

      data.details = this.offer.details;
      data.price = this.offerSum;
      this.apiRequest(
        "notes/draft/" +
          this.$store.state.offerViewer.newOfferModal.data.uuid +
          "/offers/new/",
        "post",
        true,
        data
      ).then(res => {
        let data = [];
        for (let i = 0; i < this.services.length; i++) {
          let obj = {};
          obj.name = this.services[i].name;
          obj.amount = this.services[i].amount;
          obj.unit = this.services[i].unit;
          obj.price = this.services[i].price;
          obj.vat = this.services[i].vat;
          obj.offer = res.data.offer_id;

          if (obj.name) {
            data.push(obj);
          }
        }

        this.apiRequest(
          "notes/draft/" +
            this.$route.params.projectId +
            "/" +
            res.data.offer_id +
            "/items/add/",
          "post",
          true,
          data
        ).then(res2 => {
          if (res2.status === 201) {
            EventBus.$emit("updateOffers");
            this.$store.dispatch("offerViewer/closeNewOfferModal");
          }
        });
      });
    },
    closeForm() {
      this.$emit("closeForm");
    }
  },
  mounted() {
    this.loadTemplates();
    this.addService();
  },
  computed: {
    offerSum() {
      let offerSum = 0;

      for (let i = 0; i < this.services.length; i++) {
        offerSum += parseFloat(this.services[i].calculatedSum);
      }

      offerSum = offerSum.toFixed(2);
      return offerSum;
    }
  }
};
</script>

<style lang="scss">
.add-offer-input {
  @apply bg-offwhite shadow w-full;
  &:focus {
    @apply shadow-outline;
  }
}
</style>
