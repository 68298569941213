<template>
  <div class="modal">
    <div
      class="modal-body modal-medium add-task-modal relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-content flex-col">
        <div class="flex justify-between items-center mobile:flex-col mb-4">
          <h2 class="self-center">Lisa ülesanne</h2>
        </div>

        <add-task-form
          @taskSaved="handleSaveTask"
          ref="addTaskForm"
          :has-preset-data="data.hasPresetData"
          :preset-data="data.presetData"
          :related-project="data.relatedProject"
          :project-location="data.location"
          :display-save-button="false"
        ></add-task-form>
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveTask()" class="new-button-green mr-3">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal(false)">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AddTaskForm from "./AddTaskForm";
import { directive as onClickaway } from "vue-clickaway2";
import EventBus from "@/assets/mixins/EventBus";
export default {
  components: {
    AddTaskForm
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  directives: {
    onClickaway
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  methods: {
    closeModal() {
      this.$store.dispatch("taskViewer/closeAddTaskModal");
    },
    saveTask() {
      this.$refs.addTaskForm.saveTask();
    },
    handleSaveTask() {
      EventBus.$emit("updateTasks");
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">
.add-task-modal {
  .close-button {
    left: 90%;
    top: 2%;
  }
}
</style>
