<template>
  <div>
    <div v-if="!addTaskNoteActive">
      <div
        class="mb-2 relative items-center cursor-pointer flex flex-row justify-between hover:bg-offwhite rounded-xl w-full px-2 py-1"
        @click="expanded = !expanded"
        v-if="$store.state.companyData.isClient ? false : canEdit"
      >
        <div class="flex flex-row items-center">
          <span class="typcn typcn-document text-xl mr-2"></span>
          <h4 class="mr-2">Kommentaarid</h4>
          <span
            class="note-amount flex justify-center items-center font-semibold"
          >
            {{ notes.length }}
          </span>
        </div>

        <span
          class="typcn typcn-chevron-right transform duration-100"
          :class="!expanded ? 'rotate-90' : '-rotate-90'"
        ></span>
      </div>
      <div v-if="expanded">
        <div v-for="note in notes" :key="note.id" class="py-1 flex flex-col">
          <span>{{ note.note }}</span>
          <div class="flex flex-col" v-if="note.workers.length > 0">
            {{ note.workers }}
          </div>
        </div>
        <div
          class="mb-2"
          v-if="$store.state.companyData.isClient ? false : canEdit"
        >
          <button class="new-button-green" @click="addTaskNoteActive = true">
            <span class="icon typcn typcn-plus"></span>
            <span class="label">Lisa märkus</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="addTaskNoteActive">
      <input v-model="newNoteText" type="text" class="mb-2" />
      <button class="new-button-green" @click="addTaskNote">
        <span class="icon typcn typcn-plus"></span>
        <span class="label">Salvesta</span>
      </button>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "TaskNotes",
  props: {
    task: { type: Number, default: null },
    notes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    canEdit: Boolean
  },
  data() {
    return {
      addTaskNoteActive: false,
      newNoteText: "",
      expanded: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    addTaskNote() {
      this.apiRequest("tasks/" + this.task + "/notes/create/", "post", true, {
        task: this.task,
        note: this.newNoteText
      }).then(res => {
        if (res.status === 201) {
          this.addTaskNoteActive = false;
          this.$emit("noteAdded");
        }
      });
    }
  }
};
</script>

<style lang="scss">
.note-amount {
  top: 0;
  left: 100%;
  @apply rounded-full bg-pending h-6 w-6 text-white;
}
</style>
